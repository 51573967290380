import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, message,Select ,Modal} from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import '../../../base/index.scss';
const {Option} = Select;

class AddNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_type:[],
            editorState: BraftEditor.createEditorState('<p>初始值</p>'), // 设置编辑器初始内容
            outputHTML: '<p></p>' // 编辑器输出内容
        };


    }

    componentDidMount() {
        this.setState({
            editorState: BraftEditor.createEditorState(' ')
        })
    }
    handleChange = (editorState) => {
        this.setState({
            editorState: editorState,
            outputHTML: editorState.toHTML()
        })
    }

    submit = ()=>{
        console.log(this.state.outputHTML)
        this.props.$request('/config/add-notic',{
            txt:this.state.outputHTML,
            type:1,
            country:'CN',
            show_type:this.state.show_type,
            end:'2030-12-31'
        },(res)=>{
            if(res['code']===200){
                message.success('操作成功！');
                this.props.close();
                this.props.reload();
            }
        })
    }

    handleUserChange=(values)=>{
        console.log(values)
        this.setState({
            show_type:values
        })
    }
    render() {
        const { editorState, outputHTML,show_type } = this.state
        return (
            <Modal
                visible={true}
                title="发布公告"
                centered
                onCancel={this.props.close}
                footer={false}
                width={800}
            >
            <div className="content_tabs_box">
                <div className="editor-wrapper">
                    <BraftEditor
                        value={editorState}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <span>用户群体：</span>
                    <Select
                        mode="tags"
                        onChange={this.handleUserChange}
                        placeholder="请选择用户群体，不选默认全部"
                        style={{ width: 300,marginBottom:10 }}
                        value={show_type}
                    >
                        <Option value={1}>正式用户</Option>
                        <Option value={2}>取消用户</Option>
                        <Option value={3}>试用期用户</Option>
                    </Select>
                </div>
                <Button type="primary" onClick={this.submit} disabled={!(show_type.length>0&&!!editorState)}>提交</Button>
            </div>
            </Modal>
        );
    }
}

export default BaseHoc(AddNotice);