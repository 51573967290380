import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Button, Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import Add from './add';
import '../../../base/index.scss';


const { RangePicker } = DatePicker;
const tconfig = {
    url: '/seller/list',
    param: {},
    callback: null,
    columns: []
}
class SellerActiveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            config: tconfig,
            param: {
                id: '',
                email: '',
            },
            columns: []
        };

    }
    componentDidMount() {
        console.log('self did mount');
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'buyer',
                key: 'buyer',
                width: 150,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.buyerId) - parseFloat(b.buyerId),
            }, {
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 150,
                fixed: 'right',
                render: (a) => {

                    return '';
                },
            }]
        })

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent();
        })
    }

    addNewActive = () => {
        this.setState({ visible: true });
    }


    handleCancel = () => {
        this.setState({ visible: false });
    };


    render() {
        const { param, config ,columns,visible} = this.state;
        config['columns'] =columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">发布时间</span>
                    <RangePicker className="add_margin" onChange={this.handleTimeChange} />
                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    <Button style={{ float: 'right' }} onClick={this.addNewActive}>发布</Button>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} />


                <Modal
                    visible={visible}
                    title="发布活动"
                    centered
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    {visible ? <Add handleCancel={this.handleCancel} refresh={this.childEvent}/> : ''}
                </Modal>
            </div>
        );
    }
}

export default BaseHoc(SellerActiveModal);