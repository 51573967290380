import React, { Component } from 'react';

import store from '../../store/index';

import Loading from '../widget/loading';

import { SET_LOGIN_STATUS } from '../../store/action';

import axios from 'axios';

import qs from 'qs';

import { message } from 'antd';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//高阶组件 封装通用方法，例如redux中的状态更新，以及本地缓存、异步请求等。
const baseHoc = WrappedComponent => class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

  }
  // componentWillMount(){
  //   console.log('hoc will mount')
  // }

  componentDidMount() {
    // console.log('hoc did mount')
    this.listenerProps()
    // store.subscribe(this.listenerProps);
    // const { isLogin } = store.getState();
    // if (!isLogin) {
    //   this.props.history.push('/login')
    // }
  }

  listenerProps = () => {

  }
  //没有登录，设置状态
  noLoginOption = () => {
    store.dispatch({ type: SET_LOGIN_STATUS, key: 'isLogin', status: false });
  }

  getStatus = (code) => {
    switch (code) {
      case '1':
        return '买家申请,等待同意';
      case '2':
        return '卖家同意买家申请';
      case '3':
        return '卖家拒绝买家申请';
      case '4':
        return '卖家超时未处理买家申请';
      case '5':
        return '买家提交订单号';
      case '6':
        return '买家提交订单号超时';
      case '7':
        return '卖家确认订单号';
      case '8':
        return '卖家拒绝确认订单号';
      case '9':
        return '卖家超时未确认订单号 ，自动确认订单号';
      case '10':
        return '已提交评价等待检测';
      case '11':
        return '评价检测中';
      case '12':
        return '完成评价';
      case '13':
        return '评价错误';
      case '14':
        return '返现中';
      case '15':
        return '返现失败';
      case '16':
        return '返现成功';
      case '17':
        return '返现审核中';
      case '18':
        return '已关闭（针对原历史订单）';
    }
  }

  // 上传文件
  $upload = (url,param,success,err)=>{
    let auth = '';
    if (!!window.sessionStorage.getItem('P_TOKEN')) {
      auth = window.sessionStorage.getItem('P_TOKEN');
    }
    //增加请求头
    axios.defaults.headers.post['Authorization'] = auth;
    url = 'https://adminapi.pingamz.com' + url;
    axios.post(url, param).then((res) => {
      if(!!success){
        success(res['data']);
      }else{
        err(res['data']);
      }
    })
  }
  //axios//用于集成封装，加载loading以及数据转化
  $request = (url, param, success, err ,needLoading = true) => {
    if(needLoading){
      this.setState({ loading: true });
    }
    let auth = '';
    if (!!window.sessionStorage.getItem('P_TOKEN')) {
      auth = window.sessionStorage.getItem('P_TOKEN');
    }

    //增加请求头
    axios.defaults.headers.post['Authorization'] = auth;

    
    //区分 api
    if(window.location.host.indexOf('pre')===-1){
    // if(window.location.host.indexOf('localhost')===-1&&window.location.host.indexOf('pre')===-1){
      url = 'https://adminapi.pingamz.com' + url;
      // url = 'https://adminapi.pingamz.com' + url;
    }else{
      url = 'https://adminapi.pingamz.com' + url;
    }
    axios.post(url, qs.stringify(param)).then((res) => {
      //请求后获取校验令牌certification，并带入
      if (res['data']['code'] === 200 && !!res['data']['data']['userId']) {
        window.sessionStorage.setItem('P_TOKEN', res['data']['data']['userId']);
      }

      if (!!success) {
        success(res['data'])
      }

      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
      // 未登录状态
      // if(res.code===500){
      //   this.noLoginOption()
      // }
      if(res['data']['code']===400){
        message.error(res['data']['message']);
      }
    }).catch((res) => {
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
      if (!!err) {
        err(res)
      }
    })
  }

  getJurisdiction = ()=>{
    let s = sessionStorage.getItem('IS_WHOLE');
    return s
  }

  render() {
    const loadingBox = <Loading />;
    const { loading } = this.state;
    const props = {
      ...this.props,
      $request: this.$request,
      $upload: this.$upload,
      getStatus: this.getStatus,
      getJurisdiction:this.getJurisdiction
    }
    return (
      <div style={{ height: '100%' }}>
        <WrappedComponent {...props} />
        {loading ? loadingBox : ''}
      </div>
    )
  }
}
export default baseHoc;