import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, Upload, Modal, Countdown, message } from 'antd';
import ProTable from '../../../widget/proTable';
import { PlusOutlined, RightSquareFilled } from '@ant-design/icons';
import AddMedium from './addMedium';
import EditMedium from './editMedium';
import '../../../base/index.scss';

const { Option } = Select;
const tconfig = {
    url: '/medium/admin-medium-list',
    param: {},
    callback: null,
    columns: []
}
class MediumList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                admin_name: '',
                buyer_name: '',
            },
            columns: [],
            tableInfo: '',
            doAddMedium:false,
            doEditMedium:false,
            optionIds:[]
        };

    }
    componentDidMount() {
        this.setColumns();
        this.doQuery();
    }

    setColumns = () => {
        this.setState({
            columns: [{
                title: '中介ID',
                dataIndex: 'admin_id',
                key: 'admin_id',
                width: 150
            }, {
                title: '中介账号',
                dataIndex: 'admin_name',
                key: 'admin_name',
                width: 150
            }, {
                title: '买家ID',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width: 150
            }, {
                title: '买家邮箱',
                dataIndex: 'buyer_email',
                key: 'buyer_email',
                width: 250
            }, {
                title: '站点',
                dataIndex: 'country_code',
                key: 'country_code',
                width: 150
            },{
                title:'Profile',
                dataIndex:'profile',
                key:'profile',
                width: 200,
                render:(a)=>{
                    return <a href={a} target="_blank">{a}</a>
                }
            },{
                title:'Profile状态',
                dataIndex:'profile_status',
                key:'profile_status',
                width: 150
            }, {
                title: '负责人',
                dataIndex: 'person',
                key: 'person',
                width: 150
            }, {
                title: '是否开启申请',
                dataIndex: 'is_auto_request',
                key: 'is_auto_request',
                width: 150,
                render:(a,b)=>{
                    return a==='1'?'开启':'关闭'
                }
            }, {
                title: '是否可以留评',
                dataIndex: 'is_can_review',
                key: 'is_can_review',
                width: 150,
                render:(a,b)=>{
                    return a==='1'?'可以留评':'不留评'
                }
            }, {
                title: '货值要求',
                dataIndex: 'is_greater_than',
                key: 'is_greater_than',
                width: 150
            },{
                title:'限制对象',
                dataIndex:'process_or_threshold_type',
                key:'process_or_threshold_type',
                width: 150,
                render:(a,b)=>{
                    return a==='1'?'中介':'买家账号'
                }
            },{
                title: '处理单量',
                dataIndex: 'process_or_threshold_type',
                key: 'process_or_threshold_type',
                width: 150,
                render:(a,b)=>{
                    return a==='1'?b['medium_country_max_process_order_count']:b['max_process_order_count']
                }
            },{
                title: '处理单量阈值',
                dataIndex: 'process_or_threshold_type',
                key: 'process_or_threshold_type',
                width: 150,
                render:(a,b)=>{
                    return a==='1'?b['medium_country_order_threshold_order_count']:b['order_threshold_order_count']
                }
            },{
                title:'中介折扣',
                dataIndex:'discount',
                key:'discount',
                width:150
            },{
                title:'中介支付宝姓名',
                dataIndex:'alipay_name',
                key:'alipay_name',
                width:150
            },{
                title:'中介支付宝账号',
                dataIndex:'alipay_account',
                key:'alipay_account',
                width:150
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                width: 80,
                render:(a,b)=>{
                    return <Button size="small" onClick={()=>{
                        this.props.$request('/medium/remove',{id:b['id']},(res)=>{
                            if(res['code']===200){
                                message.success('操作成功');
                                this.doQuery();
                            }
                        })
                    }}>移除</Button>
                }
            }]
        })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleAdminNameChange = (e) => {
        this.setParam({ 'admin_name': e.target.value })
    }

    handleBuyerNameChange = (e) => {
        this.setParam({ 'buyer_name': e.target.value })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config,
            fileList: []
        }, () => {
            this.childEvent(1, 20, (data) => {
                this.setState({
                    tableInfo: data
                })
            });
        })
    }


    closeAddMedium = ()=>{
        this.setState({
            doAddMedium:false
        })
    }

    openAddMedium = ()=>{
        this.setState({
            doAddMedium:true
        })
    }

    setOptionIds=(ids)=>{
        console.log(ids)
        this.setState({
            optionIds:ids
        })
    }

    openRequest = ()=>{
        const {optionIds} = this.state;
        this.props.$request('/medium/set-auto-request-status',{ids:optionIds,st:1},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.doQuery();
            }
        })
    }

    closeRequest = ()=>{
        const {optionIds} = this.state;
        this.props.$request('/medium/set-auto-request-status',{ids:optionIds,st:0},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.doQuery();
            }
        })
    }

    openEditMedium=()=>{
        this.setState({
            doEditMedium:true
        })
    }

    closeEditMedium=()=>{
        this.setState({
            doEditMedium:false
        }) 
    }

    render() {
        const { tableInfo, param, config, columns, doAddMedium,optionIds,doEditMedium} = this.state;
        config['columns'] = columns;

        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">后台账号</span>
                    <Input className="inline_el" value={param['admin_name']} onChange={this.handleAdminNameChange}/>

                    <span className="label">买家账号</span>
                    <Input className="inline_el" value={param['buyer_name']} onChange={this.handleBuyerNameChange}/>


                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    <Button onClick={this.openRequest} style={{ marginLeft: 20 }} disabled={optionIds.length===0}>开启申请</Button>
                    <Button onClick={this.closeRequest} style={{ marginLeft: 20 }} disabled={optionIds.length===0}>关闭申请</Button>

                    <Button onClick={this.openAddMedium} style={{float:'right'}}>新增账号</Button>
                    <Button onClick={this.openEditMedium} style={{float:'right'}}>修改账号信息</Button>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} rewSelect={true} setOptionIds={this.setOptionIds}/>

                {doAddMedium?<AddMedium close={this.closeAddMedium} reload={this.doQuery}/>:''}

                {doEditMedium?<EditMedium close={this.closeEditMedium} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(MediumList);