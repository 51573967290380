import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,Input} from 'antd';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';
class Examine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrival_time: ''
        };

    }

    // onChange = (e, str) => {
    //     this.setState({
    //         arrival_time: str
    //     })
    // }

    onChange = (e, str) => {
        this.setState({
            arrival_time: e.target.value
        })
    }

    submit=()=>{
        const { arrival_time } = this.state;
        const { item } = this.props;
        this.props.$request('/medium/audit-dg-order',{id:item['id'],arrival_time },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }
    
    render() {
        const { arrival_time } = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="审核通过-确认运单号/预计到货时间"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={8}><div style={style}>运单号/预计到货时间:</div></Col>
                    <Col span={16}>
                        {/* <DatePicker style={{width:'100%'}} onChange={this.onChange} value={moment(arrival_time, dateFormat)} format={dateFormat} /> */}
                        <Input onChange={this.onChange} value={arrival_time}/>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={16}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!arrival_time)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(Examine);