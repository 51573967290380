import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Form, Input,InputNumber, Button,message,Modal} from 'antd';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {

    }
    onFinish = values => {
        const {item} = this.props;
        values['product_id'] = item['id'];
        this.props.$request('/product/request',values,(res)=>{
            if(res['code']===200){
                message.success('提交成功');
                let form = this.productInfo.current;
                form.resetFields();
            }
        })
    }
    onFinishFailed = () => {

    }

    productInfo = React.createRef();
    render() {
        const {item} = this.props;
        return (
            <Modal 
                visible={true}
                title="申请"
                centered
                onCancel={this.props.close}
                footer={false}>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    className="form_content"
                >
                    <Form.Item
                        label="商品编号"
                        name="product_id"
                    >
                        <div>{item['id']}</div>
                    </Form.Item>


                    <Form.Item
                        label="商品图片"
                    >
                        <div><img src={item['image']} style={{maxWidth:150,maxHeight:150}} alt=""/></div>
                    </Form.Item>

                    <Form.Item
                        label="买家id"
                        name="buyer_id"
                        rules={[{ required: true }]}
                    >
                       <Input
                        placeholder="请输入买家 id"
                        style={{ width: 300 }} 
                        />
                    </Form.Item>

                    
                    <Form.Item
                        label="返现金额"
                        name="rebate_price"
                        rules={[{ required: true }]}
                    >
                       <InputNumber
                        placeholder="返现金额"
                        style={{ width: 300 }} 
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" >
                            申请
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default BaseHoc(Request);