import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,Input} from 'antd';
class RebateSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tid:''
        };

    }
    handleChange=(e)=>{
        this.setState({
            tid:e.target.value
        })
    }
    submit=()=>{
        const { item } = this.props;
        this.props.$request('/cash/success',{
            id:item['id'],
            tid: this.state.tid
        },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }

    
    render() {
        const { tid } = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="返现成功"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}><div style={style}>交易号:</div></Col>
                    <Col span={18}><Input value={tid} onChange={this.handleChange}/></Col>
                    <Col span={6}></Col>
                    <Col span={18}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!tid)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(RebateSuccess);