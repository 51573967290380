import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Button, Modal ,Row, Col, Upload ,message,Input} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

class SubmitDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList:[],
            image:'',
            arrival_time_medium:''
        };
    }
    submit=()=>{
        const { item } = this.props;
        const { fileList,arrival_time_medium } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        formData.append('id',item['id']);
        formData.append('arrival_time_medium',arrival_time_medium)
        // 将图片插入
        this.props.$upload('/medium/set-delivery-image',formData,(res) => {
            if (res['code'] === 200) {
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }else{
                message.error(res['message']);
            }
        })
    }

    handlerChangeArrivalTimeMedium=(e)=>{
        this.setState({
            arrival_time_medium:e.target.value
        })
    }

    render() {
        const { fileList,image ,arrival_time_medium} = this.state;
        // console.log(this.props.item)
        // 上传 banner插件的 props
        const upLoadProps = {
            onRemove: file => {
              this.setState(state => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                  fileList: newFileList,
                };
              });
            },
            beforeUpload: file => {
              const image = window.URL ? window.URL.createObjectURL(file): window.webkitURL.createObjectURL(file);
              this.setState(state => ({
                fileList: [...state.fileList, file],
                image: image
              }));
              return false;
            },
            fileList,
        };

        const labelSpan = 7;
        const itemSpan = 17;
        return (
            <Modal
                title="上传发货信息"
                centered
                visible={true}
                onCancel={this.props.close}
                destroyOnClose={true}
                maskClosable={false}
                width={600}
                footer={false}
            >
                <Row>
                    <Col span={labelSpan}>
                        上传截图:
                    </Col>
                    <Col span={itemSpan}>
                        {!!image?<img src={image} style={{maxWidth:200,maxHeight:200,marginRight:20,verticalAlign:'bottom'}} alt="" />:''}
                        <Upload {...upLoadProps} maxCount={1} showUploadList={false} style={{verticalAlign:'top'}}>
                            <Button icon={<UploadOutlined />}>选择文件</Button>
                            <p style={{fontSize:12,marginTop:5}}></p>
                        </Upload>
                    </Col>

                    <Col span={labelSpan}></Col>
                    <Col span={itemSpan} style={{fontSize:12,marginBottom:10,marginTop:10}}>
                        <div>请上传亚马逊/沃尔玛上的发货截图</div>
                        <div>每张图片大小800k 以内，格式 png/jpg/jpeg 上传1张。</div>
                        <div>注意：如果图片清晰度不够或信息错误，将无法通过审核。</div>
                    </Col>

                    

                    <Col span={labelSpan}>
                        运单号/预计到货日期
                    </Col>
                    <Col span={itemSpan}>
                        <Input value={arrival_time_medium} onChange={this.handlerChangeArrivalTimeMedium}></Input>
                    </Col>
                    
                    <Col span={24} style={{textAlign:'right'}}>
                        <Button disabled={!(!!image||!!arrival_time_medium)} type="primary" style={{marginTop:20}} onClick={this.submit}>提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(SubmitDrawing);