import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import AddNewBanner from './addNewBanner';
import '../../../base/index.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/config/buyer-banner-list',
    param: {},
    callback: null,
    columns: []
}
class SellerNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                type:2,
                show:1,
                country:'US'
            },
            columns: [],
            optionItem:null,
            openAddNewBanner:false
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title:'Banner',
                dataIndex:'image',
                key:'image',
                width:200,
                render:(a)=>{
                    return <img src={a} style={{width:180}}/>
                }
            },{
                title: '发布日期',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
            },{
                title: '展示站点',
                dataIndex: 'stations',
                key: 'stations',
                width: 100
            },{
                title: '设备',
                dataIndex: 'dev',
                key: 'dev',
                width: 100,
                render:(a,b)=>{
                    return a==='1'?'PC':'Phone'
                }
            }, {
                title: '推广周期',
                dataIndex: 'begin',
                key: 'begin',
                width: 100,
                render:(a,b)=>{
                    return <div>
                        <div>{b['begin']}</div>
                        -
                        <div>{b['end']}</div>
                    </div>
                }
            },{
                title:'是否需要登录',
                dataIndex:'needLogin',
                key:'needLogin',
                width:100,
                render:(a,b)=>{
                    return a==='1'?'是':'否'
                }
            }, {
                title:'只显示当前',
                dataIndex:'isOnly',
                key:'isOnly',
                width:100,
                render:(a,b)=>{
                    return a==='1'?'是':'否'
                }
            },{
                title:'点击动作',
                dataIndex:'clickAction',
                key:'clickAction',
                width:200,
                render:(a,b)=>{
                    if(a==='1'){
                        return '滑动至楼层'
                    }else if(a==='2'){
                        return <div><div>站内跳转</div><div>跳转地址：{b['url']}</div></div>
                    }else if(a==='3'){
                        return <div><div>打开新页面</div><div>跳转地址：{b['url']}</div></div>
                    }else{
                        return '无'
                    }
                }
            },{
                title:'统计',
                dataIndex:'isStat',
                key:'isStat',
                width:200,
                render:(a,b)=>{
                    if(a==='1'){
                        let ar = b['stat'].split('&');
                        return ar.map((item,index)=> <div key={index}>{item}</div> )
                    }
                }
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 50,
                fixed: 'right',
                render: (a,b) => {
                    return  [
                        <Button size="small" onClick={()=>{this.editBannerConfig(b)}}>编辑</Button>,
                        <Button size="small" onClick={()=>{this.undercarriage(b['id'])}}>下架</Button>
                    ]
                },
            }],

        })

        this.doQuery();

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleShowStateChange = (e) => {
        this.setParam({ 'show': e })
    }

    handleStationChange=(e)=>{
        this.setParam({ 'country': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }
    // 编辑
    editBannerConfig=(item)=>{
        this.setState({
            optionItem: JSON.parse(JSON.stringify(item)),
            openAddNewBanner:true
        })
    }
    // 新增
    doAddNew=()=>{
        this.setState({
            openAddNewBanner:true
        })
    }
    // 关闭
    closeAddNew=()=>{
        this.setState({
            openAddNewBanner:false,
            optionItem:null
        })
    }

    // 下架
    undercarriage=(id)=>{
        this.props.$request('/config/close-doc',{id:id},res=>{
            if(res['code']===200){
                message.success('已下架');
                this.doQuery();
            }
        })
    }


    

    render() {
        const {param, config ,columns,optionItem,openAddNewBanner} = this.state;
        config['columns'] =columns;
        return (
            <div className="content_tabs_box">
                <div>
                    <div className="tool_bar">
                        <span className="label">状态</span>
                        <Select value={param['show']} onChange={this.handleShowStateChange}>
                            <Option value={1}>生效中</Option>
                            <Option value={2}>已关闭</Option>
                        </Select>
                        <span className="label" style={{marginLeft:20}}>站点</span>
                        <Select value={param['country']} onChange={this.handleStationChange}>
                            <Option value={'US'}>US</Option>
                            <Option value={'UK'}>UK</Option>
                            <Option value={'DE'}>DE</Option>
                            <Option value={'FR'}>FR</Option>
                            <Option value={'CA'}>CA</Option>
                            <Option value={'JP'}>JP</Option>
                        </Select>
                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                        <Button style={{float:'right'}} onClick={this.doAddNew}>上传新Banner</Button>
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery}  autoLoad={false}/>
                {openAddNewBanner?<AddNewBanner item={optionItem} close={this.closeAddNew} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(SellerNotice);