import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Form, Input, Button,message,Modal} from 'antd';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


class SellerActiveAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {

    }
    onFinish = values => {
        console.log(values)
        this.props.$request('/order/submit-review',values,(res)=>{
            if(res['code']===200){
                message.success('提交成功');
                let form = this.productInfo.current;
                form.resetFields();
            }
        })
    }
    onFinishFailed = () => {

    }
    productInfo = React.createRef();
    render() {
        const {item} = this.props;
        return (
            <Modal 
                visible={true}
                title="评论绕过"
                centered
                onCancel={this.props.close}
                footer={false}>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    className="form_content"
                >
                    <Form.Item
                        label="内部订单号"
                    >
                        <div>{item['request_id']}</div>
                    </Form.Item>

                    <Form.Item
                        label="评论链接"
                    >
                        <div>{item['review_url']}</div>
                    </Form.Item>

                    
                    <Form.Item
                        label="备注"
                        name="remark"
                        rules={[{ required: true }]}
                    >
                       <TextArea
                        placeholder="请输入备注"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        style={{ width: 300 }} 
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default BaseHoc(SellerActiveAdd);