import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,Select, message, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const { RangePicker } = DatePicker;
const {Option} = Select;

const tconfig = {
    url: '/buyer/bank-list',
    param: {},
    callback: null,
    columns: []
}
class RebateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                buyer_id: '',
                account: '',
                name: '',
                station:'',
                begin:'',
                end:''
            },
            columns: [],
            optionItem:{},
            showAccountStatus:false,
            rebateSuccessStatus:false
        };
    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '买家 Id',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_id) - parseFloat(b.buyer_id),
            }, {
                title:'站点',
                dataIndex:'station',
                key:'station',
                width: 100,
            },{
                title:'提交时间',
                dataIndex:'create_time',
                key:'create_time',
                width: 100,
            },{
                title:'First name',
                dataIndex:'first_name',
                key:'first_name',
                width: 150,
            },{
                title:'Last name',
                dataIndex:'last_name',
                key:'last_name',
                width: 150,
            },{
                title:'Country',
                dataIndex:'country',
                key:'country',
                width: 100,
            },{
                title:'States',
                dataIndex:'states',
                key:'states',
                width: 100,
            },{
                title:'City',
                dataIndex:'city',
                key:'city',
                width: 100,
            },{
                title:'Address',
                dataIndex:'address',
                key:'address',
                width: 100,
            },{
                title:'Post code',
                dataIndex:'post_code',
                key:'post_code',
                width: 150,
            },{
                title:'Routing',
                dataIndex:'routing',
                key:'routing',
                width: 100,
            },{
                title:'Bank name',
                dataIndex:'bank_name',
                key:'bank_name',
                width: 150,
            },{
                title:'Account',
                dataIndex:'account',
                key:'account',
                width: 100,
            },{
                title:'Swift code',
                dataIndex:'swift_code',
                key:'swift_code',
                width: 150,
            },{
                title:'Sort code',
                dataIndex:'sort_code',
                key:'sort_code',
                width: 100,
            },{
                title:'Account type',
                dataIndex:'account_type',
                key:'account_type',
                width: 150,
            }]
        })

        this.doQuery();

    }

    // 查看返现信息
    showAccount=(item)=>{
        this.setState({
            optionItem:item,
            showAccountStatus:true
        })
    }

    // 查看返现信息
    hideAccount=()=>{
        this.setState({
            optionItem:'',
            showAccountStatus:false
        })
    }


    //返现成功
    successCash=(item)=>{
        if(item['refund_type']==='1'){
            // paypal
            this.setState({
                optionItem:item,
                rebateSuccessStatus:true
            })
        }else{
            // 银行卡啊
            this.props.$request('/cash/success',{id:item['id']},(res)=>{
                if(res['code']===200){
                    message.success('操作成功！')
                    this.doQuery();
                }
            })
        }
    }

    closeRebateSuccess=()=>{
        this.setState({
            optionItem:'',
            rebateSuccessStatus:false
        })
    }


    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleBuyerIdChange = (e) => {
        this.setParam({ 'buyer_id': e.target.value})
    }

    handleNameChange = (e) => {
        this.setParam({ 'name': e.target.value })
    }

    handleAccountChange = (e) => {
        this.setParam({ 'account': e.target.value })
    }

    handleStationTypeChange =(e)=>{
        this.setParam({ 'station': e })
    }

    handleOptionTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }


    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns ,optionItem,showAccountStatus ,tableInfo,rebateSuccessStatus} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">买家Id</span>
                    <Input className="inline_el" value={param['buyer_id']} style={{width:80}} onChange={this.handleBuyerIdChange} />
                    <span className="label">卡号</span>
                    <Input className="inline_el" value={param['account']} style={{width:80}} onChange={this.handleAccountChange} />
                    <span className="label">名</span>
                    <Input className="inline_el" value={param['name']} onChange={this.handleNameChange} />
                    <span className="label">站点</span>
                    <Select className="inline_el" value={param['station']} onChange={this.handleStationTypeChange} >
                        <Option value="">全部</Option>
                        <Option value="US">US</Option>
                        <Option value="UK">UK</Option>
                        <Option value="DE">DE</Option>
                        <Option value="FR">FR</Option>
                        <Option value="CA">CA</Option>
                        <Option value="JP">JP</Option>
                    </Select>

                    <span className="label">提交时间</span>
                    <RangePicker className="add_margin" onChange={this.handleOptionTimeChange} />

                    <Button type="primary" style={{ marginLeft: 10 }} onClick={this.doQuery}>查询</Button>
                    {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:10}}>导出Excel</a>:''}
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} />

            </div>
        );
    }
}

export default BaseHoc(RebateList);