import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/stat/dg-stat-list',
    param: {},
    callback: null,
    columns: []
}
class PurchasingUserActionStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                begin:'',
                end:''
            },
            allInbegin:'',
            allInEnd:''
        };

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    
    handleTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    doOut = () => {
        const { param } = this.state;
        this.props.$request('/stat/dg-stat',param,(res)=>{
            if(res['code']===200){
                window.open(res['data']['url'])
            }
        })
    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '统计时间-开始',
                dataIndex: 'stat_date',
                key: 'stat_date',
                width: 150
            }, {
                title:'统计时间-结束',
                dataIndex:'stat_date_end',
                key:'stat_date_end',
                width: 150
            },{
                title: '操作',
                dataIndex: 'url',
                key: 'url',
                width: 150,
                fixed: 'right',
                render: (a,b) => {
                    return <div>
                        {!!a?<a href={a} target="_blank" style={{marginRight:10}}>下载</a>:<span style={{marginRight:10}}>等待统计</span>}
                        {!!b['url_pc']?<a href={b['url_pc']} style={{marginRight:10}} target="_blank">代购日常数据pc</a>:<span style={{marginRight:10}}>暂无</span>}
                        {!!b['url_mobile']?<a href={b['url_mobile']} style={{marginRight:10}} target="_blank">代购日常数据mb</a>:<span style={{marginRight:10}}>暂无</span>}
                        {!!b['url_all']?<a href={b['url_all']} style={{marginRight:10}} target="_blank">代购日常数据总计</a>:<span style={{marginRight:10}}>暂无</span>}
                    </div>
                },
            }],

        })
        this.doQuery();
    }


    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    doQuery = () => {
        const { config, param } = this.state;
        // config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo: data
                })
            });
        })
    }

    handleAllInTimeChange = (e, str) => {
        this.setState({
            'allInbegin': str[0], 'allInEnd': str[1]
        })
    }
    
    doSet=()=>{
        const {allInbegin,allInEnd} = this.state;
        this.props.$request('/stat/dg-user-defined',{begin:allInbegin,end:allInEnd},(res)=>{
            if(res['code']===200){
                message.success('设置成功!')
                this.doQuery();
            }
        })
    }

    render() {
        const { param, config, columns,allInbegin,allInEnd } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div>
                    <div className="tool_bar">
                        <Button onClick={this.doQuery} style={{marginRight:20}}>刷新</Button>
                        <span className="label" style={{marginRight: 20}}>源数据筛查时间</span>
                        <RangePicker className="add_margin" onChange={this.handleTimeChange} format="YYYY/MM/DD" />

                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doOut} disabled={!(!!param['begin']&&!!param['end'])}>导出</Button>

                        <span className="label" style={{marginRight: 20,marginLeft: 30}}>源数据整合设置</span>
                        <RangePicker className="add_margin" onChange={this.handleAllInTimeChange} format="YYYY/MM/DD" />

                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doSet} disabled={!(allInbegin&&!!allInEnd)}>设置</Button>
                    </div>

                </div>

                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(PurchasingUserActionStatistics);