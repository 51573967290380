import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,Select, message, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import AddPromotion from './addPromotion';
const { RangePicker } = DatePicker;
const {Option} = Select;

const tconfig = {
    url: '/sid/list',
    param: {},
    callback: null,
    columns: []
}
class PromotionSourcesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                sid:''
            },
            columns: [],
            optionItem:{},
            showAddPromotionStatus:false
        };
    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: '推广来源',
                dataIndex: 'sid',
                key: 'sid',
                width: '20%',
                align:'center'
            },{
                title: '推广地址',
                dataIndex: 'url',
                key: 'url',
                width: '20%',
                align:'center'
            } ,{
                title: '来源名称',
                dataIndex: 'source',
                key: 'source',
                width: '20%',
                align:'center'
            } ,{
                title: '适用平台',
                dataIndex: 'type',
                key: 'type',
                width: '20%',
                align:'center'
            } ,{
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                width: '20%',
                align:'center'
            } ]
        })

        this.doQuery();

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleSidChange=(e)=>{
        
        this.setParam({'sid':e.target.value})
    }

    closeAddPromotionModal=()=>{
        this.setState({
            showAddPromotionStatus:false
        })
    }

    openAddPromotionModal=()=>{
        this.setState({
            showAddPromotionStatus:true
        })
    }


    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns ,showAddPromotionStatus} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <Button onClick={this.openAddPromotionModal} style={{marginBottom:'10px'}}>新增推广来源</Button>
                <span className="label" style={{marginLeft:10}}>推广来源</span>
                <Input className="inline_el" value={param['sid']} onChange={this.handleSidChange} />
                <Button onClick={this.doQuery} type="primary">查询</Button>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} />
                {showAddPromotionStatus?<AddPromotion  close={this.closeAddPromotionModal} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(PromotionSourcesList);