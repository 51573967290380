import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,message,Row,Col} from 'antd';
class ModifyAmzOrderId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amzOrderId:'',
            id:''
        };
    }

    handlerRequestId = e => {
        this.setState({
            id: e.target.value
        })
    }

    
    handlerAmzOrderId = e => {
        this.setState({
            amzOrderId: e.target.value
        })
    }

    // 提交订单号
    submitAmzOrderId = () => {
        const {amzOrderId,id } = this.state;
        this.props.$request('/medium/edit-amz-order-id', {request_id:id,amz_order_id:amzOrderId}, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！');
                this.setState({
                    amzOrderId:'',
                    id:''
                })
            }
        })
    }
    render() {
        const { amzOrderId ,id} = this.state;
        const style = {
            marginBottom:20,
            width:300
        }
        return (
            <div className="content_tabs_box" style={{paddingTop:40}}>
                <Row>
                    <Col span={4} offset={4}>
                        内部订单号：
                    </Col>
                    <Col span={16}>
                        <Input style={style} value={id} onChange={this.handlerRequestId}/>
                    </Col>
                    <Col span={4} offset={4}>
                        亚马逊订单号：
                    </Col>
                    <Col span={16}>
                        <Input style={style} value={amzOrderId} onChange={this.handlerAmzOrderId} />
                    </Col>
                    <Col span={4} offset={4}>
                        <Button type="primary" disabled={!(!!amzOrderId&&!!id)} onClick={this.submitAmzOrderId}>修改</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BaseHoc(ModifyAmzOrderId);