import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import Request from './request';

const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/product/list',
    param: {},
    callback: null,
    columns: []
}
class BuyerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                product_id:'',
                seller_id:'',
                sku_status:''
            },
            columns: [],
            requestModal:false,
            optionItem:''
        };

    }

    // componentWillMount() {
    //     console.log('self will mount')
    // }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '卖家 id',
                dataIndex: 'sellerId',
                key: 'sellerId',
                width: 100,
                sorter: (a, b) => parseFloat(a.sellerId) - parseFloat(b.sellerId),
            }, {
                title:'商品图',
                dataIndex:'image',
                key:'image',
                width:150,
                render:(a,b)=>{
                    return <img src={a} style={{maxWidth:'150px',maxHeight:'100px'}}/>
                }
            }, {
                title:'title',
                dataIndex:'title',
                key:'title',
                width:200,
                render:(a,b)=>{
                    return <div >
                        <a href={b['rawUrl']} className="product_title" target="_blank">{a}</a>
                        <div>ASIN:{b['asin']} 站点:{b['countryCode']}</div>
                        </div>
                }
            },{
                title:'返现金额',
                dataIndex:'price_rebate',
                key:'price_rebate',
                width:200,
                render:(a,b)=>{
                    return <span>{parseFloat(a)/100} {b['currency']}</span>
                }
            },{
                title:'返现类型',
                dataIndex:'unnecessaryReview',
                key:'unnecessaryReview',
                width:200,
                render:(a,b)=>{
                    if(a==='1'){
                        return '需要留评'
                    }else{
                        return '不需要留评'
                    }
                }
            },{
                title:'额外返现金额',
                dataIndex:'extra_price',
                key:'extra_price',
                width:150,
                render:(a,b)=>{
                    return <span>{a} {b['currency']}</span>
                }
            }, {
                title:'商品状态',
                dataIndex:'status',
                key:'status',
                width:150
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 100,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [
                        <Button size="small" onClick={()=>{
                            this.openRequestModal(b)
                        }}>申请</Button>
                    ]
                    return btns;
                },
            }],

        })
        this.doQuery();
    }

    listenerProps = () => {
        // const { isLogin } = store.getState();
        // this.setState({
        //     isLogin: isLogin
        // })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleSellerIdChange = (e) => {
        this.setParam({ 'seller_id': e.target.value })
    }

    handleProductsIdChange = e=>{
        this.setParam({ 'product_id': e.target.value })
    }

    handleSkuStatusChange = e=>{
        this.setParam({ 'sku_status': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    //打开修改 profile
    openRequestModal=(item)=>{
        this.setState({
            optionItem:item,
            requestModal:true
        })
    }
    // 关闭修改 profile
    openCloseModal=()=>{
        this.setState({
            optionItem:null,
            requestModal:false
        })  
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,optionItem,requestModal} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div>
                    {/* <Button>刷新</Button> */}
                    <div className="tool_bar">
                        <span className="label">商品 id</span>
                        <Input className="inline_el" value={param['product_id']} onChange={this.handleProductsIdChange} />
                        
                        <span className="label">卖家 id</span>
                        <Input className="inline_el" value={param['seller_id']} onChange={this.handleSellerIdChange} />

                        <span className="label">商品状态</span>
                        <Select  onChange={this.handleSkuStatusChange} value={param['sku_status']} style={{width:150,marginBottom:20}}>
                            <Option value={''}>全部</Option>
                            <Option value={3}>推广中</Option>
                            <Option value={4}>已下架</Option>
                        </Select>

                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
                {requestModal?<Request close={this.openCloseModal} item={optionItem}/>:''}
            </div>
        );
    }
}

export default BaseHoc(BuyerList);