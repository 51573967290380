import React, { Component } from 'react';
import './App.css';
import { HashRouter,Route } from 'react-keeper';
import Login from './compontents/site/login/index';
import Main from './compontents/site/main/index';
import store from './store';
import { SET_LOGIN_STATUS } from './store/action';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    };
  }

  componentDidMount() {
    if (!!window.sessionStorage.getItem('USERNAME')) {
      store.dispatch({ type: SET_LOGIN_STATUS, key: 'isLogin', status: true });
    }
  }
  render() {
    return (
      <div className="overFlow_box">
        <HashRouter>
          <div className="overFlow_box">
            <Route path="/" component={Main} cache></Route>
            <Route path="/login" component={Login} ></Route>
            {/* <Route path="/register" component={Register}></Route> */}
          </div>
        </HashRouter>
      </div>
    );
  }
}

export default App;
