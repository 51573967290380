// export const Increment='increment'
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const SET_MENU_STATUS = 'SET_MENU_STATUS';

export const OPEN_TAB = 'OPEN_TAB';

export const ACTIVE_MENU_KEY = 'ACTIVE_MENU_KEY';

export const setLoginStatus = (status) => ({
    type:SET_LOGIN_STATUS,
    status
})

export const setLoadingStatus = (status) => ({
    type:SET_LOADING_STATUS,
    status
})

export const setMenuStatus = () =>({
    type:SET_MENU_STATUS
})

export const openTab = (config) =>({
    type:OPEN_TAB,
    config
})

export const activeMenuKey = (status)=>({
    type:ACTIVE_MENU_KEY,
    status
})