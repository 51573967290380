import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,Input} from 'antd';
const { Option } = Select;

class AddPromotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sid:'',
            source:'',
            remark:'',
            type:'',
            url:''
        };

    }
    handleSidChange=(e)=>{
        this.setState({
            sid:e.target.value
        })
    }
    handleAddressChange=(e)=>{
        this.setState({
            url:e.target.value
        })
    }
    handleSourceChange=(e)=>{
        this.setState({
            source:e.target.value
        })
    }
    handleRmkChange=(e)=>{
        this.setState({
            remark:e.target.value
        })
    }
    handlePlatformChange=(e)=>{
        this.setState({
            type:e
        })
    }

    submit=()=>{
        const { sid,source,remark,type,url } = this.state;
        this.props.$request('/sid/add',{
            sid,source,remark,type,url
        },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }

    
    render() {
        const { sid,source,remark,type,url } = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="新增推广来源"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}><div style={style}>推广来源:</div></Col>
                    <Col span={18}><Input value={sid} onChange={this.handleSidChange}/></Col>
                    <Col span={6}><div style={style}>来源名称:</div></Col>
                    <Col span={18}><Input value={source} onChange={this.handleSourceChange}/></Col>
                    <Col span={6}><div style={style}>推广地址:</div></Col>
                    <Col span={18}><Input value={url} onChange={this.handleAddressChange}/></Col>
                    <Col span={6}><div style={style}>适用平台:</div></Col>
                    <Col span={18}>
                        <Select value={type} onChange={this.handlePlatformChange} style={{width:'100%'}}>
                            <Option value={1}>卖家端</Option>
                            <Option value={2}>买家端</Option>
                        </Select>
                    </Col>
                    <Col span={6}><div style={style}>备注:</div></Col>
                    <Col span={18}><Input.TextArea value={remark} onChange={this.handleRmkChange}/></Col>
                    <Col span={6}></Col>
                    <Col span={18}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!sid&&!!url&&!!source&&!!type)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(AddPromotion);