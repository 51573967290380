import React, { Component } from 'react';

import '../widget/widget.scss';

class Loading extends Component {

  render() {
    return (
      <div className="loading_modal">
        <div className="loading_cover">
          <div className="loader">
            <div className="item-1"><span></span>
            </div>
            <div className="item-2"><span></span>
            </div>
            <div className="item-3"><span></span>
            </div>
            <div className="item-4"><span></span>
            </div>
            <div className="item-5"><span></span>
            </div>
            <div className="item-6"><span></span>
            </div>
            <div className="item-7"><span></span>
            </div>
            <div className="item-8"><span></span>
            </div>
            <div className="loading_text">加载中...</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;