import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,Select, message ,DatePicker} from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import ByPass from './byPass';
const { RangePicker } = DatePicker;
const {Option } = Select;
const tconfig = {
    url: '/buyer/review-error-list',
    param: {},
    callback: null,
    columns: []
}
class ReviewByPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                id: '',
                result: '',
                begin:'',
                end:''
            },
            columns: [],
            byPassStatus:false,
            optionItem:null
        };

    }

    // 打开绕过弹框
    openByPassModal=(item)=>{
        this.setState({
            byPassStatus:true,
            optionItem:item
        })
    }
    // 关闭绕过弹框
    closeByPassModal=(item)=>{
        this.setState({
            byPassStatus:false,
            optionItem:{}
        })
    }
    // 通过评论
    doByPass=(item)=>{
        this.props.$request('/buyer/review-success',{id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.doQuery();
            }
        })
    }
    // 已查验
    doExamine=(item)=>{
        this.props.$request('/buyer/review-see',{id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.doQuery();
            }
        })
    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '内部订单号',
                dataIndex: 'request_id',
                key: 'request_id',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_uid) - parseFloat(b.buyer_uid),
            }, {
                title: '买家 Id',
                dataIndex: 'buyer_uid',
                key: 'buyer_uid',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_uid) - parseFloat(b.buyer_uid),
            },{
                title: 'profile',
                dataIndex: 'profile',
                key: 'profile',
                width:200,
            },{
                title: 'review中profile',
                dataIndex: 'review_info_profileurl',
                key: 'review_info_profileurl',
                width:200,
            },{
                title: 'review链接',
                dataIndex: 'review_url',
                key: 'review_url',
                width:200,
            }, {
                title: 'review提交时间',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 100, 
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '评论检测完成时间',
                dataIndex: 'done_time',
                key: 'done_time',
                width: 150,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '错误原因',
                dataIndex: 'error',
                key: 'error',
                width:200,
            }, {
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 80,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [
                        <Button size="small" onClick={()=>{
                            // this.openByPassModal(b)
                            this.doByPass(b)
                        }}>评论完成</Button>,

                        <Button size="small" onClick={()=>{
                            this.doExamine(b)
                        }}>已查验</Button>
                    ];
                    return btns
                },
            }]
        })

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleBuyerIdChange = (e) => {
        this.setParam({ 'id': e.target.value})
    }

    handleResultChange = (e) => {
        this.setParam({ 'result': e})
    }

    handleSuccessTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }
    
    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent();
        })
    }

    render() {
        const { param, config, columns,byPassStatus,optionItem } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">买家Id</span>
                    <Input className="inline_el" value={param['id']} onChange={this.handleBuyerIdChange} />
                    <span className="label">结果</span>
                    <Select value={param['result']} onChange={this.handleResultChange} style={{marginRight:20}}>
                        <Option value="">全部</Option>
                        <Option value="1">成功</Option>
                        <Option value="2">失败</Option>
                    </Select>

                    <span className="label" style={{marginRight:10}}>检测完成时间</span>
                    <RangePicker className="add_margin" onChange={this.handleSuccessTimeChange} />
                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} />

                {byPassStatus?<ByPass item={optionItem} close={this.closeByPassModal}/>:''}
            </div>
        );
    }
}

export default BaseHoc(ReviewByPass);