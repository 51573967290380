import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button} from 'antd';
import moment from 'moment';
const {Option} = Select;

class ChangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            level:'',
            end:'',
            charge:''
        };
    }

    handlerPackageChange=(e)=>{
        this.setState({
            level:e
        })
    }

    handlerEndChange=(e,b)=>{
        this.setState({
            end:b
        })
    }

    handlerChargeChange=(e)=>{
        this.setState({
            charge:e
        })
    }

    doSubmit=()=>{
        const {level,end,charge} = this.state;
        const {item,close,reload} = this.props;
        this.props.$request('/seller/edit-user-info',{id:item['id'],level,charge,end},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                reload();
                close();
            }
        })
    }
    // '0'=>'0',
    // '1'=>'29.99',
    // '2'=>'49.99',
    // '3'=>'99.99',
    // '4'=>'179.99',
    // '5'=>'39.99',
    // '6'=>'59.99',
    // '7'=>'119.99',
    // '8'=>'199.99',
    // '9'=>'49.99', 60%留评
    // '10'=>'49.99', 质保
    // '11'=>'79.99',

    render() {
        const { level,end,charge } = this.state;
        const { item } = this.props;
        const style = {width:300,marginBottom:10}
        return (
            <Modal 
                visible={true}
                title="修改卖家信息"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>卖家 ID:</Col>
                    <Col span={18} style={style}>{item['id']}</Col>

                    <Col span={6}>套餐等级:</Col>
                    <Col span={18}>
                        <Select value={level} onChange={this.handlerPackageChange} style={style}>
                            <Option value="5">39.99</Option>
                            <Option value="9">49.99（60%高留评套餐）</Option>
                            <Option value="10">49.99（退款质保）</Option>
                            <Option value="6">59.99</Option>
                            <Option value="11">79.99 (双保险)</Option>
                            <Option value="7">119.99</Option>
                            <Option value="8">199.99</Option>
                        </Select>
                    </Col>

                    <Col span={6}>到期时间:</Col>
                    <Col span={18}>
                        <DatePicker showTime style={style} onChange={this.handlerEndChange}/>
                    </Col>

                    <Col span={6}>客服:</Col>
                    <Col span={18}>
                        <Select value={charge} onChange={this.handlerChargeChange} style={style}>
                            <Option value="C">C-小陈</Option>
                            <Option value="B">B-小陈</Option>
                            <Option value="S">S-玄烨</Option>
                            <Option value="F">F-玄烨</Option>
                            <Option value="Y">Y-鸿雁</Option>
                            <Option value="H">H-鸿雁</Option>
                            <Option value="Z">Z-小白</Option>
                            <Option value="X">X-小白</Option>
                        </Select>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} >提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(ChangeInfo);