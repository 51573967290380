import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';

import { Select, Input, Button, PageHeader, Row, Col, message } from 'antd';

const { Option } = Select;

class AddMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentIds: [],
            parentId: '',
            name: '',
            key: ''
        };

    }
    componentDidMount() {
        this.loadParentIds();
    }

    loadParentIds = () => {
        let parentIds = [{ id: 0, name: '无' }];
        this.props.$request('/config/get-menu-list', {}, (res) => {
            if (res['code'] === 200) {
                parentIds = parentIds.concat(res['data']);
                this.setState({
                    parentIds: parentIds,
                    parentId: '',
                    name: '',
                    key: ''
                })
            }
        })
    }

    handlerChangeParentId = (e) => {
        this.setState({
            parentId: e
        })
    }

    handlerNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    handlerKeyChange = (e) => {
        this.setState({
            key: e.target.value
        })
    }

    handlerAdd = () => {
        const { parentId, name, key } = this.state;
        this.props.$request('/config/add-menu', {
            name: name,
            key: key,
            parent_id: parentId
        }, () => {
            message.success('新增成功');
            this.loadParentIds();
        })
    }
    render() {
        const { parentIds, parentId, name, key } = this.state;
        const iptStyles = { width: '300px', marginBottom: '20px' };
        return (
            <div className="content_tabs_box">
                <PageHeader title="新增菜单" style={{ paddingLeft: '0' }} />
                <Row>
                    <Col span={4}> 父级菜单: </Col>
                    <Col span={20}>
                        <Select style={iptStyles} value={parentId} onChange={this.handlerChangeParentId}>
                            {parentIds.map((i, k) =>
                                <Option value={i['id']} key={k}>{i['name']}</Option>
                            )}
                        </Select>
                    </Col>

                    <Col span={4}> name: </Col>
                    <Col span={20}>
                        <Input style={iptStyles} type="text" value={name} onChange={this.handlerNameChange}></Input>
                    </Col>

                    <Col span={4}> key: </Col>
                    <Col span={20}>
                        <Input style={iptStyles} type="text" value={key} onChange={this.handlerKeyChange}></Input>
                    </Col>

                    <Col span={4}></Col>
                    <Col span={20}>
                        <Button type="primary" disabled={!(!!name&&!!key)} onClick={this.handlerAdd}>增加</Button>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default BaseHoc(AddMenu);