import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
const tconfig = {
    url: '/seller/get-seller-capital-list',
    param: {},
    callback: null,
    columns: []
}
class BuyerCapitalRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                seller_uid: '',
                type: '',
                begin: '',
                end: '',
                currency: '',
                orderid: '',
                tid: '',
                transactionType:'',
                pay_type:'',
                source:''
            },
            columns: [],
            tableInfo:null
        };

    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '卖家 Id',
                dataIndex: 'seller_uid',
                key: 'seller_uid',
                width: 100, sorter: (a, b) => parseFloat(a.seller_uid) - parseFloat(b.seller_uid),
            }, {
                title: '买家 Id',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_id) - parseFloat(b.buyer_id),
            }, {
                title: '创建时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 150,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '金额',
                dataIndex: 'account',
                key: 'account',
                width: 150, sorter: (a, b) => parseFloat(a.account) - parseFloat(b.account),
                render: (a, b) => {
                    return a + b['currency']
                }
            }, {
                title: '操作订单号',
                dataIndex: 'orderid',
                key: 'orderid',
                width: 250
            }, {
                title:'交易号',
                dataIndex:'transaction_id',
                key:'transaction_id',
                width:250
            },{
                title: '类型',
                dataIndex: 'account_type',
                key: 'account_type',
                width: 150,
                render: (a) => {
                    switch (a) {
                        case '1':
                            return '余额冻结支出'
                        case '2':
                            return '余额解冻收入'
                        case '3':
                            return '冻结转账支出'
                        case '4':
                            return '余额手动充值收入'
                        case '5':
                            return '订阅'
                        case '6':
                            return '余额赠送(注册推荐用)'
                        case '7':
                            return '后台增加'
                        case '8':
                            return '后台扣除'
                        case '9':
                            return '后台清零'
                        case '10':
                            return '转账退回'
                        case '11':
                            return '转账扣款'
                        case '12':
                            return '更改订阅后返回差额'
                        case '13':
                            return '争议清零'
                        case '14':
                            return '争议恢复'
                        case '15':
                            return '竞品监控'
                        case '16':
                            return '自建站'
                        case '17':
                            return '卖家保证金'
                        case '18':
                            return '子站asin分析'
                        case '19':
                            return '商品上架流量包'
                        case '20':
                            return '申请提现,等待审核'
                        case '21':
                            return '申请提现-已转账'
                        case '22':
                            return '申请提现-审核失败'
                    }
                }
            },{
                title: '交易状态',
                dataIndex: 'account_result',
                key: 'account_result',
                width: 150,
                render: (a) => {
                    switch (a) {
                        case '0':
                            return '失败'
                        case '1':
                            return '成功'
                        case '2':
                            return '进行中'
                        case '3':
                            return '未付款'
                    }
                }
            },{
                title: '备注',
                dataIndex: 'msg',
                key: 'msg',
                width: 80
            }]
        })
        this.doQuery();
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleIdChange = (e) => {
        this.setParam({ 'seller_uid': e.target.value })
    }

    handleOrderIdChange = (e) => {
        this.setParam({ 'orderid': e.target.value })
    }

    handleTIdChange = (e) => {
        this.setParam({ 'tid': e.target.value })
    }

    handleTypeChange = (e) => {
        this.setParam({ 'type': e })
    }

    handleSourceChange=(e)=>{
        this.setParam({ 'source': e })
    }

    handleCurrencyChange = (e) => {
        this.setParam({ 'currency': e })
    }

    handleTrTypeChange = (e) => {
        this.setParam({ 'transactionType': e })
    }
    
    handleOptionTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handlePayTypeChange=(e)=>{
        this.setParam({ 'pay_type': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                console.log(data)
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,tableInfo } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">卖家Id</span>
                    <Input className="inline_el_min" value={param['seller_uid']} onChange={this.handleIdChange} />

                    <span className="label">流水类型</span>
                    <Select value={param['type']} onChange={this.handleTypeChange} className="inline_el">
                        <Option value="">全部</Option>
                        <Option value={1}>余额冻结支出</Option>
                        <Option value={2}>余额解冻收入</Option>
                        <Option value={3}>冻结转账支出</Option>
                        <Option value={4}>余额手动充值收入</Option>
                        <Option value={5}>订阅 </Option>
                        <Option value={6}>余额赠送(注册推荐用)</Option>
                        <Option value={7}>后台增加</Option>
                        <Option value={8}>后台扣除</Option>
                        <Option value={9}>后台清零</Option>
                        <Option value={10}>转账退回</Option>
                        <Option value={11}>转账扣款</Option>
                        <Option value={12}>更改订阅后返回差额</Option>
                        <Option value={13}>争议清零</Option>
                        <Option value={14}>争议恢复</Option>
                        <Option value={17}>卖家保证金</Option>
                    </Select>

                    <span className="label">流水来源</span>
                    <Select value={param['source']} onChange={this.handleSourceChange} className="inline_el">
                        <Option value="">全部</Option>
                        <Option value={1}>普通订单</Option>
                        <Option value={2}>额外返现订单</Option>
                        <Option value={3}>退款保障订单</Option>
                        <Option value={4}>佣金</Option>
                    </Select>

                    <span className="label">操作时间</span>
                    <RangePicker className="add_margin" onChange={this.handleOptionTimeChange} />

                    <span className="label">币种</span>
                    <Select value={param['currency']} onChange={this.handleCurrencyChange} className="inline_el_min">
                        <Option value="">全部</Option>
                        <Option value="USD">USD</Option>
                        <Option value="EUR">EUR</Option>
                        <Option value="JPY">JPY</Option>
                        <Option value="GBP">GBP</Option>
                        <Option value="CAD">CAD</Option>
                    </Select>

                    <span className="label">订单号</span>
                    <Input className="inline_el" value={param['orderid']} onChange={this.handleOrderIdChange} />

                    <span className="label">交易号</span>
                    <Input className="inline_el" value={param['tid']} onChange={this.handleTIdChange} />
                   
                </div>
                <div className="tool_bar">

                    <span className="label">交易状态</span>
                    <Select value={param['transactionType']} onChange={this.handleTrTypeChange} className="inline_el_min">
                        <Option value="">全部</Option>
                        <Option value="0">失败</Option>
                        <Option value="1">成功</Option>
                        <Option value="2">进行中</Option>
                        <Option value="3">未付款</Option>
                    </Select>

                    <span className="label">充值方式</span>
                    <Select value={param['pay_type']} onChange={this.handlePayTypeChange} className="inline_el">
                        <Option value="">全部</Option>
                        <Option value="PPL">PayPal</Option>
                        <Option value="CCG">G2S</Option>
                        <Option value="ALIPAY">支付宝</Option>
                        <Option value="WX">微信</Option>
                        <Option value="pi">Stripe</Option>
                    </Select>

                    <Button type="primary" onClick={this.doQuery}>查询</Button>
                    {!!tableInfo?<div style={{display:'inline-block',marginLeft:20}}> 笔数:{tableInfo['total']} 、总金额: {tableInfo['sum']} <a href={tableInfo['url']} style={{marginLeft:20}}>导出</a></div>:''}
                </div>

                
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(BuyerCapitalRecord);