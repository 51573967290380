import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Row, Col ,Table } from 'antd';
const columns = [{
    title:'日期',
    dataIndex:'begin',
    key:'begin',
    width:'25%',
    align:'center'
},{
    title:'申请订单数 (不含中介)',
    dataIndex:'count1',
    key:'count1',
    width:'25%',
    align:'center'
},{
    title:'同意订单数 (不含中介)',
    dataIndex:'count2',
    key:'count2',
    width:'25%',
    align:'center'
},{
    title:'提交订单号数 (不含中介)',
    dataIndex:'count3',
    key:'count3',
    width:'25%',
    align:'center'
}]
class OrderStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
           data:null
        };
    }
    componentDidMount(){
        this.loadStatisticsData();
    }

    loadStatisticsData=()=>{
        this.props.$request('/stat/request',{},(res)=>{
            if(res['code']===200){
                this.setState({
                    data:res['data']
                })
            }
        })
    }
    render() {
        const { data } = this.state;
        return (
            <div className="content_tabs_box">
                {!!data?<Row>
                    <Col span="24">
                        <div style={{marginBottom:'20px'}}>统计周期：{data[0]['begin']} - {data[6]['end']}</div>
                    </Col>
                    <Col span="24">
                        <Table dataSource={data} columns={columns} pagination={false}/>
                    </Col>
                </Row>:''}
            </div>
        );
    }
}

export default BaseHoc(OrderStatistics);