import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Input, Button, Row, Col, PageHeader, Modal, message} from 'antd';
import '../../../base/index.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;


class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            param: {
                amz_order_id: '',
                request_id: '',
                seller_id: ''
            },
            orderDatail: null
        };

    }



    componentDidMount() {
        console.log('self did mount');
        this.doQuery()
    }

    setParam = (p, val) => {
        const { param } = this.state;
        param[p] = val;
        for (let key in param) {
            if (!!param[key]) {
                break;
            }
        }
        this.setState({
            param: param
        })
    }
    handleOrderIdChange = (e) => {
        this.setParam('amz_order_id', e.target.value)
    }

    handleRequestIdChange = (e) => {
        this.setParam('request_id', e.target.value)
    }

    handleSellerIdChange =(e)=>{
        this.setParam('seller_id', e.target.value)
    }

    doQuery = () => {
        const { param } = this.state;
        this.props.$request('/order/info', param, (res) => {
            console.log(res);
            if (res['code'] === 200) {
                this.setState({
                    orderDatail: res['data']['data']
                })
            }
        })

    }

    doReloadCashback=()=>{
        const { orderDatail } = this.state;
        this.props.$request('/order/flush-extra-order',{request_id:orderDatail['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功！')
            }
        })
    }

    // 扣退款二次确认
    doRefundRebate=()=>{
        const { orderDatail } = this.state;
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否开启退款?',
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk() {
                me.props.$request('/order/deduct-buyer-account',{request_id:orderDatail['id']},(res)=>{
                  if(res['code']===200){
                      message.success('操作成功！')
                  }
                })
            }
        });
    }

    render() {
        const { param, orderDatail} = this.state;
        const style = { padding: '8px' };
        let reviewType = ''
        if (!!orderDatail) {
            if (orderDatail['unnecessaryReview'] === '1') {
                if (orderDatail['image_extra_return'] === '1') {
                    reviewType = '图片留评'
                } else if (orderDatail['video_extra_return'] === '1') {
                    reviewType = '视频留评'
                } else if (orderDatail['encourageReview'] === '1') {
                    reviewType = '文字留评'
                } else {
                    if (orderDatail['encourageReviewFree'] === '1') {
                        reviewType = '留评单'
                    }else{
                        reviewType = '标准测评'
                    }
                }
            } else {
                reviewType = '免评单'
            }
        }
        return (
            <div className="content_tabs_box" style={{overflow:'auto'}}>
                <div className="tool_bar">
                    <span className="label" style={{ marginRight: 20 }}>亚马逊订单号</span>
                    <Input className="inline_el" style={{ width: 200 }} value={param['amz_order_id']} onChange={this.handleOrderIdChange} />

                    <span className="label" style={{ marginRight: 20 }}>卖家id</span>
                    <Input className="inline_el" style={{ width: 100 }} value={param['seller_id']} onChange={this.handleSellerIdChange} />

                    <span className="label" style={{ marginRight: 20 }}>内部订单号</span>
                    <Input className="inline_el" style={{ width: 300 }} value={param['request_id']} onChange={this.handleRequestIdChange} />

                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    {!!orderDatail?<Button style={{marginLeft:20}} onClick={this.doReloadCashback}>刷新额外返现</Button>:''}

                    {!!orderDatail?<Button style={{marginLeft:20}} onClick={this.doRefundRebate}>开启退款申请</Button>:''}
                </div>

                {!!orderDatail ? <div className="order_detail">

                    <PageHeader title="商品信息" style={{ paddingLeft: '0' }} />
                    <Row>
                        <Col span={8}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={orderDatail['image']} style={{ maxWidth: 200, maxHeight: 200 }} alt="" />
                            </div>
                        </Col>
                        <Col span={16}>
                            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                                {orderDatail['product_title']}
                            </div>
                            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                                <div dangerouslySetInnerHTML={{ __html: orderDatail['feature'] }}></div>
                            </div>
                            <div style={style}>
                                <a href={orderDatail['rawUrl']} target="_blank">{orderDatail['rawUrl']}</a>
                            </div>
                        </Col>
                        <Col span={6}>商品ID：{orderDatail['product_id']} </Col>
                        <Col span={6}>ASIN: {orderDatail['product_asin']}</Col>
                        <Col span={6}>站点：{orderDatail['countryCode']}</Col>
                        <Col span={6}>金额：{parseFloat(orderDatail['price']) / 100} {orderDatail['currency']}</Col>
                    </Row>
                    <PageHeader title="卖家信息" style={{ paddingLeft: '0' }} />
                    <Row>
                        <Col span={6}>
                            <div style={style}>
                                卖家 Id：{orderDatail['seller_uid']}
                            </div>
                        </Col>
                        <Col span={6}>
                            <div style={style}>
                                卖家邮箱：{orderDatail['seller_email']}
                            </div>
                        </Col>
                    </Row>
                    <PageHeader title="买家信息" style={{ paddingLeft: '0' }} />
                    <Row>
                        <Col span={6}><div style={style}>
                            买家 Id：{orderDatail['buyer_uid']}</div></Col>
                        <Col span={6}><div style={style}>
                            买家邮箱：{orderDatail['buyer_email']}</div></Col>
                        <Col span={6}><div style={style}>
                            买家等级：{orderDatail['buyer_level']}</div></Col>
                        <Col span={6}><div style={style}></div></Col>
                    </Row>
                    <PageHeader title="订单信息" style={{ paddingLeft: '0' }} />
                    <Row>
                        <Col span={6}><div style={style}>
                            订单id：{orderDatail['id']}</div></Col>
                        <Col span={6}><div style={style}>
                            订单金额：{parseFloat(orderDatail['price_rebate']) / 100} {orderDatail['currency']}</div></Col>
                        <Col span={6}><div style={style}>
                            订单类型：{orderDatail['discountType'] === '1' ? '返现' : '优惠券'}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            评论类型：{reviewType}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            订单状态：{this.props.getStatus(orderDatail['request_status'])}</div>
                        </Col>

                        <Col span={6}><div style={style}>
                            亚马逊订单号：{orderDatail['amz_order_id']}</div>
                        </Col>

                        <Col span={6}><div style={style}>
                            订单创建时间：{orderDatail['create_request_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            订单同意时间：{orderDatail['approve_request_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            亚马逊订单号提交时间：{orderDatail['submit_orderid_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            确认亚马逊订单号时间：{orderDatail['confirm_orderid_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            提交reivew 时间：{orderDatail['submit_review_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            返现时间：{orderDatail['cash_back_success_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            完成检测评价时间：{orderDatail['done_review_datetime']}</div>
                        </Col>
                        <Col span={6}><div style={style}>
                            review链接：<a href={orderDatail['review_url']} target="_blank">{orderDatail['review_url']}</a></div>
                        </Col>
                    </Row>
                </div> : ''}
            </div>
        );
    }
}

export default BaseHoc(OrderDetail);