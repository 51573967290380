import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
const tconfig = {
    url: '/finance/pay-stat',
    param: {},
    callback: null,
    columns: []
}
class DomesticCollectionStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                begin: '',
                end: '',
                currency: '',
                pay_type:'ALIPAY'
            },
            columns: [],
            tableInfo:null
        };

    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: '卖家邮箱',
                dataIndex: 'email',
                key: 'email',
                width: 200,
            }, {
                title: '充值时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 150,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '金额（充值币种）',
                dataIndex: 'account',
                key: 'account',
                width: 150,
                sorter: (a, b) => parseFloat(a.account) - parseFloat(b.account),
                render: (a, b) => {
                    return a +' '+ b['currency']
                }
            },{
                title: '充值汇率',
                dataIndex: 'exchange_rate',
                key: 'exchange_rate',
                width: 150,
                render: (a, b) => {
                    return a
                }
            }, {
                title: '平台手续费（人民币）',
                dataIndex: 'msg',
                key: 'msg',
                width: 150,
                render: (a, b) => {
                    return a
                }
            },{
                title: '总金额（人民币）',
                dataIndex: 'cny',
                key: 'cny',
                width: 150, 
                render: (a, b) => {
                    return a
                }
            },{
                title: '操作订单号',
                dataIndex: 'transaction_id',
                key: 'transaction_id',
                width: 250
            },]
        })
        this.doQuery();
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleCurrencyChange = (e) => {
        this.setParam({ 'currency': e })
    }
    
    handleOptionTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handlePayTypeChange=(e)=>{
        this.setParam({ 'pay_type': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                console.log(data)
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,tableInfo } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">操作时间</span>
                    <RangePicker className="add_margin" onChange={this.handleOptionTimeChange} />

                    <span className="label">币种</span>
                    <Select value={param['currency']} onChange={this.handleCurrencyChange} className="inline_el_min">
                        <Option value="">全部</Option>
                        <Option value="USD">USD</Option>
                        <Option value="EUR">EUR</Option>
                        <Option value="JPY">JPY</Option>
                        <Option value="GBP">GBP</Option>
                        <Option value="CAD">CAD</Option>
                    </Select>
                   
                    <span className="label">收款渠道</span>
                    <Select value={param['pay_type']} onChange={this.handlePayTypeChange} className="inline_el">
                        <Option value="ALIPAY">支付宝</Option>
                        <Option value="WX">微信</Option>
                    </Select>

                    <Button type="primary" onClick={this.doQuery}>查询</Button>
                    {!!tableInfo?<div style={{display:'inline-block',marginLeft:20}}><a href={tableInfo['url']} style={{marginLeft:20}}>导出</a></div>:''}
                </div>

                
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(DomesticCollectionStatistics);