import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SubmitDrewing from './submitDrawing';
import SubmitOrderId from './submitOrderId';
const { Option } = Select;
const tconfig = {
    url: '/medium/dg-order-list',
    param: {},
    callback: null,
    columns: []
}
class MediumPurchasingOrderlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                status: 1,
            },
            columns: [],
            tableInfo:'',
            optionItem:[],
            submitDrawingStatus:false,
            submitOrderIdStatus:false
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title:'商品图',
                dataIndex: 'product_image',
                key: 'product_image',
                width: 140,
                render:(a,b)=>{
                    return <img src={a} style={{maxHeight:120,maxWidth:120}} alt="" />
                }
            },{
                title:'商品Title',
                dataIndex: 'product_title',
                key: 'product_title',
                width: 150,
                render:(a,b)=>{
                    return <a className="product_title" href={b['product_url']} target="_blank">{a}</a>
                }
            },{
                title:'订单类型',
                dataIndex: 'product_source',
                key: 'product_source',
                width: 120,
            },{
                title: '商品原价',
                dataIndex: 'amazon_price',
                key: 'amazon_price',
                width: 150, sorter: (a, b) => parseFloat(a.amazon_price) - parseFloat(b.amazon_price),
                render: (a, b) => {
                    return a + b['currency']
                }
            },{
                title: '税费(参考)',
                dataIndex: 'tax',
                key: 'tax',
                width: 100
            },{
                title: '税率(参考)',
                dataIndex: 'address_tax',
                key: 'address_tax',
                width: 100
            },{
                title: '数量',
                dataIndex: 'count',
                key: 'count',
                width: 150
            },{
                title:'创建时间',
                dataIndex:'change_medium_time',
                key:'change_medium_time',
                width: 200,
            },{
                title:'提交亚马逊/沃尔玛订单号时间',
                dataIndex:'submit_orderid_time',
                key:'submit_orderid_time',
                width: 300,
            }, {
                title:'提交发货图时间',
                dataIndex:'delivery_time',
                key:'delivery_time',
                width: 200,
            },{
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 150,
                // render: (a) => {
                //     if (a === '1') {
                //         return '待提交订单号'
                //     } else if (a === '2') {
                //         return '待提交发货图'
                //     }
                // }
            }, {
                title:'收货信息',
                dataIndex:'region',
                key:'region',
                width:400,
                render: (a,b) => {
                    return <div>
                        姓名: {b['full_name']}<br/>
                        手机号: {b['phone']}<br/>
                        地址: {b['address']}<br/>
                        城市: {b['city']} 洲: {b['state']} 国家: {b['region']} <br/>
                        邮编: {b['zip_code']}
                    </div>
                }

            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 200,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [];
                    if(b.order_status==='1'){
                        btns.push(<Button size="small" onClick={()=>{
                            this.openSubmitOrderIdModal(b)
                        }}>提交亚马逊/沃尔玛订单号</Button>)
                    }else if(b.order_status==='2'){
                        btns.push(<Button size="small" onClick={()=>{
                            this.openSubmitDrawingModal(b)
                        }}>上传发货信息</Button>)
                    }
                    return btns;
                },
            }]
        })

        this.doQuery();
    }


    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleStatusChange = (e) => {
        this.setParam({ 'status': e})
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    closeSubmitDrawingModal=()=>{
        this.setState({
            submitDrawingStatus: false,
            optionItem:null
        })
    }
    // 提交发货图
    openSubmitDrawingModal=(item)=>{
        this.setState({
            submitDrawingStatus: true,
            optionItem: item
        })
    }
    
    closeSubmitOrderIdModal=()=>{
        this.setState({
            submitOrderIdStatus: false,
            optionItem:null
        })
    }

    // 提交亚马逊订单号
    openSubmitOrderIdModal=(item)=>{
        this.setState({
            submitOrderIdStatus: true,
            optionItem: item
        })
    }

    // 审核失败
    examineFail=(item)=>{
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '拒绝此发货图?',
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk() {
                me.props.$request('',{id:item['id']},(res)=>{
                  if(res['code']===200){
                      me.doQuery();
                  }
                })
            }
        });
    }

    // 0-提交下单信息 1-已完成付款 ，等待提交亚马逊订单号 2-已提交亚马逊订单号 3-已提交发货图 4-发货图审核成功 5-订单退款
    render() {
        const { param, config, columns,optionItem,submitDrawingStatus,submitOrderIdStatus } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">状态</span>
                    <Select value={param['status']} onChange={this.handleStatusChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={1}>待提交订单号</Option>
                        <Option value={2}>待提交发货图</Option>
                    </Select>

                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>

                {submitDrawingStatus?<SubmitDrewing item={optionItem} close={this.closeSubmitDrawingModal} reload={this.doQuery}/>:''}
                {submitOrderIdStatus?<SubmitOrderId item={optionItem} close={this.closeSubmitOrderIdModal} reload={this.doQuery}/>:''}
            
            </div>
        );
    }
}

export default BaseHoc(MediumPurchasingOrderlist);