import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,Select, message,DatePicker ,Modal} from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import AccountInfo from './accountInfo';
import RebateSuccess from './rebateSuccess';
import EditTrid from './editTrid';
const { RangePicker } = DatePicker;
const {Option} = Select;

const tconfig = {
    url: '/cash/list',
    param: {},
    callback: null,
    columns: []
}
class RebateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                buyer_id: '',
                seller_id: '',
                refund_type: '',
                amz_order_id:'',
                status:'',
                begin:'',
                end:'',
                currency:'',
                success_begin:'',
                success_end:''
            },
            columns: [],
            optionItem:{},
            showAccountStatus:false,
            rebateSuccessStatus:false,
            editTridStatus:false
        };
    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '买家 Id',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_id) - parseFloat(b.buyer_id),
            }, {
                title: '卖家 Id',
                dataIndex: 'extra_seller_id',
                key: 'extra_seller_id',
                width: 100, sorter: (a, b) => parseFloat(a.extra_seller_id) - parseFloat(b.extra_seller_id),
            }, {
                title: '创建时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 150,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '金额',
                dataIndex: 'account',
                key: 'account',
                width: 150, sorter: (a, b) => parseFloat(a.account) - parseFloat(b.account),
                render: (a, b) => {
                    return a + b['currency']
                }
            }, {
                title: '返现订单号',
                dataIndex: 'item_id',
                key: 'item_id',
                width: 250
            }, {
                title: '流水订单号',
                dataIndex: 'order_list',
                key: 'order_list',
                width: 250
            }, {
                title: '交易号',
                dataIndex: 'transaction_id',
                key: 'transaction_id',
                width: 250
            },{
                title:'返现方式',
                dataIndex:'refund_type',
                key:'refund_type',
                width:150,
                render: (a, b) => {
                    if(a==='1'){
                        return 'Paypal'
                    }else if(a==='2'){
                        return '银行卡'
                    }
                }
            }, {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: 150
            }, {
                title:'返现完成时间',
                dataIndex:'update_time',
                key:'update_time',
                width:150
            },{
                title: '手续费',
                dataIndex: 'commission',
                key: 'commission',
                width: 80,
            }, {
                title: '操作',
                dataIndex: 'refund_type',
                key: 'refund_type',
                width: 120,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [
                        <Button size='small' onClick={()=>{this.showAccount(b)}}>返现信息</Button>
                    ]
                    if(this.props.getJurisdiction()==='1'){
                        if(b['status']==='等待返现'){
                            btns = btns.concat([
                                <Button size="small" onClick={()=>{this.successCash(b)}}>返现成功</Button>,
                                <Button size="small" onClick={()=>{this.faildCash(b)}}>审核失败</Button>
                            ])
                        }
                        if(b['status']==='返现成功'){
                            btns = btns.concat([
                                <Button size="small" onClick={()=>{this.openEditTrid(b)}}>修改交易号</Button>
                            ])
                        }
                    }
                    return btns;
                },
            }]
        })

        this.doQuery();

    }

    // 查看返现信息
    showAccount=(item)=>{
        this.setState({
            optionItem:item,
            showAccountStatus:true
        })
    }

    // 查看返现信息
    hideAccount=()=>{
        this.setState({
            optionItem:'',
            showAccountStatus:false
        })
    }


    //返现成功
    successCash=(item)=>{
        if(item['refund_type']==='1'){
            // paypal
            this.setState({
                optionItem:item,
                rebateSuccessStatus:true
            })
        }else{
            let me = this;
             // 银行卡啊
            Modal.confirm({
                title: '提示',
                content: '返现成功？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    me.props.$request('/cash/success',{id:item['id']},(res)=>{
                    if(res['code']===200){
                        message.success('操作成功！')
                        me.doQuery();
                    }
                })
                }
            });
           
        }
    }
    // 审核失败
    faildCash=(item)=>{
        let me = this;
        Modal.confirm({
            title: '提示',
            content: '确认审核失败？',
            okText: '确定',
            cancelText: '取消',
            centered:true,
            onOk() {
                me.props.$request('/cash/fail',{id:item['id']},(res)=>{
                  if(res['code']===200){
                    message.success('操作成功！')
                    me.doQuery();
                  }
              })
            }
        });
    }

    closeRebateSuccess=()=>{
        this.setState({
            optionItem:'',
            rebateSuccessStatus:false
        })
    }

    //修改交易号
    openEditTrid=(item)=>{
        this.setState({
            optionItem:item,
            editTridStatus: true
        })
    }

    closeEditTrid=()=>{
        this.setState({
            optionItem:'',
            editTridStatus: false
        })
    }


    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleBuyerIdChange = (e) => {
        this.setParam({ 'buyer_id': e.target.value})
    }

    handleSellerIdChange = (e) => {
        this.setParam({ 'seller_id': e.target.value })
    }

    handleRefundTypeChange =(e)=>{
        this.setParam({ 'refund_type': e })
    }

    handleCurrencyChange=(e)=>{
        this.setParam({ 'currency': e })
    }

    handleTypeChange=(e)=>{
        this.setParam({ 'status': e })
    }

    handleOptionTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }
    
    handleRebateTimeChange = (e, str) => {
        this.setParam({ 'success_begin': str[0], 'success_end': str[1] })
    }

    handleAmzIdChange = (e) => {
        this.setParam({ 'amz_order_id': e.target.value })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,optionItem,showAccountStatus ,tableInfo,rebateSuccessStatus,editTridStatus} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">买家Id</span>
                    <Input className="inline_el" value={param['buyer_id']} style={{width:80}} onChange={this.handleBuyerIdChange} />
                    <span className="label">卖家Id</span>
                    <Input className="inline_el" value={param['seller_id']} style={{width:80}} onChange={this.handleSellerIdChange} />
                    <span className="label" style={{ marginRight: 10 }}>亚马逊订单号</span>
                    <Input className="inline_el" value={param['amz_order_id']} onChange={this.handleAmzIdChange} />
                    <span className="label">币种</span>
                    <Select className="inline_el" value={param['currency']} onChange={this.handleCurrencyChange} >
                        <Option value="">全部</Option>
                        <Option value="USD">USD</Option>
                        <Option value="CAD">CAD</Option>
                        <Option value="EUR">EUR</Option>
                        <Option value="GBP">GBP</Option>
                        <Option value="JPY">JPY</Option>
                    </Select>
                    <span className="label">返现方式</span>
                    <Select className="inline_el" value={param['refund_type']} onChange={this.handleRefundTypeChange} >
                        <Option value="">全部</Option>
                        <Option value="1">Paypal</Option>
                        <Option value="2">银行卡</Option>
                    </Select>
                    <span className="label">返现状态</span>
                    <Select className="inline_el" value={param['status']}  onChange={this.handleTypeChange} >
                        <Option value="">全部</Option>
                        <Option value="0">等待审核</Option>
                        <Option value="1">返现成功</Option>
                        <Option value="2">返现失败</Option>
                        <Option value="3">拒绝返现</Option>
                        <Option value="4">等待返现</Option>
                    </Select>

                    <span className="label">申请提现时间</span>
                    <RangePicker className="add_margin" onChange={this.handleOptionTimeChange} />

                    <span className="label">返现完成时间</span>
                    <RangePicker className="add_margin" onChange={this.handleRebateTimeChange} />

                    <Button type="primary" style={{ marginLeft: 10 }} onClick={this.doQuery}>查询</Button>
                    <div style={{marginTop:10}}>
                    {!!tableInfo?<a href={tableInfo['url']} target="_blank">导出返现明细</a>:''}
                    {!!tableInfo?<a href={tableInfo['url2']} target="_blank" style={{marginLeft:10}}>导出银行卡返现</a>:''}
                    {!!tableInfo?<a href={tableInfo['url3']} target="_blank" style={{marginLeft:10}}>导出paypal返现</a>:''}
                    
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} />


                {showAccountStatus?<AccountInfo item={optionItem} close={this.hideAccount}/>:''}
                {rebateSuccessStatus?<RebateSuccess item={optionItem} close={this.closeRebateSuccess} reload={this.doQuery}/>:''}
                {editTridStatus?<EditTrid item={optionItem} close={this.closeEditTrid} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(RebateList);