import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Input,InputNumber,Select,Button} from 'antd';
const {Option} = Select;
class SendApproveCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approve_count:0
        };
    }

    handlerCountChange=(e)=>{
        this.setState({
            approve_count:e
        })
    }

    doSubmit=()=>{
        const {approve_count} = this.state;
        const {item,close,reload} = this.props;
        this.props.$request('/seller/send-approve-count',{id:item['id'],approve_count},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                reload();
                close();
            }
        })
    }

    render() {
        const { approve_count } = this.state;
        const { item } = this.props;
        const style = {width:300,marginBottom:10}
        return (
            <Modal 
                visible={true}
                title="赠送可测评订单数"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>卖家 ID:</Col>
                    <Col span={18} style={style}>{item['id']}</Col>

                    <Col span={6}>赠送数量:</Col>
                    <Col span={18}>
                        <InputNumber value={approve_count} onChange={this.handlerCountChange} min={0} style={style}/>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} disabled={!!approve_count?false:true}>提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(SendApproveCount);