import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,message,Row,Col} from 'antd';
class CloseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:''
        };
    }

    handlerRequestId = e => {
        this.setState({
            id: e.target.value
        })
    }

    // 提交订单号
    submitOrderId = () => {
        const {id } = this.state;
        this.props.$request('/order/close', {request_id:id}, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！');
                this.setState({
                    id:''
                })
            }
        })
    }
    render() {
        const { id} = this.state;
        const style = {
            marginBottom:20,
            width:300
        }
        return (
            <div className="content_tabs_box" style={{paddingTop:40}}>
                <Row>
                    <Col span={4} offset={4}>
                        内部订单号：
                    </Col>
                    <Col span={16}>
                        <Input style={style} value={id} onChange={this.handlerRequestId}/>
                    </Col>
                    <Col span={4} offset={4}>
                        <Button type="primary" disabled={!(!!id)} onClick={this.submitOrderId}>提交</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BaseHoc(CloseOrder);