import React, { Component } from 'react';
import store from '../../../store/index';
import { Layout } from 'antd';
import HeaderContainer from '../../layout/header';
import MenuList from '../../layout/menu';
import Center from '../../layout/center';
import {Control} from 'react-keeper';


const { Header, Sider, Content } = Layout;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isLogin:false
    };

  }

  
  componentDidMount() {
    this.listenerProps()
    store.subscribe(this.listenerProps);
  }

  listenerProps = () => {
    const { isLogin } = store.getState();
    if (!isLogin) {
      Control.go('/login')
    }
    this.setState({
      isLogin:isLogin
    })
  }
  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const {isLogin} = this.state;
    return (
      <div className="main">
        {isLogin?
        <Layout style={{ minHeight: '100vh' }}>
          <Header>
            <HeaderContainer />
          </Header>
          <Layout>
            <Sider collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
              <div style={{overflow:'auto',height: 'calc(100vh - 110px)'}}><MenuList /></div>
            </Sider>
            <Content>
              <Center />
            </Content>
          </Layout>
        </Layout>:''}
      </div>
    );
  }
}

export default Main;

