import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button, message ,Modal} from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import TransferAccounts from './transferAccounts';
import ChangeInfo from './changeInfo';
import GetUserAccount from './getUserAccount';
import CancelSubReason from './cancelSubReason';
import SetRmk from './setRmk';
import SendApproveCount from './sendApproveCount';
import SendProductCount from './sendProductCount';
import { ExclamationCircleOutlined } from '@ant-design/icons';



const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/seller/list',
    param: {},
    callback: null,
    columns: []
}
class SellerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                id: '',
                email: '',
                create_begin: '',
                create_end: '',
                cancel_begin: '',
                cancel_end: '',
                sb_begin: '',
                sb_end: '',
                reg_status: '',
                sub_status: '',
                pay_type: '',
                source:'',
                plan_type:''
            },
            columns: [],
            optionItem:null,
            transferAccountsModuls:false,

            changeInfoStatus:false,
            getUserAccountStatus:false,
            cancelSubReasonStatus:false,
            setRmkStatus:false,
            sendApproveCountStatus:false,
            sendProductCountStatus:false,
            tableInfo:'',
            

        };

    }

    // componentWillMount() {
    //     console.log('self will mount')
    // }

    openWithdrawl(item){
        this.props.$request('/seller/open-refund',{id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功')
            }
        })
    }
    //打开转账弹框
    openTransferAccountsModal=(item)=>{
        this.setState({
            optionItem:item,
            transferAccountsModuls:true
        })
    }
    // 关闭转账弹框
    closeTransferAccountsModal=()=>{
        this.setState({
            optionItem:null,
            transferAccountsModuls:false
        })  
    }

    //保证金退回
    refundBail=(item)=>{
        this.props.$request('/seller/unlock-bail',{id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功！')
            }
        })
    }

    // 余额订阅
    doSub =(item)=>{
        this.props.$request('/seller/sub',{id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功！')
            }
        })
    }
    //开关支付宝充值
    handlerAlipay=(item)=>{
        let act = '0'
        if(item['open_alipay']==='0'){
            act='1'
        }
        this.props.$request('/seller/alipay-oc',{id:item['id'],act:act},(res)=>{
            if(res['code']===200){
                message.success('操作成功！')
                this.doQuery();
            }
        })
    }

    // 打开拉黑弹框
    openBlockModal=(id,type)=>{
        //拉黑二次确认
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '你确定要'+(type==='0'?'拉黑':'取消拉黑')+'这个卖家吗?',
            okText: '是的',
            okType: 'danger',
            cancelText: '想想算了',
            centered:true,
            onOk() {
                me.props.$request('/seller/credit',{id:id},(res)=>{
                  if(res['code']===200){
                      me.doQuery();
                  }
              })
            }
        });
    }

    // 加入白名单二次确认
    addWhiteModal=(id,type)=>{
        //拉黑二次确认
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: 'id:'+id+'卖家的订单'+(type==='0'?'给中介分配':'不给中介分配')+'?',
            okText: '是的',
            cancelText: '取消',
            centered: true,
            onOk() {
                me.props.$request('/seller/is-distribute',{id:id},(res)=>{
                  if(res['code']===200){
                      me.doQuery();
                  }
              })
            }
        });
    }

    // 退回质保金
    handlerRefundDeposit=(item)=>{
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确认退回已超时的质保金?',
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk() {
                me.props.$request('/order/insurance-back',{id:item['id']},(res)=>{
                  if(res['code']===200){
                      me.doQuery();
                  }
                })
            }
        });
    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '邮箱',
                dataIndex: 'email',
                key: 'email',
                width: 200
            },
            //  {
            //     title: '用户名',
            //     dataIndex: 'username',
            //     key: 'username',
            //     width: 200
            // }, 
            {
                title: '注册时间',
                dataIndex: 'createtime',
                width: 200,
                key: 'createtime'
            }, 
            // {
            //     title:'注册 IP',
            //     dataIndex:'reg_ip',
            //     key:'reg_ip',
            //     width: 200,
            // },{
            //     title: '最后登录IP',
            //     dataIndex: 'last_login_ip',
            //     key: 'last_login_ip',
            //     width: 200,
            // },
            {
                title: '被留评率',
                dataIndex: 'review_rate',
                key: 'review_rate',
                width: 100,
            },{
                title:'累计推广产品',
                dataIndex:'product_count',
                key:'product_count',
                width: 200,
            },{ 
                title:'累计获得申请',
                dataIndex:'request_count',
                key:'request_count',
                width: 200,
            },{
                title:'累计同意申请',
                dataIndex:'approve_count',
                key:'approve_count',
                width: 200,
            },{
                title:'累计充值次数',
                dataIndex:'pay_count',
                key:'pay_count',
                width: 200,
            },{
                title:'赠送测评额度',
                dataIndex:'send_request',
                key:'send_request',
                width:150,
            },{
                title:'赠送可推广商品数',
                dataIndex:'send_product',
                key:'send_product',
                width:150,
            },{
                title: '取消时间',
                dataIndex: 'canceltime',
                key: 'canceltime',
                width: 200,
            }, {
                title: '到期时间',
                dataIndex: 'subscribe_period_end',
                key: 'subscribe_period_end',
                width: 200,
            },{
                title: '套餐类型',
                dataIndex: 'plan_type',
                width: 200,
                key: 'plan_type'
            }, {
                title: '套餐等级',
                dataIndex: 'level',
                key: 'level',
                width: 100,
                // render:(a,b)=>{
                //     if(a==='5'){
                //         return '39.99'
                //     }else if(a==='6'){
                //         return '59.99'
                //     }else if(a==='7'){
                //         return '119.99'
                //     }else if(a==='8'){
                //         return '199.99'
                //     }else if(a==='9'){
                //         return '49.99（60%高留评套餐）'
                //     }else if(a==='10'){
                //         return '49.99（退款质保）'
                //     }else if(a==='11'){
                //         return '79.99 (双保险)'
                //     }
                // }
            },{
                title: '套餐价格',
                dataIndex: 'level_price',
                key: 'level_price',
                width: 100,
            }, {
                title: '客服',
                dataIndex: 'person_in_charge',
                key: 'person_in_charge',
                width: 50,
            }, {
                title: 'QQ',
                dataIndex: 'qq',
                key: 'qq',
                width: 140,
            },{
                title:'手机号',
                dataIndex:'mobile',
                key:'mobile',
                width:120
            },{
                title: '信用卡后4位',
                dataIndex: 'card',
                key: 'card',
                width: 150,
            },{
                title:'订阅状态',
                dataIndex:'sub_status',
                key:'sub_status',
                width: 100,
                render:(a)=>{
                    if(a==='0'){
                        return '试用期'
                    }else if(a==='1'){
                        return '正式用户'
                    }else if(a==='2'){
                        return '取消用户'
                    }
                }
            },{
                title:'订阅方式',
                dataIndex:'checkout_type',
                key:'checkout_type',
                width: 100,
                render:(a)=>{
                    if(a==='1'){
                        return '先信用卡后余额'
                    }else if(a==='0'){
                        return '仅信用卡'
                    }else if(a==='2'){
                        return '仅余额'
                    }
                }
            },{
                title:'注册来源',
                dataIndex:'reg_source',
                key:'reg_source',
                width:200
            },{
                title:'推广来源',
                dataIndex:'sid',
                key:'sid',
                width:200
            },{
                title:'是否自动同意',
                dataIndex:'is_auto_accept',
                key:'is_auto_accept',
                width: 100,
                render:(a)=>{
                    if(a==='1'){
                        return '是'
                    }else{
                        return '否'
                    }
                }
            },{
                title:'是否特殊卖家',
                dataIndex:'request_count',
                key:'request_count',
                width: 100,
                render:(a)=>{
                    if(a==='0'){
                        return '是'
                    }else{
                        return '否'
                    }
                }
            },{
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render:(a)=>{
                    if(a==='1'){
                        return '已激活'
                    }else if(a==='0'){
                        return '未激活'
                    }
                }
            },{
                title:'取消原因',
                dataIndex:'cancel_msg',
                key:'cancel_msg',
                width:200,
                render:(a)=>{
                    return <div><span className="msg_reason" title={a}>{a}</span></div>
                }
            },{
                title:'备注',
                dataIndex:'feedback_problem',
                key:'feedback_problem',
                width:200,
                render:(a)=>{
                    return <div><span className="msg_reason" title={a}>{a}</span></div>
                }
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 300,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [
                        <Button size="small" onClick={()=>{this.openGetUserAccountModal(b)}}>商品资金信息</Button>,
                        <Button size="small" onClick={()=>{this.handlerAlipay(b)}}>{b['open_alipay']==='0'?'开启支付宝充值':'关闭支付宝充值'}</Button>,
                        <Button size="small" onClick={()=>{this.openCancelSubReasonModal(b)}}>取消订阅</Button>,
                        <Button size="small" onClick={()=>{this.openSetRmkModal(b)}}>设置备注</Button>,
                        <Button size="small" onClick={()=>{this.openSendApproveCountModal(b)}}>赠送可同意订单数</Button>,
                        <Button size="small" onClick={()=>{this.openSendProductCountModal(b)}}>赠送可推广商品数</Button>,
                        <Button size="small" onClick={()=>{this.openBlockModal(b['id'],b['credit'])}}>{b['credit']==='0'?'拉黑':'取消拉黑'}</Button>,
                    ]
                    if(this.props.getJurisdiction()==='1'){
                        btns = btns.concat([
                            <Button size="small" onClick={()=>{this.openWithdrawl(b)}}>开启提现</Button>,
                            <Button size="small" onClick={()=>{this.refundBail(b)}}>保证金退回</Button>,
                            <Button size="small" onClick={()=>{this.openChangeInfoModal(b)}}>修改卖家信息</Button>,
                            <Button size="small" onClick={()=>{this.openTransferAccountsModal(b)}}>转账</Button>,
                            // b['sub_status']!=='2'?<Button size="small" onClick={()=>{this.cancelSub(b['id'])}}>取消订阅</Button>:'',
                            <Button size="small" onClick={()=>{this.doSub(b)}}>余额订阅</Button>,
                            <Button size="small" onClick={()=>{this.handleOpenOrCloseSelfRefund(b)}}>
                                {b['is_refund']==='0'?'开启':'关闭'}PayPal返现
                            </Button>,
                            <Button size="small" onClick={()=>{this.handlerRefundDeposit(b)}}>
                                退回质保金
                            </Button>,
                            <Button size="small" onClick={()=>{this.addWhiteModal(b['id'],b['is_distribute'])}}>{b['is_distribute']==='0'?'给中介分单':'不给中介分单'}</Button>,
                        ])
                    }
                    return btns;
                },
            }],

        })

        this.doQuery();

    }

    listenerProps = () => {
        // const { isLogin } = store.getState();
        // this.setState({
        //     isLogin: isLogin
        // })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    // 设置备注
    openSetRmkModal=(item)=>{
        this.setState({
            optionItem:item,
            setRmkStatus:true
        })
    }
    closeSetRmkModal=()=>{
        this.setState({
            optionItem:null,
            setRmkStatus:false
        })
    }
    // 赠送测评订单数
    openSendApproveCountModal=(item)=>{
        this.setState({
            optionItem:item,
            sendApproveCountStatus:true
        })
    }

    closeSendApproveCountModal=()=>{
        this.setState({
            optionItem:null,
            sendApproveCountStatus:false
        })
    }

    // 赠送可推广商品数
    openSendProductCountModal=(item)=>{
        this.setState({
            optionItem:item,
            sendProductCountStatus:true
        })
    }

    closeSendProductCountModal=()=>{
        this.setState({
            optionItem:null,
            sendProductCountStatus:false
        })
    }


    openChangeInfoModal=(item)=>{
        this.setState({
            optionItem:item,
            changeInfoStatus:true
        })
    }

    closeChangeInfoModal=()=>{
        this.setState({
            optionItem:null,
            changeInfoStatus:false
        })
    }
    // 开启或关闭返现
    handleOpenOrCloseSelfRefund=(item)=>{
        this.props.$request('/seller/set-refund',{id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.doQuery();
            }
        })
    }

    // 查看账号
    openGetUserAccountModal=(item)=>{
        console.log(item)
        this.setState({
            optionItem:item,
            getUserAccountStatus:true
        })
    }

    closeGetUserAccountModal=()=>{
        this.setState({
            optionItem:null,
            getUserAccountStatus:false
        })
    }

    // 取消订阅
    openCancelSubReasonModal=(item)=>{
        console.log(item)
        this.setState({
            optionItem:item,
            cancelSubReasonStatus:true
        })
    }

    closeCancelSubReasonModal=()=>{
        this.setState({
            optionItem:null,
            cancelSubReasonStatus:false
        })
    }


    // 取消订阅
    cancelSub=(id)=>{
        this.props.$request('/seller/cancel-sub',{id:id},(res)=>{
            if(res['code']===200){
                message.success('操作成功')
            }
        })
    }


    handleIdChange = (e) => {
        this.setParam({ 'id': e.target.value })
    }

    handleEmailChange = (e) => {
        this.setParam({ 'email': e.target.value })
    }

    handleRegTimeChange = (e, str) => {
        this.setParam({ 'create_begin': str[0], 'create_end': str[1] })
    }

    handleCancelTimeChange = (e, str) => {
        this.setParam({ 'cancel_begin': str[0], 'cancel_end': str[1] })
    }

    handleEndTimeChange = (e, str) => {
        this.setParam({ 'sb_begin': str[0], 'sb_end': str[1] })
    }

    handleRegStatusChange = (e) => {
        this.setParam({ 'reg_status': e })
    }
    handleSubStatusChange = (e) => {
        this.setParam({ 'sub_status': e })
    }
    handlePayTypeChange = (e) => {
        this.setParam({ 'pay_type': e })
    }

    handleSourceChange = (e)=>{
        this.setParam({ 'source': e })
    }

    handlePlanTypeChange = (e)=>{
        this.setParam({ 'plan_type': e })
    }


    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { tableInfo,param, config ,columns ,transferAccountsModuls ,optionItem ,changeInfoStatus,getUserAccountStatus,cancelSubReasonStatus,setRmkStatus,sendApproveCountStatus,sendProductCountStatus} = this.state;
        config['columns'] =columns;
        return (
            <div className="content_tabs_box">
                <div>
                    {/* <Button>刷新</Button> */}
                    <div className="tool_bar">
                        <span className="label">卖家Id</span>
                        <Input className="inline_el" value={param['id']} onChange={this.handleIdChange} />

                        <span className="label">卖家账号</span>
                        <Input className="inline_el" value={param['email']} onChange={this.handleEmailChange} />

                        <span className="label">激活状态</span>
                        <Select value={param['reg_status']} onChange={this.handleRegStatusChange} className="inline_el">
                            <Option value=''>全部</Option>
                            <Option value={0}>未激活</Option>
                            <Option value={1}>已激活</Option>
                        </Select>

                        <span className="label">订阅状态</span>
                        <Select value={param['sub_status']} onChange={this.handleSubStatusChange} className="inline_el">
                            <Option value={0}>试用期</Option>
                            <Option value={1}>正式用户</Option>
                            <Option value={2}>取消用户</Option>
                        </Select>

                        <span className="label">支付方式</span>
                        <Select value={param['pay_type']} onChange={this.handlePayTypeChange} className="inline_el" style={{ width: 128 }}>
                            <Option value=''>全部</Option>
                            <Option value={2}>余额</Option>
                            <Option value={3}>信用卡</Option>
                        </Select>

                        <span className="label">注册来源</span>
                        <Select value={param['source']} onChange={this.handleSourceChange} className="inline_el" style={{ width: 128 }}>
                            <Option value=''>全部</Option>
                            <Option value={0}>pingamz.com</Option>
                            <Option value={1}>worldsolds</Option>
                        </Select>

                    </div>
                    <div className="tool_bar">

                        <span className="label">注册时间</span>
                        <RangePicker className="add_margin" onChange={this.handleRegTimeChange} />

                        <span className="label">取消时间</span>
                        <RangePicker className="add_margin" onChange={this.handleCancelTimeChange} />

                        <span className="label">到期时间</span>
                        <RangePicker className="add_margin" onChange={this.handleEndTimeChange} />

                        <span className="label">套餐类型</span>
                        <Select value={param['plan_type']} onChange={this.handlePlanTypeChange} className="inline_el" style={{ width: 128 }}>
                            <Option value=''>全部</Option>
                            <Option value={1}>付费用户</Option>
                            <Option value={2}>按单计费</Option>
                            <Option value={3}>免费用户</Option>
                        </Select>

                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                        {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery}  autoLoad={false}/>
                
                {/* 操作转账 */}
                {transferAccountsModuls?<TransferAccounts item={optionItem} close={this.closeTransferAccountsModal} reload={this.doQuery}/>:''}
                
                {/* 修改信息 */}
                {changeInfoStatus?<ChangeInfo item={optionItem} close={this.closeChangeInfoModal} reload={this.doQuery}/>:''}

                {/* 查看资金信息 */}
                {getUserAccountStatus?<GetUserAccount item={optionItem} close={this.closeGetUserAccountModal} reload={this.doQuery}/>:''}
                
                {/* 设置取消订阅原因 */}
                {cancelSubReasonStatus?<CancelSubReason item={optionItem} close={this.closeCancelSubReasonModal} reload={this.doQuery}/>:'' }

                {/* 设置备注 */}
                {setRmkStatus?<SetRmk item={optionItem} close={this.closeSetRmkModal} reload={this.doQuery}/>:''}

                {/* 赠送可测评订单数 */}
                {sendApproveCountStatus?<SendApproveCount item={optionItem} close={this.closeSendApproveCountModal} reload={this.doQuery}/>:''}

                {/* 赠送可推广商品数 */}
                {sendProductCountStatus?<SendProductCount item={optionItem} close={this.closeSendProductCountModal} reload={this.doQuery}/>:''}

            </div>
        );
    }
}

export default BaseHoc(SellerList);