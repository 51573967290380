import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal, message, Row, Col, DatePicker, Select, Button,Input,Switch,InputNumber ,Radio} from 'antd';
const { Option } = Select;

class AddHideAsin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin_name: '',
            sku: '',
            country_code: '',
            adminAccountList:[],
        };
    }

    handlerCountryCodeChange = (e) => {
        this.setState({
            country_code: e
        })
    }

    handlerAdminNameChange = (e) => {
        this.setState({
            admin_id: e
        })
    }

    handlerASINSChange = (e) => {
        this.setState({
            sku: e.target.value
        })
    }



    loadAdminAccount = () => {
        this.props.$request('/medium/get-admin-account', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    adminAccountList: res['data']['data']
                })
            }
        })
    }

    componentDidMount(){
        this.loadAdminAccount();
    }

    doSubmit = () => {
        const { admin_id, country_code, sku } = this.state;
        const { close, reload } = this.props;
        this.props.$request('/medium/add-sku', {
            admin_id, country_code, sku
        }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功');
                reload();
                close();
            }
        })
    }

    render() {
        const { admin_id, sku, country_code,adminAccountList} = this.state;
        const style = { width: 300, marginBottom: 10 }
        const adminSelect = [];
        adminAccountList.map(i => {
            adminSelect.push(<Option value={i['id']} key={i['key']}>{i['name']}</Option>)
        })
        return (
            <Modal
                visible={true}
                title="隐藏商品ASIN"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>管理员账号:</Col>
                    <Col span={18} style={style}>
                        <Select value={admin_id} onChange={this.handlerAdminNameChange} style={style}>
                            {adminSelect}
                        </Select>
                    </Col>

                    <Col span={6}>Sku:</Col>
                    <Col span={18} style={style}>
                        <Input.TextArea value={sku} onChange={this.handlerASINSChange} style={style} placeholder='可输入多个Asin，以","分割。'/>
                    </Col>

                    <Col span={6}>站点:</Col>
                    <Col span={18} style={style}>
                        <Select value={country_code} onChange={this.handlerCountryCodeChange} style={style}>
                            <Option value="US">US</Option>
                            <Option value="DE">DE</Option>
                            <Option value="FR">FR</Option>
                            <Option value="UK">UK</Option>
                            <Option value="CA">CA</Option>
                            <Option value="JP">JP</Option>
                        </Select>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} disabled={!(!!admin_id&&!!sku&&!!country_code)}>提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(AddHideAsin);