import React, { Component } from 'react';
import { SET_LOGIN_STATUS } from '../../store/action';
import { Menu, Dropdown, Modal,Row,Col,Input,Button,message } from 'antd';
import store from '../../store/index';
import '../layout/layout.scss';
import BaseHoc from '../base/baseHoc';

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showChangePwdModalStatus: false,
        newPwd:'',
        confirmNewPwd:''
    };
  }

  menuClick =({key}) =>{
    if(key ==='header_menu_logout'){
      window.sessionStorage.removeItem('P_TOKEN');
      window.sessionStorage.removeItem('USERNAME');
      window.sessionStorage.removeItem('IS_WHOLE');
      store.dispatch({type:SET_LOGIN_STATUS,key:'isLogin',status:false});
      window.location.reload();
    }
    if(key ==='header_menu_change_pwd'){
      this.setState({
        showChangePwdModalStatus:true
      })
    }
  }

  hideChangePwdModal=()=>{
    this.setState({
      showChangePwdModalStatus:false,
      newPwd:'',
      confirmNewPwd:''
    })
  }

  onPwdChange=(e)=>{
    this.setState({
      newPwd: e.target.value
    })
  }
  onConfirmNewPwdChange=(e)=>{
    this.setState({
      confirmNewPwd: e.target.value
    })
  }

  doSubmit=()=>{
    const {newPwd,confirmNewPwd} = this.state;
    if(!!newPwd){
      if(newPwd===confirmNewPwd){
        this.props.$request('/user/edit-user-passwd',{password1:newPwd,password2:confirmNewPwd},(res)=>{
          if(res['code']===200){
            message.success('操作成功');
            this.hideChangePwdModal();
          }
        },()=>{},false);
      }else{
        message.error('两次输入密码不一致');
      }
    }else{
      message.error('请输入新密码');
    }
  }

  render() {
    let username = window.sessionStorage.getItem('USERNAME');
    const {newPwd,confirmNewPwd,showChangePwdModalStatus} = this.state;
    const menu = (
      <Menu onClick={this.menuClick}>
        <Menu.Item key="header_menu_change_pwd">
          修改密码
        </Menu.Item>
        <Menu.Item key="header_menu_logout">
          退出登录
        </Menu.Item>
      </Menu>
    )
    const style={'marginBottom':'20px'};
    return (
      <div className="header_box">
          <span style={{display:'inline-block',fontSize:40,color:'#fff'}}>Admin</span>
          <Dropdown overlay={menu} trigger={['click']} className="user_btn">
            <div className="ant-dropdown-link user_name_text">
              <img className="user_img" src="http://file.qqkj.cn/upload/avatar/201410/29/21/11/54432855c9c14351.jpg%21200x200.jpg" alt="" />{username}
            </div>
          </Dropdown>

          {showChangePwdModalStatus?<Modal 
                visible={showChangePwdModalStatus}
                title="修改密码"
                centered
                onCancel={this.hideChangePwdModal}
                footer={false}
            >
                <Row>
                    <Col span={6} style={style}>新密码:</Col>
                    <Col span={18} style={style}>
                      <Input.Password value={newPwd} onChange={this.onPwdChange}></Input.Password>
                    </Col>

                    <Col span={6} style={style}>确认新密码:</Col>
                    <Col span={18} style={style}>
                      <Input.Password value={confirmNewPwd} onChange={this.onConfirmNewPwdChange}></Input.Password>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} >提交</Button>
                    </Col>
                </Row>
            </Modal>:''}
      </div>
    )
  }
}

export default BaseHoc(HeaderContainer);