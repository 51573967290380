import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,Input} from 'antd';
const { Option } = Select;

class Assign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            medium_id:'',
            adminAccountList:[]
        };

    }
    componentDidMount(){
        this.loadAdminAccount();
    }

   
    handleMediumChange=(e)=>{
        this.setState({
            medium_id: e
        })
    }

    submit=()=>{
        const { medium_id } = this.state;
        const { item } = this.props;
        this.props.$request('/medium/dg-order-medium',{id:item['id'],medium_id },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }

    loadAdminAccount = () => {
        this.props.$request('/medium/get-admin-account', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    adminAccountList: res['data']['data']
                })
            }
        },()=>{},false)
    }
    
    render() {
        const { adminAccountList,medium_id} = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="分配订单"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}><div style={style}>选择中介:</div></Col>
                    <Col span={18}>
                        <Select value={medium_id} onChange={this.handleMediumChange} style={{width:'100%'}}>
                            {adminAccountList.map((item,index)=>
                                <Option value={item['id']} key={index}>{item['name']}</Option>
                            )}
                        </Select>
                    </Col>
                    <Col span={6}></Col>
                    <Col span={18}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!medium_id)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(Assign);