import React, { Component } from 'react';
import store from '../../store/index';
import { Tabs, message } from 'antd';

import { ACTIVE_MENU_KEY, OPEN_TAB } from '../../store/action';

import Main from '../content/home/index';
// 卖家
import SellerList from '../content/sellerManage/sellerList';
import SellerWithdrawalList from '../content/sellerManage/sellerWithdrawalList';
import SellerCapitalRecord from '../content/sellerManage/sellerCapitalRecord';
import SellerHelp from '../content/sellerManage/sellerHelp';
import SellerNotice from '../content/sellerManage/sellerNotice';
import SellerActiveModal from '../content/sellerManage/sellerActiveModal';
import SellerActivationUrl from '../content/sellerManage/sellerActivationUrl';
import SellerApplyRefundList from '../content/sellerManage/sellerApplyRefundList';
// 买家
import BuyerList from '../content/buyerManage/buyerList';
import BuyerPoints from '../content/buyerManage/buyerPoints';
import BuyerAddUser from '../content/buyerManage/buyerAddUser';
import BuyerNotice from '../content/buyerManage/buyerNotice';
import BuyerBannerList from '../content/buyerManage/buyerBannerList';
import BuyerCapitalRecord from '../content/buyerManage/buyerCapitalRecord';
import BuyerLuckyDrawList from '../content/buyerManage/buyerLuckyDrawList';
import BuyerRestrictReturnCash from '../content/buyerManage/buyerRestrictReturnCash';
import BuyerAddPoint from '../content/buyerManage/buyerList/bonusPoints';

// 订单
import OrderDetail from '../content/orderManage/orderDetail';
import ReviewBypass from '../content/orderManage/reviewBypass';
import ReviewErrorList from '../content/orderManage/reviewErrorList';
import ModifyRebatePrice from '../content/orderManage/modifyRebatePrice';
import ModifyAmzOrderId from '../content/orderManage/modifyAmzOrderId';
import ModifysAmzOrderId from '../content/orderManage/modifysAmzOrderId';
import CloseOrder from '../content/orderManage/closeOrder';
import AmzIdOrderList from '../content/orderManage/amzIdOrderList';
import AdvancedPurchasingList from '../content/orderManage/advancedPurchasingOrderlist';
// 返现
import RebateList from '../content/rebateManage/rebateList';
import BankList from '../content/rebateManage/bankList';
// 特殊订单
import SpecialOrderList from '../content/specialOrderManage/specialOrderList';
import MediumList from '../content/specialOrderManage/mediumList';
import HideProductsAsin from '../content/specialOrderManage/hideProductsAsin';
import MediumPurchasingList from '../content/specialOrderManage/mediumPurchasingOrderlist';
import MediumCapitalRecord from '../content/specialOrderManage/mediumCapitalRecord';
// 配置项
import AddMenu from '../content/configManage/addMenu';
import EventList from '../content/configManage/eventList';
// 系统
import AdminList from '../content/systemManage/adminList';
// 财务
import UserAccount from '../content/finance/userAccount';
import DomesticCollectionStatistics from '../content/finance/domesticCollectionStatistics';
import MediumBusinessStatistics from '../content/finance/mediumBusinessStatistics';

// 商品管理
import ProductsList from '../content/productsManage/productsList';
import SubmitAmzOrderId from '../content/productsManage/submitAmzOrderId';

//统计
import BuyerStatistics from '../content/statistics/buyerStatistics';
import SellerStatistics from '../content/statistics/sellerStatistics';
import OrderStatistics from '../content/statistics/orderStatistics';
import OperationStatistics from '../content/statistics/operationStatistics';
import AllPassDataStatistics from '../content/statistics/allPassDataStatistics';
import PurchasingUserActionStatistics from '../content/statistics/purchasingUserActionStatistics';

// 推广来源
import PromotionSourcesList from '../content/promotionSourcesManage/promotionSourcesList';

const TabPane = Tabs.TabPane;

//这里把所有tab页面导入并写配置项
const tabConfig = {
  main: Main,
  // main: BuyerBannerList,
  sellerList: SellerList,
  sellerWithdrawalList: SellerWithdrawalList,
  sellerCapitalRecord: SellerCapitalRecord,
  sellerHelp: SellerHelp,
  sellerNotice: SellerNotice,
  sellerActiveModal: SellerActiveModal,
  sellerActivationUrl: SellerActivationUrl,
  sellerApplyRefund: SellerApplyRefundList,

  buyerList: BuyerList,
  buyerPoints: BuyerPoints,
  buyerAddUser: BuyerAddUser,
  buyerNotice: BuyerNotice,
  buyerBannerList: BuyerBannerList,
  buyerCapitalRecord: BuyerCapitalRecord,
  buyerLuckyDrawList: BuyerLuckyDrawList,
  buyerRestrictReturnCash: BuyerRestrictReturnCash,
  buyerAddPoint: BuyerAddPoint,

  orderDetail: OrderDetail,
  reviewBypass: ReviewBypass,
  reviewErrorList: ReviewErrorList,
  modifyRebatePrice:ModifyRebatePrice,
  modifyAmzOrderId:ModifyAmzOrderId,
  modifysAmzOrderId:ModifysAmzOrderId,
  closeOrder:CloseOrder,
  amzIdOrderList:AmzIdOrderList,
  advancedPurchasingList:AdvancedPurchasingList,

  rebateList: RebateList,
  bankList: BankList,

  specialOrderList: SpecialOrderList,
  mediumList:MediumList,
  hideProductsAsin:HideProductsAsin,
  mediumPurchasingList:MediumPurchasingList,
  mediumCapitalRecord:MediumCapitalRecord,

  addMenu:AddMenu,
  eventConfig:EventList,

  adminList:AdminList,

  userAccount:UserAccount,
  domesticCollectionStatistics:DomesticCollectionStatistics,
  mediumBusinessStatistics:MediumBusinessStatistics,

  productsList:ProductsList,
  submitAmzOrderId:SubmitAmzOrderId,

  buyerStatistics:BuyerStatistics,
  sellerStatistics:SellerStatistics,
  orderStatistics:OrderStatistics,
  operationStatistics:OperationStatistics,
  allPassDataStatistics:AllPassDataStatistics,
  purchasingUserActionStatistics:PurchasingUserActionStatistics,

  promotionSourcesList:PromotionSourcesList
}

//利用高阶组件动态创建tab 并传值给组件
class TabContent extends Component {
  render() {
    //通过传入的name属性动态得到自己需要注入的组件，TabComponent首字母要大写
    const TabComponent = tabConfig[this.props['content']];
    return <TabComponent {...this.props} />
  }
}

class Center extends Component {
  constructor(props) {
    super(props);
    const panes = [];
    this.state = {
      panes
    };
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
    let dispatchActiveKey = activeKey.split('_')[0];
    if (dispatchActiveKey === 'orderDetail') {
      dispatchActiveKey = 'seeAd'
    }
    store.dispatch({ type: ACTIVE_MENU_KEY, key: 'activeMenuKey', status: { activeMenuKey: dispatchActiveKey } })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }

  // 新增tab
  add = (key, name, closable, params) => {
    const panes = this.state.panes;
    let add = true;
    const activeKey = key + '_' + name + (!!params['readOnly'] ? '_readOnly' : '');
    // 判断增加只读属性，增加不同的tab
    for (let i = 0; i < panes.length; i++) {
      if (panes[i]['key'] === activeKey) {
        if (params['readOnly'] === panes[i]['params']['readOnly']) {
          add = add & false;
        }
      }
    }
    // 根据窗口固定id 打开或者选中窗口
    if (add) {
      if (panes.length < 14) {
        params['activeKey'] = activeKey;
        panes.push({ title: name + (params['readOnly'] ? '(只读)' : ''), content: key, key: activeKey, closable: closable, params: params });
        this.setState({ panes, activeKey });
      } else {
        message.error('窗口打开已达上限，请关闭其他窗口。');
      }
    } else {
      this.setState({ activeKey });
    }
    store.dispatch({ type: OPEN_TAB, key: 'openTabConfig', status: { key: '0' } });
  }

  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter(pane => pane.key !== targetKey);
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key;
    }
    this.setState({ panes, activeKey });
  }

  componentDidMount() {
    this.listenerProps()
    store.subscribe(this.listenerProps);
  }

  listenerProps = () => {
    const { openTabConfig, closeTab } = store.getState();
    if (openTabConfig['key'] !== '0') {
      if (!openTabConfig['key'] || openTabConfig['key'] === 'main') {
        this.add('main', '首页', false, openTabConfig['params'] || {});
      } else {
        this.add(openTabConfig['key'], openTabConfig['menuName'], true, openTabConfig['params'] || {});
      }
    }
    if (!!closeTab) {
      this.remove(closeTab)
    }
  }

  render() {
    return (
      <Tabs
        hideAdd
        onChange={this.onChange}
        activeKey={this.state.activeKey}
        type="editable-card"
        onEdit={this.onEdit}
        className="tabs_content"
      >
        {this.state.panes.map(pane => <TabPane className="tab_content" tab={pane.title} key={pane.key} closable={pane.closable}>
          {!!tabConfig[pane['content']] ? (<TabContent content={pane['content']} params={pane['params']} />) : ('')}
        </TabPane>)}
      </Tabs>
    );
  }
}

export default Center;