import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,Input} from 'antd';
class SellerActivationUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
           email:'',
           data:''
        };

    }
    handleEmailChange =(e)=>{
        this.setState({
            email:e.target.value
        })
    }

    doQuery =()=>{
        this.props.$request('/seller/get-active-url',{email:this.state.email},(res)=>{
            console.log(res['data'])
            this.setState({
                data:res['data']['url']
            })
        })
    }

    render() {
        const {data ,email} = this.state;
        return (
            <div className="content_tabs_box">
                邮箱：
                <Input onChange={this.handleEmailChange} value={email} style={{width:200,marginRight:20}}></Input>
                <Button type="primary" onClick={this.doQuery} disabled={!!email?false:true}>查找</Button>

                <div style={{marginTop:20}}>
                    激活链接：
                    <div>{data}</div>
                </div>
            </div>
        );
    }
}

export default BaseHoc(SellerActivationUrl);