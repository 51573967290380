import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button} from 'antd';
class GetUserAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
           data:null
        };
    }
    componentDidMount(){
        const id = this.props.item['id'];
        this.props.$request('/seller/get-user-info',{id:id},(res)=>{
            console.log(res['data'])
            if(res['code']===200){
                this.setState({
                    data:res['data']
                })
            }
        })
    }
    render() {
        const { data } = this.state;
        const style1 = {fontSize:'18px',fontWeight:'bold'};
        const style = {marginTop:'20px',fontSize:'18px',fontWeight:'bold'};
        return (
            <Modal visible={true}
                title="商品资金信息"
                centered
                onCancel={this.props.close}
                footer={false}>
                    {!!data?<div><Row>
                        <Col span={24}>
                            <div style={style1}>总返现笔数：<span style={{fontWeight:'normal'}}>{data['order']['refund_count']}</span></div>
                        </Col>
                        <Col span={24}>
                            <div style={style}>余额</div>
                        </Col>
                        <Col span={4}>{data['account']['account']} USD</Col>
                        <Col span={4}>{data['account']['account_cad']} CAD</Col>
                        <Col span={4}>{data['account']['account_eur']} EUR</Col>
                        <Col span={4}>{data['account']['account_gbp']} GPB</Col>
                        <Col span={4}>{data['account']['account_jpy']} JPY</Col>

                        <Col span={24}>
                            <div style={style}>冻结资金</div>
                        </Col>
                        <Col span={4}>{data['account']['lock_account']} USD</Col>
                        <Col span={4}>{data['account']['lock_account_cad']} CAD</Col>
                        <Col span={4}>{data['account']['lock_account_eur']} EUR</Col>
                        <Col span={4}>{data['account']['lock_account_gbp']} GPB</Col>
                        <Col span={4}>{data['account']['lock_account_jpy']} JPY</Col>

                        <Col span={24}>
                            <div style={style}>总商品数</div>
                        </Col>
                        <Col span={4}>美国站：{data['product']['product_us_count']}</Col>
                        <Col span={4}>加拿大站：{data['product']['product_ca_count']}</Col>
                        <Col span={4}>英国站：{data['product']['product_uk_count']}</Col>
                        <Col span={4}>日本站：{data['product']['product_jp_count']}</Col>
                        <Col span={4}>德国站：{data['product']['product_de_count']}</Col>
                        <Col span={4}>法国站：{data['product']['product_fr_count']}</Col>

                        <Col span={24}>
                            <div style={style}>推广中商品数</div>
                        </Col>
                        <Col span={4}>美国站：{data['product']['product_ing_us_count']}</Col>
                        <Col span={4}>加拿大站：{data['product']['product_ing_ca_count']}</Col>
                        <Col span={4}>英国站：{data['product']['product_ing_uk_count']}</Col>
                        <Col span={4}>日本站：{data['product']['product_ing_jp_count']}</Col>
                        <Col span={4}>德国站：{data['product']['product_ing_de_count']}</Col>
                        <Col span={4}>法国站：{data['product']['product_ing_fr_count']}</Col>
                    </Row></div>:''}
            </Modal>
        );
    }
}

export default BaseHoc(GetUserAccount);