import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';

import moment from 'moment';

const dateFormat = "YYYY/MM/DD HH";

const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/stat/get-distribute-stat',
    param: {},
    callback: null,
    columns: []
}
class allPassDataStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                type:'',
                begin:'',
                end:''
            },
            keyValue:'',
            columns: []
        };

    }

    // componentWillMount() {
    //     console.log('self will mount')
    // }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '搜索时间类型',
                dataIndex: 'stat_type_str',
                key: 'stat_type_str',
                width: 200
            }, {
                title: '开始时间',
                dataIndex: 'stat_date',
                key: 'stat_date',
                width: 200
            }, {
                title: '结束时间',
                dataIndex: 'stat_date_end',
                width: 200,
                key: 'stat_date_end'
            }, {
                title: '操作',
                dataIndex: 'url',
                key: 'url',
                width: 150,
                fixed: 'right',
                render: (a,b) => {
                    return !!a?<a href={a} target="_blank">下载</a>:'等待统计'
                },
            }],

        })
        this.doQuery();
    }

    listenerProps = () => {
        // const { isLogin } = store.getState();
        // this.setState({
        //     isLogin: isLogin
        // })
    }
    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleTypeChange = e=>{
        this.setParam({ 'type': e })
    }

    
    handleTimeChange = (e, str) => {
        console.log(e,str)
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    doQuery = () => {
        const { config, param } = this.state;
        // config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo: data
                })
            });
        })
    }

    // 设置查询数据
    doSetData=()=>{
        const { param } = this.state;
        this.props.$request('/stat/set-distribute-stat',param,res=>{
            if(res['code']===200){
                this.setState({
                    param: {
                        type:'',
                        begin:'',
                        end:''
                    },
                    keyValue: new Date()
                })
                this.doQuery();
            }
        })
    }

    render() {
        const { param, config, columns, keyValue } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div>
                    <div className="tool_bar">
                        <Button style={{marginRight:40}}  onClick={this.doQuery}>刷新</Button>
                        <span className="label">筛查类型</span>
                        <Select value={param['type']} onChange={this.handleTypeChange} className="inline_el">
                            <Option value={'1'}>领取时间</Option>
                            <Option value={'2'}>等待分配时间</Option>
                            <Option value={'3'}>提交订单号时间</Option>
                        </Select>

                        <span className="label">筛查时间</span>
                        <RangePicker key={keyValue} className="add_margin" showTime onChange={this.handleTimeChange}   format={dateFormat} />

                        <Button type="primary" style={{ marginLeft: 20 }} disabled={!(!!param['type']&&!!param['begin']&&!!param['end'])} onClick={this.doSetData}>设置</Button>
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(allPassDataStatistics);