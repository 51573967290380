import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Tabs } from 'antd';
import PlatformOperation from './platformOperation';
import RealBuyerQuota from './realBuyerQuota';
import NewPromotionBuyerQuota from './newPromotionBuyerQuota';
import MonthNewSellerQuota from './monthNewSellerQuota';
import BaiduPromotion from './baiduPromotion';
import ExternalCooperationPromotion from './externalCooperationPromotion';
import MediumData from './mediumData';

const { TabPane } = Tabs;

const tabConfigs = [{
    title: '平台运营数据（整站）',
    component: PlatformOperation
},{
    title: '真实买家指标（不含中介）',
    component: RealBuyerQuota
},{
    title: '新增推广买家指标（不含中介）',
    component: NewPromotionBuyerQuota
},{
    title: '当月新增卖家指标',
    component: MonthNewSellerQuota
},{
    title: '百度推广',
    component: BaiduPromotion
},{
    title: '外部合作推广',
    component: ExternalCooperationPromotion
},{
    title: '中介数据',
    component: MediumData
}]


class TabContent extends Component {
    render() {
      //通过传入的name属性动态得到自己需要注入的组件，TabComponent首字母要大写
      const TabComponent = this.props['com'];
      return <TabComponent {...this.props} />
    }
}

class OperationStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }
    render() {
        const { } = this.state;
        return (
            <div className="content_tabs_box">
                <Tabs defaultActiveKey="0" tabPosition="left" style={{ height: '100%'}}>
                    {tabConfigs.map((item,index)=><TabPane tab={item['title']} key={index}>
                        <TabContent com={item['component']}/>
                    </TabPane>)}
                </Tabs>
            </div>
        );
    }
}

export default BaseHoc(OperationStatistics);