import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Button,Input} from 'antd';

class Close extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remark:''
        };

    }
    componentDidMount(){
        
    }

   
    handleRemarkChange=(e)=>{
        this.setState({
            remark: e.target.value
        })
    }

    submit=()=>{
        const { remark } = this.state;
        const { item } = this.props;
        this.props.$request('/medium/close-dg-order',{id:item['id'],remark },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }
    
    render() {
        const { remark } = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="关闭订单"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}><div style={style}>关闭原因:</div></Col>
                    <Col span={18}>
                        <Input.TextArea onChange={this.handleRemarkChange} value={remark}/>
                    </Col>
                    <Col span={6}></Col>
                    <Col span={18}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!remark)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(Close);