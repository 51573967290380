import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Form, Input, Button,message,Modal} from 'antd';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


class SellerActiveAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {

    }
    onFinish = values => {
        console.log(values)
        this.props.$request('/order/submit-review',values,(res)=>{
            if(res['code']===200){
                message.success('提交成功');
                let form = this.productInfo.current;
                form.resetFields();
            }
        })
    }
    onFinishFailed = () => {

    }
    productInfo = React.createRef();
    render() {
        return (
            <div style={{paddingTop:40}}>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    className="form_content"
                >


                    <Form.Item
                        label="内部订单号"
                        name="request_id"
                        rules={[{ required: true, message: '请输入内部订单号' }]}
                    >
                        <Input style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        label="评论链接"
                        name="url"
                        rules={[{ required: true }]}
                    >
                        <Input style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        label="Profile"
                        name="profile"
                        rules={[{ required: false }]}
                    >
                        <TextArea
                            placeholder="请输入Profile"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            style={{ width: 300 }} 
                        />
                    </Form.Item>
                    
                    <Form.Item
                        label="备注"
                        name="remark"
                        rules={[{ required: true }]}
                    >
                       <TextArea
                        placeholder="请输入备注"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        style={{ width: 300 }} 
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default BaseHoc(SellerActiveAdd);