import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const tconfig = {
    url: '/order/get-order-list-by-amz-id',
    param: {},
    callback: null,
    columns: []
}
class AmzIdOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                amz_order_id: ''
            },
            columns: [],
            byPassStatus: false,
            optionItem: null
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: '内部订单号',
                dataIndex: 'id',
                key: 'id',
                width: 130,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '商品图片',
                dataIndex: 'image',
                key: 'image',
                width: 100,
                render: (a, b) => {
                    return <img src={a} alt="" style={{ maxWidth: 100, maxHeight: 100 }} />
                }
            }, {
                title: '商品名称',
                dataIndex: 'product_title',
                key: 'product_title',
                width: 200,
            }, {
                title: '商品简介',
                dataIndex: 'product_id',
                key: 'product_id',
                width: 200,
                render: (a, b) => {
                    return <div>
                        <div>商品ID:{b['product_id']}</div>
                        <div>ASIN:{b['product_asin']}</div>
                        <div>站点:{b['countryCode']}</div>
                        <div>商品金额:{parseFloat(b['price']) / 100}{b['currency']}</div>
                    </div>
                }
            }, {
                title: '卖家信息',
                dataIndex: 'seller_uid',
                key: 'seller_uid',
                width: 200,
                render: (a, b) => {
                    return <div>
                        <div>卖家ID:{b['seller_uid']}</div>
                        <div>{b['seller_email']}</div>
                    </div>
                }
            }, {
                title: '买家信息',
                dataIndex: 'buyer_uid',
                key: 'buyer_uid',
                width: 200,
                render: (a, b) => {
                    return <div>
                        <div>买家ID:{b['buyer_uid']}</div>
                        <div>等级:{b['buyer_level']}</div>
                        <div>{b['buyer_email']}</div>
                    </div>
                }
            }, {
                title: '订单金额',
                dataIndex: 'price_rebate',
                key: 'price_rebate',
                width: 150,
                render: (a, b) => {
                    return <div>{parseFloat(b['price_rebate']) / 100} {b['currency']}</div>
                }
            }, {
                title: '订单类型',
                dataIndex: 'discountType',
                key: 'discountType',
                width: 150,
                render: (a, b) => {
                    return <div>{b['discountType'] === '1' ? '返现' : '优惠券'}</div>
                }
            }, {
                title: '留评类型',
                dataIndex: 'reviewType',
                key: 'reviewType',
                width: 130,
                render: (a, b) => {
                    let reviewType = ''
                    if (b['unnecessaryReview'] === '1') {
                        if (b['image_extra_return'] === '1') {
                            return reviewType = '图片留评'
                        } else if (b['video_extra_return'] === '1') {
                            return reviewType = '视频留评'
                        } else if (b['encourageReview'] === '1') {
                            return reviewType = '文字留评'
                        } else {
                            return reviewType = '需要留评'
                        }
                    } else if (b['encourageReviewFree'] === '1') {
                        return reviewType = '按单计费'
                    } else {
                        return reviewType = '不需要留评'
                    }
                }
            }, {
                title: '订单状态',
                dataIndex: 'request_status',
                key: 'request_status',
                width: 150,
                render:(a,b)=>{
                   return this.props.getStatus(b['request_status'])
                }
            },{
                title:'Review链接',
                dataIndex:'review_url',
                key:'review_url',
                width:300
            },{
                title:'创建时间',
                dataIndex:'create_request_datetime',
                key:'create_request_datetime',
                width:200,
                sorter: (a, b) => new Date(a.create_request_datetime).getTime() - new Date(b.create_request_datetime).getTime(),
            },{
                title:'亚马逊订单号提交时间',
                dataIndex:'submit_orderid_datetime',
                key:'submit_orderid_datetime',
                width:200,
                sorter: (a, b) => new Date(a.submit_orderid_datetime).getTime() - new Date(b.submit_orderid_datetime).getTime(),
            },{
                title:'提交reivew 时间',
                dataIndex:'submit_review_datetime',
                key:'submit_review_datetime',
                width:200,
                sorter: (a, b) => new Date(a.submit_review_datetime).getTime() - new Date(b.submit_review_datetime).getTime(),
            },{
                title:'返现时间',
                dataIndex:'cash_back_success_datetime',
                key:'cash_back_success_datetime',
                width:200,
                sorter: (a, b) => new Date(a.cash_back_success_datetime).getTime() - new Date(b.cash_back_success_datetime).getTime(),
            },{
                title:'完成检测评价时间',
                dataIndex:'done_review_datetime',
                key:'done_review_datetime',
                width:200,
                sorter: (a, b) => new Date(a.done_review_datetime).getTime() - new Date(b.done_review_datetime).getTime(),
            }]
        })

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleAmzIdChange = (e) => {
        this.setParam({ 'amz_order_id': e.target.value })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent();
        })
    }

    render() {
        const { param, config, columns, byPassStatus, optionItem } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label" style={{ marginRight: 20 }}>亚马逊订单号</span>
                    <Input className="inline_el" value={param['id']} onChange={this.handleAmzIdChange} style={{ width: 200 }} />
                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} />
            </div>
        );
    }
}

export default BaseHoc(AmzIdOrderList);