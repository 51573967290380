import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
const tconfig = {
    url: '/buyer/capital-list',
    param: {},
    callback: null,
    columns: []
}
class SellerCapitalRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                buyer_id: '',
                // type: '',
                // begin: '',
                // end: '',
                // currency: '',
                // orderid: '',
                // tid: '',
                // transactionType:'',
                // pay_type:'',
                // source:''
            },
            columns: [],
            tableInfo:null
        };

    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '买家 Id',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_id) - parseFloat(b.buyer_id),
            }, {
                title: '买家邮箱',
                dataIndex: 'email',
                key: 'email',
                width: 230, 
            },{
                title: '创建时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 200,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '金额',
                dataIndex: 'account',
                key: 'account',
                width: 150, sorter: (a, b) => parseFloat(a.account) - parseFloat(b.account),
                render: (a, b) => {
                    return a + b['currency']
                }
            }, {
                title: '类型',
                dataIndex: 'record_source',
                key: 'record_source',
                width: 250,
            }, {
                title: '状态',
                dataIndex: 'is_settle',
                key: 'is_settle',
                width: 100,
                render: (a, b) => {
                    if(a==='1'){
                        return '已结算'
                    } else{
                        return '未结算'
                    }
                }
            }, {
                title: '备注',
                dataIndex: 'msg',
                key: 'msg',
                width: 150
            }]
        })
        this.doQuery();
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleIdChange = (e) => {
        this.setParam({ 'buyer_id': e.target.value })
    }

    handleOrderIdChange = (e) => {
        this.setParam({ 'orderid': e.target.value })
    }

    handleTIdChange = (e) => {
        this.setParam({ 'tid': e.target.value })
    }

    handleTypeChange = (e) => {
        this.setParam({ 'type': e })
    }

    handleSourceChange=(e)=>{
        this.setParam({ 'source': e })
    }

    handleCurrencyChange = (e) => {
        this.setParam({ 'currency': e })
    }

    handleTrTypeChange = (e) => {
        this.setParam({ 'transactionType': e })
    }
    
    handleOptionTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handlePayTypeChange=(e)=>{
        this.setParam({ 'pay_type': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                console.log(data)
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,tableInfo } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">买家Id</span>
                    <Input className="inline_el_min" value={param['buyer_id']} onChange={this.handleIdChange} />

                    {/* <span className="label">流水类型</span>
                    <Select value={param['type']} onChange={this.handleTypeChange} className="inline_el">
                        <Option value="">全部</Option>
                        <Option value={1}>余额冻结支出</Option>
                        <Option value={2}>余额解冻收入</Option>
                        <Option value={3}>冻结转账支出</Option>
                        <Option value={4}>余额手动充值收入</Option>
                        <Option value={5}>订阅 </Option>
                        <Option value={6}>余额赠送(注册推荐用)</Option>
                        <Option value={7}>后台增加</Option>
                        <Option value={8}>后台扣除</Option>
                        <Option value={9}>后台清零</Option>
                        <Option value={10}>转账退回</Option>
                        <Option value={11}>转账扣款</Option>
                        <Option value={12}>更改订阅后返回差额</Option>
                        <Option value={13}>争议清零</Option>
                        <Option value={14}>争议恢复</Option>
                        <Option value={17}>卖家保证金</Option>
                    </Select>

                    <span className="label">流水来源</span>
                    <Select value={param['source']} onChange={this.handleSourceChange} className="inline_el">
                        <Option value="">全部</Option>
                        <Option value={1}>普通订单</Option>
                        <Option value={2}>额外返现订单</Option>
                        <Option value={3}>退款保障订单</Option>
                    </Select>

                    <span className="label">操作时间</span>
                    <RangePicker className="add_margin" onChange={this.handleOptionTimeChange} />

                    <span className="label">币种</span>
                    <Select value={param['currency']} onChange={this.handleCurrencyChange} className="inline_el_min">
                        <Option value="">全部</Option>
                        <Option value="USD">USD</Option>
                        <Option value="EUR">EUR</Option>
                        <Option value="JPY">JPY</Option>
                        <Option value="GBP">GBP</Option>
                        <Option value="CAD">CAD</Option>
                    </Select>

                    <span className="label">订单号</span>
                    <Input className="inline_el" value={param['orderid']} onChange={this.handleOrderIdChange} />

                    <span className="label">交易号</span>
                    <Input className="inline_el" value={param['tid']} onChange={this.handleTIdChange} />
                    */}

                    <Button type="primary" onClick={this.doQuery}>查询</Button>
                </div>
                {/* <div className="tool_bar">

                    <span className="label">交易状态</span>
                    <Select value={param['transactionType']} onChange={this.handleTrTypeChange} className="inline_el_min">
                        <Option value="">全部</Option>
                        <Option value="0">失败</Option>
                        <Option value="1">成功</Option>
                        <Option value="2">进行中</Option>
                        <Option value="3">未付款</Option>
                    </Select>

                    <span className="label">充值方式</span>
                    <Select value={param['pay_type']} onChange={this.handlePayTypeChange} className="inline_el">
                        <Option value="">全部</Option>
                        <Option value="PPL">PayPal</Option>
                        <Option value="CCG">G2S</Option>
                        <Option value="ALIPAY">支付宝</Option>
                        <Option value="WX">微信</Option>
                        <Option value="pi">Stripe</Option>
                    </Select>

                    <Button type="primary" onClick={this.doQuery}>查询</Button>
                    {!!tableInfo?<div style={{display:'inline-block',marginLeft:20}}> 笔数:{tableInfo['total']} 、总金额: {tableInfo['sum']} <a href={tableInfo['url']} style={{marginLeft:20}}>导出</a></div>:''}
                </div> */}

                
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(SellerCapitalRecord);