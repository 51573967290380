import {createStore} from 'redux'
import reducer from './reducers' 

const initValue={
    isLogin:false,
    menuShow:true,
    openTabConfig:{},
    activeMenuKey:'',
    loadingStatus:false,
}
const store=createStore(reducer,initValue)

export default store