import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, message } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import '../../../base/index.scss';

class SellerHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: BraftEditor.createEditorState('<p>初始值</p>'), // 设置编辑器初始内容
            outputHTML: '<p></p>' // 编辑器输出内容
        };


    }

    componentDidMount() {
        this.loadTxt();
    }

    loadTxt=()=>{
        this.props.$request('/config/get-help-doc',{},(res)=>{
            if(res['code']===200){
                let html = BraftEditor.createEditorState(res['data']['txt']);
                this.setState({
                    editorState: html,
                    outputHTML: html.toHTML()
                })
            }else{
                let html = BraftEditor.createEditorState(' ');
                this.setState({
                    editorState: html,
                    outputHTML: html.toHTML()
                })
            }
        })
    }
    handleChange = (editorState) => {
        this.setState({
            editorState: editorState,
            outputHTML: editorState.toHTML()
        })
    }

    submit = ()=>{
        console.log(this.state.outputHTML)
        this.props.$request('/config/add-help-doc',{txt:this.state.outputHTML,id:1},(res)=>{
            if(res['code']===200){
                message.success('操作成功')
            }
        })
    }
    render() {
        const { editorState, outputHTML } = this.state
        return (
            <div className="content_tabs_box">
                <div className="editor-wrapper">
                    <BraftEditor
                        value={editorState}
                        onChange={this.handleChange}
                    />
                </div>
                <Button type="primary" onClick={this.submit}>提交</Button>
            </div>
        );
    }
}

export default BaseHoc(SellerHelp);