import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, Upload, Modal, message, Statistic, Row, Col } from 'antd';
import ProTable from '../../../widget/proTable';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import '../../../base/index.scss';

const { Option } = Select;
const tconfig = {
    url: '/medium/list',
    param: {},
    callback: null,
    columns: []
}
let uploadStatus = 1
class SpecialOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                // buyer_id: '',
                admin_id: '',
                ids: '',
                type: 2
            },
            columns: [],
            adminAccountList: [],
            // buyerAccountlist: [],

            fileList: [],

            submitOrderStatus: false,
            optionId: '',
            submitOrderId: '',
            buyerAddress: '',

            uploadReviewUrlStatus: false,
            uploadReviewImgStatus: false,
            uploadRebatedImgStatus: false,
            tableInfo: null,
            showEndBtnStatus: false,
            orderReviewUrl: '',
            optionIds: '',
            rewSelectStatus: false
        };

    }
    componentDidMount() {
        this.setColumns();
        // this.loadBuyerAccount();
        this.loadAdminAccount();
        this.loadCanEndBtn();
    }

    loadAdminAccount = () => {
        this.props.$request('/medium/get-admin-account', {}, (res) => {
            if (res['code'] === 200) {
                if (res['data']['data'].length === 1) {
                    const { param } = this.state;
                    this.setState({
                        adminAccountList: res['data']['data'],
                        param: {
                            admin_id: res['data']['data'][0]['id'],
                            ids: param['ids'],
                            type: param['type'],
                        }
                    }, () => {
                        this.doQuery();
                    })
                } else {
                    this.setState({
                        adminAccountList: res['data']['data']
                    })
                }


            }
        }, () => { }, false)
    }

    // loadBuyerAccount = () => {
    //     this.props.$request('/medium/get-buyer-account', {}, (res) => {
    //         if (res['code'] === 200) {
    //             this.setState({
    //                 buyerAccountlist: res['data']['data']
    //             })
    //         }
    //     })
    // }

    //提交订单号开始
    openSubmitOrderId = (id) => {
        this.setState({
            optionId: id,
            submitOrderStatus: true
        })
    }

    closeSubmitOrderId = () => {
        this.setState({
            optionId: '',
            submitOrderStatus: false,
            submitOrderId: '',
            buyerAddress: ''
        })
    }

    handerSubmitOrder = () => {
        const { optionId, submitOrderId, buyerAddress } = this.state;
        this.props.$request('/medium/submit-order-id', { request_id: optionId, order_id: submitOrderId }, (res) => {
            if (res['code'] === 200) {
                this.doQuery();
            }
        })
    }
    // 提交订单号结束

    // 上传图片开始
    handleChange = ({ fileList, event }) => {
        this.setState({ fileList })
        if (!!!event) {
            if (uploadStatus === 2) {
                uploadStatus = 1
                this.doQuery();
                this.closeUploadRebatedImg();
                this.closeUploadReviewImg();
            } else {
                uploadStatus++
            }
        }
    }
    // 上传返现图片
    openUploadRebatedImg = (id) => {
        this.setState({
            optionId: id,
            uploadRebatedImgStatus: true,
            fileList: [],
        })
    }
    closeUploadRebatedImg = () => {
        this.setState({
            optionId: '',
            fileList: [],
            uploadRebatedImgStatus: false
        })
    }

    openUploadReviewUrl = (id) => {
        this.setState({
            optionId: id,
            fileList: [],
            uploadReviewUrlStatus: true
        })
    }
    closeUploadReviewUrl = () => {
        this.setState({
            optionId: '',
            fileList: [],
            uploadReviewUrlStatus: false
        })
    }

    openUploadReviewImg = (id) => {
        this.setState({
            optionId: id,
            fileList: [],
            uploadReviewImgStatus: true
        })
    }

    closeUploadReviewImg = () => {
        this.setState({
            optionId: '',
            fileList: [],
            uploadReviewImgStatus: false
        })
    }

    endRebate = (id) => {
        this.props.$request('/medium/set-rebate-settled', { request_id: id }, (res) => {
            if (res['code'] === 200) {
                this.doQuery();
            }
        })
    }

    // 已退款
    isRefund = (id) => {
        this.props.$request('/medium/set-refund', { id: id }, res => {
            if (res['code'] === 200) {
                this.doQuery();
            }
        })
    }

    setColumns = () => {
        const { showEndBtnStatus } = this.state;
        console.log(showEndBtnStatus);
        this.setState({
            columns: [{
                title: '订单类型',
                dataIndex: 'isReview',
                key: 'isReview',
                width: 150
            }, {
                title: '订单-内部订单号-明文',
                dataIndex: 'id',
                key: 'id',
                width: 200
            }, {
                title: '订单-内部订单号',
                dataIndex: 'request_id',
                key: 'request_id',
                width: 150
            }, {
                title: '买家账号',
                dataIndex: 'buyer_email',
                key: 'buyer_email',
                width: 150
            }, {
                title: '商品链接',
                dataIndex: 'rawUrl',
                key: 'rawUrl',
                width: 150,
                render: (a, b) => {
                    return <a href={a} target="_blank">{a}</a>
                }
            }, {
                title: '亚马逊订单号',
                dataIndex: 'amz_order_id',
                key: 'amz_order_id',
                width: 150,
            }, {
                title: '返现金额',
                dataIndex: 'price_rebate',
                key: 'price_rebate',
                width: 150,
                render: (a, b) => {
                    return parseFloat(a) / 100 + ' ' + b['currency']
                }
            }, {
                title: '返现金额CNY',
                dataIndex: 'price_rebate_cny',
                key: 'price_rebate_cny',
                width: 150
            }, {
                title: '结算金额',
                dataIndex: 'settle_accounts',
                key: 'settle_accounts',
                width: 150
            }, {
                title: '结算金额CNY',
                dataIndex: 'settle_accounts_cny',
                key: 'settle_accounts_cny',
                width: 150
            }, {
                title: '订单利润',
                dataIndex: 'profit',
                key: 'profit',
                width: 150
            }, {
                title: '订单利润CNY',
                dataIndex: 'profit_cny',
                key: 'profit_cny',
                width: 150
            }, {
                title: '卖家 id',
                dataIndex: 'seller_uid',
                key: 'seller_uid',
                width: 100,
            }, {
                title: '订单状态',
                dataIndex: 'request_status',
                key: 'request_status',
                width: 200,
                render: (a) => {
                    return this.props.getStatus(a)
                }
            },
            {
                title: '过期时间',
                dataIndex: 'status_expire',
                key: 'status_expire',
                width: 200,
                // <div><Countdown className="tas_countdown" valueStyle={{ color: 'red', fontSize: '14px' }} value={parseFloat(record['expire']) * 1000} format="D 天 H 时 m 分 s 秒" />后超时。</div>

            }, {
                title: '剩余时间',
                dataIndex: 'status_expire_hour',
                key: 'status_expire_hour',
                width: 250
            }, {
                title: '返现状态',
                dataIndex: 'cash_type',
                key: 'cash_type',
                width: 200,
                render: (a) => {
                    if (a === '1') {
                        return '未返现'
                    } else if (a === '2') {
                        return '返现失败'
                    } else if (a === '3') {
                        return '返现成功'
                    } else if (a === '4') {
                        return '返现审核中'
                    }
                }
            }, {
                title: '结算状态',
                dataIndex: 'is_rebate_settled',
                key: 'is_rebate_settled',
                width: 200,
                render: (a) => {
                    return a === '1' ? '已结算' : '未结算'
                }
            }, {
                title: '提交订单号时间',
                dataIndex: 'submit_orderid_datetime',
                key: 'submit_orderid_datetime',
                width: 150
            }, {
                title: '确认订单号时间',
                dataIndex: 'confirm_orderid_datetime',
                key: 'confirm_orderid_datetime',
                width: 150,
            }, {
                title: '确认返现时间',
                dataIndex: 'cash_back_success_datetime',
                key: 'cash_back_success_datetime',
                width: 150,
            }, {
                title: '付款成功图片',
                dataIndex: 'pay_success_img_url',
                key: 'pay_success_img_url',
                width: 150,
                render: (a, b) => {
                    return a ? <a href={a} target="_blank">查看</a> : ''
                }
            }, {
                title: '评论图片',
                dataIndex: 'review_success_img_url',
                key: 'review_success_img_url',
                width: 150,
                render: (a, b) => {
                    return a ? <a href={a} target="_blank">查看Review</a> : ''
                }
            }, {
                title: '操作',
                dataIndex: 'type',
                key: 'type',
                width: 200,
                fixed: 'right',
                render: (a, b) => {
                    if (a === '2') {
                        return <Button size="small" onClick={() => { this.openSubmitOrderId(b['id']) }}>提交订单号</Button>
                    } else if (a === '5') {
                        return <div>
                            <Button size="small" onClick={() => { this.openUploadRebatedImg(b['id']) }}>上传付款成功截图</Button>
                            {b['isReview'] === '留评单' ? <Button size="small" onClick={() => { this.openUploadReviewImg(b['id']) }}>上传/review/rating/FB(截图)</Button> : ''}
                        </div>
                    } else if (a === '4') {
                        return <div>
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.endRebate(b['id']) }}>已结算</Button> : ''}
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.haveDispute(b['id']) }}>有争议</Button> : ''}
                            <Button size="small" onClick={() => { this.openUploadReviewUrl(b['id']) }}>上传评论链接</Button>
                            <Button size="small" onClick={() => { this.openUploadReviewImg(b['id']) }}>上传评论图片</Button>
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.isRefund(b['id']) }}>已退款</Button> : ''}
                        </div>
                    } else if (a === '7') {
                        //  return <Button size="small" onClick={()=>{this.openUploadReviewImg(b['id'])}}>上传评论图片</Button>
                        return <div>{showEndBtnStatus ? <Button size="small" onClick={() => { this.isRefund(b['id']) }}>已退款</Button> : ''}</div>
                    } else if (a === '8') {
                        return <div>
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.endRebate(b['id']) }}>已结算</Button> : ''}
                        </div>
                    } else if (a === '14') {
                        return <div>
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.doProcessed(b['id']) }}>已处理</Button> : ''}
                        </div>
                    } else if (a === '15') {
                        return <div>
                            <div>错误原因：{b['review_error']}</div>
                            <Button size="small" onClick={() => { this.openUploadReviewUrl(b['id']) }}>上传评论链接</Button>
                            <Button size="small" onClick={() => { this.endRebate(b['id']) }}>已结算</Button>
                        </div>
                    } else if (a === '17') {
                        return <div>
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.doExamineSuccess(b['id']) }}>审核成功</Button> : ''}
                            {showEndBtnStatus ? <Button size="small" onClick={() => { this.doExamineFailed(b['id']) }}>审核失败</Button> : ''}
                        </div>
                    }
                },
            }]
        })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleBuyerIdChange = (e) => {
        this.setParam({ 'buyer_id': e, 'admin_id': '' })
    }

    handleAdminIdChange = (e) => {
        this.setParam({ 'admin_id': e, 'buyer_id': '' })
    }

    handleTypeChange = (e) => {
        if (e === 17) {
            this.setState({
                rewSelectStatus: true
            })
        } else {
            this.setState({
                rewSelectStatus: false
            })
        }
        this.setParam({ 'type': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config,
            fileList: []
        }, () => {
            this.childEvent(1, 20, (data) => {
                this.setState({
                    tableInfo: data
                })
            });
        })
    }

    handleOrderIdChange = (e) => {
        this.setState({
            submitOrderId: e.target.value
        })
    }

    handleBuyerAddressChange = (e) => {
        this.setState({
            buyerAddress: e.target.value
        })
    }
    // 返现完成-已结算  按钮 工具栏
    settled = () => {
        const { param } = this.state;
        this.props.$request('/medium/set-rebate-settled-all', { admin_id: param['admin_id'] }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！')
                this.doQuery();
            }
        })
    }

    // 评论完成-已结算
    settleds = () => {
        const { param } = this.state;
        this.props.$request('/medium/set-review-settled-all', { admin_id: param['admin_id'] }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！')
                this.doQuery();
            }
        })
    }

    onSearch = (val) => {
        // console.log('search:', val);
    }

    // 加载是否可以结算
    loadCanEndBtn = () => {
        this.props.$request('/config/is-show-rebate-settled', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    showEndBtnStatus: res['data']['show']
                }, () => {
                    this.setColumns();
                })
            }
        }, () => { }, false)
    }

    // 有争议
    haveDispute = (id) => {
        this.props.$request('/medium/set-error', { id: id }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！')
                this.doQuery();
            }
        })
    }
    // 已处理
    doProcessed = (id) => {
        this.props.$request('/medium/set-error', { id: id }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！')
                this.doQuery();
            }
        })
    }

    seePayInfo = () => {
        const { tableInfo } = this.state;
        Modal.warning({
            centered: true,
            title: '打款信息',
            content: <div>
                <Row>
                    <Col span="8">结算金额:</Col>
                    <Col span="16">{tableInfo['settle_accounts_all']}</Col>
                    <Col span="8">结算金额CNY:</Col>
                    <Col span="16">{tableInfo['settle_accounts_cny_all']}</Col>
                    <Col span="8">支付宝姓名:</Col>
                    <Col span="16">{tableInfo['list'][0]['alipay_name']}</Col>
                    <Col span="8">支付宝账号</Col>
                    <Col span="16">{tableInfo['list'][0]['alipay_account']}</Col>
                </Row>
            </div>
        });
    }

    handleIdsChange = (e) => {
        this.setParam({ 'ids': e.target.value })
    }

    handleReviewChange = (e) => {
        this.setState({
            orderReviewUrl: e.target.value
        })
    }

    // 提交 review 链接
    handerSubmitReview = () => {
        const { orderReviewUrl, optionId } = this.state;
        this.props.$request('/medium/submit-review', { url: orderReviewUrl, id: optionId }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！')
                this.doQuery();
            }
        })
    }


    // 审核成功
    doExamineSuccess = (id) => {
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '此订单审核成功?',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk() {
                me.props.$request('/medium/audit-success', { id: id }, (res) => {
                    if (res['code'] === 200) {
                        message.success('操作成功！')
                        me.doQuery();
                    }
                })
            }
        });
    }

    // 审核失败
    doExamineFailed = (id) => {
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '此订单审核失败?',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk() {
                me.props.$request('/medium/audit-fail', { id: [id] }, (res) => {
                    if (res['code'] === 200) {
                        message.success('操作成功！')
                        me.doQuery();
                    }
                })
            }
        });
    }
    // 批量拒绝
    doMoreExamineFailed = () => {
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '批量审核失败?',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk() {
                me.props.$request('/medium/audit-fail', { id: me.state.optionIds }, (res) => {
                    if (res['code'] === 200) {
                        me.doQuery();
                        message.success('操作成功！')
                        this.setState({
                            optionIds:[]
                        })
                    }
                })
            }
        });
    }

    // 批量成功
    doMoreExamineSucceed = () => {
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '批量审核成功?',
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk() {
                me.props.$request('/medium/audit-all-success', { id: me.state.optionIds }, (res) => {
                    if (res['code'] === 200) {
                        me.doQuery();
                        message.success('操作成功！')
                        this.setState({
                            optionIds:[]
                        })
                    }
                })
            }
        });
    }

    
    setOptionIds = (ids) => {
        this.setState({
            optionIds: ids
        })
    }


    render() {
        const { tableInfo, param, config, columns, adminAccountList, buyerAccountlist, submitOrderStatus, submitOrderId, buyerAddress, uploadReviewUrlStatus, uploadReviewImgStatus, uploadRebatedImgStatus, optionId, showEndBtnStatus, orderReviewUrl, rewSelectStatus, optionIds } = this.state;
        config['columns'] = columns;
        const adminSelect = [];
        // const buyerSelect = [];
        adminAccountList.map(i => {
            adminSelect.push(<Option value={i['id']} key={i['key']}>{i['name']}</Option>)
        })
        // buyerAccountlist.map(i => {
        //     buyerSelect.push(<Option value={i['id']} key={i['key']}>{i['email']}</Option>)
        // })
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    {/* <span className="label">买家账号</span>
                    <Select
                        optionFilterProp="children"
                        showSearch
                        onSearch={this.onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={param['buyer_id']}
                        onChange={this.handleBuyerIdChange} className="inline_el" style={{ width: 250 }}>
                        {buyerSelect}
                    </Select> */}

                    <span className="label" style={{ marginRight: 10 }}>内部订单号</span>
                    <Input value={param['ids']} className="inline_el" onChange={this.handleIdsChange} style={{ width: 200 }} placeholder="可输入多个内部订单号以','分割"></Input>
                    {/* ({buyerAccountlist.length}) */}
                    <span className="label">中介账号</span>
                    <Select value={param['admin_id']} onChange={this.handleAdminIdChange} className="inline_el" style={{ width: 100 }}>
                        {adminSelect}
                    </Select>
                    <span className="label">订单状态</span>
                    <Select value={param['type']} onChange={this.handleTypeChange} className="inline_el" style={{ width: 250 }}>
                        <Option value={2}>等待买家提交订单号</Option>
                        <Option value={3}>等待卖家确认订单号</Option>
                        <Option value={4}>返现完成-未结算</Option>
                        <Option value={14}>返现完成-未结算-有争议</Option>
                        <Option value={5}>等待买家确认收到返现</Option>
                        <Option value={6}>等待卖家返现-卖家超时确认订单号了</Option>
                        <Option value={7}>返现完成-已结算</Option>
                        <Option value={8}>评论完成-未结算</Option>
                        <Option value={9}>评论完成-已结算</Option>
                        <Option value={10}>等待卖家同意</Option>
                        <Option value={11}>已关闭</Option>
                        <Option value={12}>买家提交订单号超时</Option>
                        <Option value={13}>卖家拒绝订单号</Option>
                        <Option value={15}>评论错误</Option>
                        <Option value={16}>已退款</Option>
                        <Option value={17}>等待审核</Option>
                    </Select>
                    {param['type']===17?<Button style={{ marginRight: 10 }} onClick={this.doMoreExamineFailed} disabled={!optionIds.length>0}>批量拒绝</Button>:''}
                    {param['type']===17?<Button style={{ marginRight: 10 }} onClick={this.doMoreExamineSucceed} disabled={!optionIds.length>0}>批量审核</Button>:''}

                    <Button type="primary" style={{ marginLeft: 0 }} onClick={this.doQuery}>查询</Button>
                    {param['type'] === 4 && showEndBtnStatus && !!tableInfo ? <Button style={{ marginLeft: 20 }} onClick={this.settled} disabled={!!param['admin_id'] && tableInfo['list'].length > 0 ? false : true}>返现完成-已结算</Button> : ''}
                    {param['type'] === 8 && showEndBtnStatus && !!tableInfo ? <Button style={{ marginLeft: 20 }} onClick={this.settleds} disabled={!!param['admin_id'] && tableInfo['list'].length > 0 ? false : true}>评论完成-已结算</Button> : ''}
                    {param['type'] === 4 && showEndBtnStatus && !!tableInfo ? <Button style={{ marginLeft: 20 }} onClick={this.seePayInfo} disabled={!(tableInfo['list'].length > 0 && !!param['admin_id'])}>申请打款</Button> : ''}
                    {!!tableInfo ? (<a href={tableInfo['url']} target="_blank" style={{ marginLeft: '20px', color: 'blue' }}>导出</a>) : ''}
                </div>
                {!!tableInfo ? (
                    <div>
                        {/* USD:{tableInfo['usd']} 、EUR:{tableInfo['eur']} 、GBP:{tableInfo['gbp']} 、CAD:{tableInfo['cad']} 、JPY:{tableInfo['jpy']} */}
                        <Row>
                            <Col span="3"><Statistic title={'汇率:' + tableInfo['usd_exchange_rate'] + '(' + tableInfo['usd_exchange_rate_time'] + ')'} suffix={'USD'} value={tableInfo['usd']} /></Col>
                            <Col span="3"><Statistic title={'汇率:' + tableInfo['eur_exchange_rate'] + '(' + tableInfo['eur_exchange_rate_time'] + ')'} suffix={'EUR'} value={tableInfo['eur']} /></Col>
                            <Col span="3"><Statistic title={'汇率:' + tableInfo['gbp_exchange_rate'] + '(' + tableInfo['gbp_exchange_rate_time'] + ')'} suffix={'GBP'} value={tableInfo['gbp']} /></Col>
                            <Col span="3"><Statistic title={'汇率:' + tableInfo['cad_exchange_rate'] + '(' + tableInfo['cad_exchange_rate_time'] + ')'} suffix={'CAD'} value={tableInfo['cad']} /></Col>
                            <Col span="2"></Col>
                            <Col span="2"><Statistic title={'留评率'} suffix={'%'} value={tableInfo['review_ratio']} valueStyle={{ color: '#ff8f00' }} /></Col>
                            <Col span="2"><Statistic title={'退单率'} suffix={'%'} value={tableInfo['refund_ratio']} valueStyle={{ color: '#ff8f00' }} /></Col>
                            <Col span="2"><Statistic title={'订单处理时效'} suffix={''} value={tableInfo['time_effect']} valueStyle={{ color: '#ff8f00' }} /></Col>
                            <Col span="2"><Statistic title={'近一个月退单率'} suffix={'%'} value={tableInfo['refund_ratio_month']} valueStyle={{ color: '#ff8f00' }} /></Col>
                        </Row>
                    </div>
                ) : ''}
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} rewSelect={rewSelectStatus} setOptionIds={this.setOptionIds} />

                {/* 提交订单号弹框 */}
                <Modal
                    visible={submitOrderStatus}
                    title="提交订单号"
                    centered
                    onCancel={this.closeSubmitOrderId}
                    footer={false}
                >
                    <div style={{ marginBottom: 20 }}>
                        <Input value={submitOrderId} placeholder="亚马逊订单号" onChange={this.handleOrderIdChange}></Input>
                    </div>
                    {/* <Input  value={buyerAddress} placeholder="收货人信息" onChange={this.handleBuyerAddressChange}></Input> */}

                    <div style={{ marginTop: 20 }}><Button type="primary" onClick={this.handerSubmitOrder} disabled={!!submitOrderId ? false : true}>提交</Button></div>
                </Modal>

                {/* 完成返现并上传图片弹框 */}
                {uploadRebatedImgStatus ? <Modal
                    visible={uploadRebatedImgStatus}
                    title="上传付款成功截图"
                    centered
                    onCancel={this.closeUploadRebatedImg}
                    footer={false}
                >
                    <Upload
                        action={"https://adminapi.pingamz.com/medium/rebate-success"}
                        listType="picture-card"
                        onChange={this.handleChange}
                        headers={{ 'Authorization': window.sessionStorage.getItem('P_TOKEN') }}
                        accept="image/png, image/jpeg"
                        name={optionId}
                    >
                        {this.state.fileList.length >= 1 ? null : (<div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传付款成功截图</div>
                        </div>)}
                    </Upload>
                </Modal> : ''}

                {/* 上传评论链接弹框 */}
                <Modal
                    visible={uploadReviewUrlStatus}
                    title="上传评论链接"
                    centered
                    onCancel={this.closeUploadReviewUrl}
                    footer={false}
                >
                    <div style={{ marginBottom: 20 }}>
                        <Input.TextArea value={orderReviewUrl} placeholder="评论链接" onChange={this.handleReviewChange}></Input.TextArea>
                    </div>
                    {/* <Input  value={buyerAddress} placeholder="收货人信息" onChange={this.handleBuyerAddressChange}></Input> */}

                    <div style={{ marginTop: 20 }}><Button type="primary" onClick={this.handerSubmitReview} disabled={!!orderReviewUrl ? false : true}>提交</Button></div>
                </Modal>

                {/* 上传评论图片弹框 */}
                {uploadReviewImgStatus ? <Modal
                    visible={uploadReviewImgStatus}
                    title="上传/review/rating/FB(截图)"
                    centered
                    onCancel={this.closeUploadReviewImg}
                    footer={false}
                >
                    <Upload
                        action={"https://adminapi.pingamz.com/medium/submit-review-image"}
                        listType="picture-card"
                        onChange={this.handleChange}
                        headers={{ 'Authorization': window.sessionStorage.getItem('P_TOKEN') }}
                        accept="image/png, image/jpeg"
                        name={optionId}
                    >
                        {this.state.fileList.length >= 1 ? null : (<div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传评论图片</div>
                        </div>)}
                    </Upload>
                </Modal> : ""}
            </div>
        );
    }
}

export default BaseHoc(SpecialOrderList);