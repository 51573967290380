import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button,message,Row,Col} from 'antd';
class BuyerRestrictReturnCash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyer_id:'',
            remark:''
        };
    }

    handlerBuyerId = e => {
        this.setState({
            buyer_id: e.target.value
        })
    }

    
    handlerRemark = e => {
        this.setState({
            remark: e.target.value
        })
    }

    // 提交订单号
    submit = () => {
        const { buyer_id,remark } = this.state;
        this.props.$request('/buyer/restrict-return-cash', {buyer_id:buyer_id,remark:remark}, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！');
                this.setState({
                    buyer_id : '',
                    remark : ''
                })
            }
        })
    }
    render() {
        const { buyer_id,remark } = this.state;
        const style = {
            marginBottom:20,
            width:300
        }
        return (
            <div className="content_tabs_box" style={{paddingTop:40}}>
                <Row>
                    <Col span={4} offset={4}>
                        买家 Id：
                    </Col>
                    <Col span={16}>
                        <Input style={style} value={buyer_id} onChange={this.handlerBuyerId}/>
                    </Col>
                    <Col span={4} offset={4}>
                        备注：
                    </Col>
                    <Col span={16}>
                        <Input.TextArea style={style} value={remark} onChange={this.handlerRemark} />
                    </Col>
                    <Col span={4} offset={4}>
                        <Button type="primary" disabled={!(!!buyer_id&&!!remark)} onClick={this.submit}>提交</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BaseHoc(BuyerRestrictReturnCash);