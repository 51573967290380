import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Select,Button,Input} from 'antd';
const { Option } = Select;
class ExamineSettlement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: '',
            currency:''
        };

    }

    // onChange = (e, str) => {
    //     this.setState({
    //         arrival_time: str
    //     })
    // }

    onChange = (e, str) => {
        let val = e.target.value;
        val = val.replace(/[^\d.]/g,"");
        val = val.replace(/^\./g,"");
        val = val.replace(/\.{2,}/g,".");
        val = val.replace(".","$#$").replace(/\./g,"").replace("$#$",".");;
        this.setState({
            account: val
        })
    }

    onCurrencyChange=(e)=>{
        this.setState({
            currency: e
        })
    }

    submit=()=>{
        const { account,currency } = this.state;
        const { item } = this.props;
        this.props.$request('/medium/dg-settle-success',{id:item['id'],account,currency },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }
    
    render() {
        const { account,currency } = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="结算"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={8}><div style={style}>币种:</div></Col>
                    <Col span={16}>
                        <Select value={currency} style={{width:'100%'}} onChange={this.onCurrencyChange}>
                            <Option value={'USD'}>USD</Option>
                            <Option value={'EUR'}>EUR</Option>
                            <Option value={'GBP'}>GBP</Option>
                            <Option value={'CNY'}>CNY</Option>
                            <Option value={'CAD'}>CAD</Option>
                            <Option value={'JPY'}>JPY</Option>
                        </Select>
                    </Col>

                    <Col span={8}><div style={style}>结算金额:</div></Col>
                    <Col span={16}>
                        <Input
                         style={{width:'100%'}} min={0} onChange={this.onChange} value={account}
                        />
                    </Col>
                    <Col span={8}></Col>
                    <Col span={16}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!account&&!!currency)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(ExamineSettlement);