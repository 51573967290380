import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button ,PageHeader, message,Row, Col,Select } from 'antd';
const { Option } = Select;
class BuyerAddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            profile:''
        };

    }

    hanlderChange=(e)=>{
        this.setState({
            email:e.target.value
        })
    }
    hanlderProfileChange =(e)=>{
        this.setState({
            profile:e.target.value
        })
    }

    handleLevalChange=(e)=>{
        this.setState({
            level:e
        })
    }
    doAdd = ()=>{
        const {email,profile,level} = this.state;
        this.props.$request('/buyer/reg-buyer',{email:email,profile:profile,level:level},(res)=>{
            if(res['code']===200){
                message.success('新建成功');
                this.setState({
                    email:'',
                    profile:'',
                    level:''
                })
            }
        })
    }
    render() {
        const {email,profile,level} = this.state;
        return (
            <div className="content_tabs_box">
                <PageHeader title="新增买家" style={{ paddingLeft: '0' }} />
                <Row>
                    <Col span={4}>邮箱</Col>
                    <Col span={20}><Input value={email} onChange={this.hanlderChange} style={{width:300,marginBottom:20}}/></Col>
                    <Col span={4}>Profile</Col>
                    <Col span={20}><Input value={profile} onChange={this.hanlderProfileChange} style={{width:300,marginBottom:20}}/></Col>
                    <Col span={4}>等级</Col>
                    <Col span={20}>
                        <Select  onChange={this.handleLevalChange} value={level} style={{width:300,marginBottom:20}}>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={4}>4</Option>
                            {/* <Option value={5}>5</Option>
                            <Option value={6}>6</Option>
                            <Option value={7}>7</Option>
                            <Option value={8}>8</Option> */}
                        </Select>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={20}><Button type="primary" disabled={!(!!email&&!!profile&&!!level)} style={{marginTop:20}} onClick={this.doAdd}>新增</Button></Col>
                
                
                
                    </Row>
                

            </div>
        );
    }
}

export default BaseHoc(BuyerAddUser);