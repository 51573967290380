import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message, DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const { RangePicker } = DatePicker;
const { Option } = Select;
const tconfig = {
    url: '/lottery/list',
    param: {},
    callback: null,
    columns: []
}
class BuyerLuckyDrawList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                buyer_id:'',
                pay_status:'',
                begin:'',
                end:''
            },
            columns: [],
            byPassStatus: false,
            optionItem: null
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: '买家Id',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width: 130,
                sorter: (a, b) => parseFloat(a.buyer_id) - parseFloat(b.buyer_id),
            },{
                title:'买家邮箱',
                dataIndex:'buyer_email',
                key:'buyer_email',
                width:200,
                
            },{
                title:'创建时间',
                dataIndex:'createtime',
                key:'createtime',
                width:200,
                
            },{
                title:'订单号',
                dataIndex:'order_id',
                key:'order_id',
                width:200,  
            },{
                title:'交易号',
                dataIndex:'pay_tid',
                key:'pay_tid',
                width:200
            },{
                title:'奖品',
                dataIndex:'lottery_prize',
                key:'lottery_prize',
                width:200,
            },{
                title:'支付金额',
                dataIndex:'amount',
                key:'amount',
                width:200,
                render:(a,b)=>{
                    return a + b['currency']
                }
            },{
                title:'购买数量',
                dataIndex:'lottery_count',
                key:'lottery_count',
                width:200,
            },{
                title:'支付状态',
                dataIndex:'pay_type',
                key:'pay_type',
                width:200, 
            },{
                title:'开奖状态',
                dataIndex:'lottery_type',
                key:'lottery_type',
                width:200, 
            },{
                title:'联系信息',
                dataIndex:'ft_msg',
                key:'ft_msg',
                width:200,
            }]
        })
        this.doQuery();
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }


    handleBuyerIdChange = (e) => {
        this.setParam({ 'buyer_id': e.target.value })
    }

    handleOrderStatusChange = (e) => {
        this.setParam({ 'pay_status': e })
    }

    handleTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,tableInfo } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label" style={{ marginRight: 20 }}>买家ID</span>
                    <Input className="inline_el" value={param['buyer_id']} onChange={this.handleBuyerIdChange} style={{ width: 200 }} />
                    

                    <span className="label">订单状态</span>
                    <Select value={param['pay_status']} onChange={this.handleOrderStatusChange} className="inline_el">
                        <Option value=''>全部</Option>
                        <Option value={0}>未支付</Option>
                        <Option value={1}>支付成功</Option>
                        <Option value={2}>支付失败</Option>
                    </Select>

                    <span className="label">下单时间</span>
                    <RangePicker className="add_margin" onChange={this.handleTimeChange} />
                    
                    
                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} />
            </div>
        );
    }
}

export default BaseHoc(BuyerLuckyDrawList);