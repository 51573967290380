import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import {Table, Row, Col  } from 'antd';
const columns = [{
    title:'日期',
    dataIndex:'begin',
    key:'begin',
    width:'15%',
    align:'center'
},{
    title:'注册人数',
    dataIndex:'count1',
    key:'count1',
    width:'15%',
    align:'center'
},{
    title:'激活人数',
    dataIndex:'count2',
    key:'count2',
    width:'15%',
    align:'center'
},{
    title:'登录人数',
    dataIndex:'count3',
    key:'count3',
    width:'15%',
    align:'center'
},{
    title:'拉取商品人数',
    dataIndex:'count4',
    key:'count4',
    width:'15%',
    align:'center'
},{
    title:'推广商品人数',
    dataIndex:'count5',
    key:'count5',
    width:'15%',
    align:'center'
}]
class SellerStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
           data:null
        };
    }
    componentDidMount(){
        this.loadStatisticsData();
    }

    loadStatisticsData=()=>{
        this.props.$request('/stat/seller',{},(res)=>{
            if(res['code']===200){
                this.setState({
                    data:res['data']
                })
            }
        })
    }
    render() {
        const { data } = this.state;
        return (
            <div className="content_tabs_box">
                {!!data?<Row>
                    <Col span="24">
                        <div style={{marginBottom:'20px'}}>统计周期：{data[0]['begin']} - {data[6]['end']}</div>
                    </Col>
                    <Col span="24"><Table dataSource={data} columns={columns} pagination={false}/></Col>
                </Row>:''}
            </div>
        );
    }
}

export default BaseHoc(SellerStatistics);