import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, Upload, Modal, Countdown, message } from 'antd';
import ProTable from '../../../widget/proTable';
import { PlusOutlined, RightSquareFilled } from '@ant-design/icons';
import '../../../base/index.scss';
import AddHideAsin from './addHideAsin';

const { Option } = Select;
const tconfig = {
    url: '/medium/sku-list',
    param: {},
    callback: null,
    columns: []
}
class MediumList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                admin_id: '',
                sku: '',
            },
            adminAccountList:[],
            columns: [],
            tableInfo: '',
            doAdd:false,
            optionIds:[]
        };

    }
    componentDidMount() {
        this.loadAdminAccount();
        this.setColumns();
        this.doQuery();
    }

    setColumns = () => {
        this.setState({
            columns: [{
                title: '编号',
                dataIndex: 'id',
                key: 'id',
                width: 150
            }, {
                title: '后台账号',
                dataIndex: 'admin_name',
                key: 'admin_name',
                width: 150
            }, {
                title: '站点',
                dataIndex: 'country_code',
                key: 'country_code',
                width: 150
            }, {
                title: 'Sku',
                dataIndex: 'asin',
                key: 'asin',
                width: 250
            }, {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                width: 80,
                render:(a,b)=>{
                    return <Button size="small" onClick={()=>{
                        this.delSku(b['id'])
                    }}>移除</Button>
                }
            }]
        })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleAdminNameChange = (e) => {
        this.setParam({ 'admin_id': e })
    }

    handleSkuChange = (e) => {
        this.setParam({ 'sku': e.target.value })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config,
            fileList: []
        }, () => {
            this.childEvent(1, 20, (data) => {
                this.setState({
                    tableInfo: data
                })
            });
        })
    }


    closeAdd = ()=>{
        this.setState({
            doAdd:false
        })
    }

    openAdd = ()=>{
        this.setState({
            doAdd:true
        })
    }

    setOptionIds=(ids)=>{
        console.log(ids)
        this.setState({
            optionIds:ids
        })
    }


    doMoreDelSku = ()=>{
        const {optionIds} = this.state;
        this.delSku(optionIds);
    }

    delSku = (id)=>{
        this.props.$request('/medium/del-sku',{id:id},(res)=>{
            if(res['code']===200){
                this.setState({
                    optionIds:[]
                })
                message.success('操作成功');
                this.doQuery();
            }
        })
    }

    loadAdminAccount = () => {
        this.props.$request('/medium/get-admin-account', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    adminAccountList: res['data']['data']
                })
            }
        })
    }

    // closeRequest = ()=>{
    //     const {optionIds} = this.state;
    //     this.props.$request('/medium/set-auto-request-status',{ids:optionIds,st:0},(res)=>{
    //         if(res['code']===200){
    //             message.success('操作成功');
    //             this.doQuery();
    //         }
    //     })
    // }

    render() {
        const { tableInfo, param, config, columns, doAdd,optionIds ,adminAccountList} = this.state;
        config['columns'] = columns;
        const adminSelect = [];
        adminAccountList.map(i => {
            adminSelect.push(<Option value={i['id']} key={i['key']}>{i['name']}</Option>)
        })
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">后台账号</span>
                    <Select value={param['admin_id']} onChange={this.handleAdminNameChange} style={{width:200,marginRight:20}}>
                        {adminSelect}
                    </Select>

                    <span className="label">Sku</span>
                    <Input className="inline_el" value={param['sku']} onChange={this.handleSkuChange}/>


                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    <Button onClick={this.doMoreDelSku} style={{ marginLeft: 20 }} disabled={optionIds.length===0}>去除隐藏的sku</Button>
                    {/* <Button onClick={this.closeRequest} style={{ marginLeft: 20 }} disabled={optionIds.length===0}>关闭申请</Button> */}

                    <Button onClick={this.openAdd} style={{float:'right'}}>隐藏商品ASIN</Button>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} rewSelect={true} setOptionIds={this.setOptionIds}/>

                {doAdd? <AddHideAsin close={this.closeAdd} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(MediumList);