import React, { Component } from 'react';
import BaseHoc from '../base/baseHoc';
import { Table, Pagination } from 'antd';
const pageSizeOptions = [20];
class proTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationConfig: {
                listTotal: 0,
                pageSize: 20,
                page: 1
            },
            listData: [],
            selectedRowKeys: [],
            selectedRowKeysIds: []
        };

    }

    // componentWillMount() {
    //     console.log('self will mount')
    // }

    componentDidMount() {
        if(this.props.autoLoad===false){
            // console.log(this.props.autoLoad)  
        }else{
            this.loadData();
        }
        this.props.handleQuery(this.loadData)
    }
    // componentDidUpdate() {
    //     const { page, pageSize } = this.state.paginationConfig;
    //     this.loadData(page, pageSize);
    // }

    loadData = (page = 1, pageSize = this.state.paginationConfig.pageSize,cb) => {
        const { url, param } = this.props;
        param['page'] = page;
        param['eachpage'] = pageSize;
        this.setState({
            listData: [],
            selectedRowKeys: [],
            selectedRowKeysIds: []
        })
        this.props.$request(url, param, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    listData: res['data']['list'],
                    selectedRowKeys: [],
                    selectedRowKeysIds: [],
                    paginationConfig: {
                        listTotal: res['data']['total'],
                        pageSize: pageSize,
                        page: page
                    }
                })
                if(!!cb){
                    cb(res['data'])
                }
            }
        })
    }

    // 翻页
    pageChange = (e, s) => {
        this.loadData(e, s);
    }

    onListSelectChange = (e, items) => {
        let ids = [];
        items.forEach((item) => {
            ids.push(item.id)
        })
        this.setState({ selectedRowKeys: e, selectedRowKeysIds: ids });
        this.props.setOptionIds(ids);
    }

    render() {
        const { paginationConfig, listData ,selectedRowKeys} = this.state;
        const { page, pageSize, listTotal } = paginationConfig;
        const { columns,rewSelect} = this.props;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onListSelectChange,
        };
        return (
            <div style={{paddingBottom:20}}>
                {rewSelect?<Table rowSelection={rowSelection} className="pro_table" columns={columns} pagination={false} dataSource={listData} scroll={{ x: 1500, y: 500 }} sticky />:
                <Table className="pro_table" columns={columns} pagination={false} dataSource={listData} scroll={{ x: 1500, y: 500 }} sticky />}

                <Pagination
                    showSizeChanger
                    showTotal={(total, range) => `共 ${Math.ceil(listTotal / pageSize)} 页 ${listTotal} 条`}
                    current={page}
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    // onShowSizeChange={this.pageShowSizeChange}
                    total={listTotal}
                    onChange={this.pageChange}
                />
            </div>
        );
    }
}

export default BaseHoc(proTable);