import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Input,InputNumber,Select,Button} from 'antd';
const {Option} = Select;
class TransferAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currency:'USD',
            account:'',
            type:'',
            remark:'' 
        };
    }

    handlerCurrencyChange=(e)=>{
        this.setState({
            currency:e
        })
    }

    handlerTypeChange=(e)=>{
        this.setState({
            type:e
        })
    }

    handlerAccountChange=(e)=>{
        this.setState({
            account:e
        })
    }

    handlerRemarkChange=(e)=>{
        this.setState({
            remark:e.target.value
        })
    }

    doTransfer=()=>{
        const {currency,account,type,remark} = this.state;
        const {item,close,reload} = this.props;
        this.props.$request('/seller/change-account',{id:item['id'],currency,account,type,remark},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                reload();
                close();
            }
        })
    }

    render() {
        const { currency,account,type,remark } = this.state;
        const { item } = this.props;
        const style = {width:300,marginBottom:10}
        return (
            <Modal 
                visible={true}
                title="转账"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>卖家 ID:</Col>
                    <Col span={18} style={style}>{item['id']}</Col>

                    <Col span={6}>货币:</Col>
                    <Col span={18}>
                        <Select value={currency} onChange={this.handlerCurrencyChange} style={style}>
                            <Option value="USD">USD</Option>
                            <Option value="EUR">EUR</Option>
                            <Option value="GBP">GBP</Option>
                            <Option value="CAD">CAD</Option>
                            <Option value="JPY">JPY</Option>
                        </Select>
                    </Col>

                    <Col span={6}>金额:</Col>
                    <Col span={18}>
                        <InputNumber value={account} onChange={this.handlerAccountChange} min={0} style={style}/>
                    </Col>

                    <Col span={6}>类型:</Col>
                    <Col span={18}>
                        <Select value={type} onChange={this.handlerTypeChange} style={style}>
                            <Option value="1">加钱</Option>
                            <Option value="2">扣钱</Option>
                        </Select>
                    </Col>

                    <Col span={6}> 备注</Col>
                    <Col span={18}>
                        <Input.TextArea value={remark} onChange={this.handlerRemarkChange} style={style}></Input.TextArea>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doTransfer} disabled={!!currency&&!!account&&!!type&&!!remark?false:true}>提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(TransferAccounts);