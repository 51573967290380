import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,InputNumber,Input,PageHeader} from 'antd';
const { TextArea } = Input;
class BonusPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            point:'',
            remark:''
        };

    }
    handleAddPointChange=(e)=>{
        this.setState({
            point:e
        })
    }
    handleRemarkChange=(e)=>{
        this.setState({
            remark:e.target.value
        })
    }
    handleIdChange=(e)=>{
        this.setState({
            id:e.target.value
        })
    }
    submit=()=>{
        // const { item } = this.props;
        this.props.$request('/buyer/add-point',{
            id: this.state.id,
            point: this.state.point,
            remark: this.state.remark
        },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                // this.props.reload();
                // this.props.close();,
                this.setState({
                    id: '',
                    point: '',
                    remark: ''
                })
            }
        })
    }

    
    render() {
        const { id,point,remark } = this.state;
        // const { item } = this.props;
        const style ={
            marginBottom: '20px'
        }
        return (
            // <Modal
            //     visible={true}
            //     title="赠送积分"
            //     centered
            //     onCancel={this.props.close}
            //     footer={false}
            // >
            <div className="content_tabs_box">
                <PageHeader title="赠送积分" style={{ paddingLeft: '0' }} />
                <Row>
                    <Col span={4}>
                        <div style={style}>买家 Id：</div>
                    </Col>
                    <Col span={18}><Input value={id} onChange={this.handleIdChange} style={{width:300}}></Input></Col>
                    {/* <Col span={6}><div style={style}>买家邮箱：</div></Col>
                    <Col span={18}>{item['email']}</Col> */}
                    <Col span={4}><div style={style}>赠送积分：</div></Col>
                    <Col span={18}><InputNumber value={point} onChange={this.handleAddPointChange} style={{width:300}}/></Col>
                    <Col span={4}><div style={style}>备注：</div></Col>
                    <Col span={18}><TextArea value={remark} onChange={this.handleRemarkChange} style={{width:300}}/></Col>
                    <Col span={4}></Col>
                    <Col span={18}><Button type="primary" disabled={!(!!id&&!!remark&&!!point)} onClick={this.submit} style={{marginTop:20}}>提交</Button></Col>
                </Row>
            {/* </Modal> */}
            </div>
        );
    }
}

export default BaseHoc(BonusPoints);