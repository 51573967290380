import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, message, Modal, Row, Col, Checkbox } from 'antd';

class UserAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataInfo: null,
            nowDate: ''
        };
    }

    componentDidMount() {
        console.log(new Date())
        let newDate = new Date();
        this.setState({
            nowDate: newDate.getFullYear() + '-' + (parseInt(newDate.getMonth())+1) + '-' + newDate.getDate()
        })

        this.loadData();
    }

    loadData = () => {
        this.props.$request('/finance/get-seller-account', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    dataInfo: res['data']
                })
            }
        })
    }

    render() {
        const { dataInfo, nowDate } = this.state;
        return (
            <div className="content_tabs_box">
                <div style={{marginBottom:20}}>
                    数据统计时间：2016-10-20 -- {nowDate}
                    <div>* 暂冻：因卖家评测产品产生暂时冻结的金额，会随着卖家订单状态产生实时变化。</div>
                    <div>* 非活跃：2020年1月1日起无法登录行为用户账号涉及金额的金额</div>
                </div>
                <div>
                    {!!dataInfo?<Row>
                        <Col span={8}>USD 总余额：{dataInfo['usd']}</Col>
                        <Col span={8}>USD 暂冻：{dataInfo['lock_usd']}</Col>
                        <Col span={8}>USD 非活跃：{dataInfo['death_usd']}</Col>

                        <Col span={8}>EUR 总余额：{dataInfo['eur']}</Col>
                        <Col span={8}>EUR 暂冻：{dataInfo['lock_eur']}</Col>
                        <Col span={8}>EUR 非活跃：{dataInfo['death_eur']}</Col>

                        <Col span={8}>GBP 总余额：{dataInfo['gbp']}</Col>
                        <Col span={8}>GBP 暂冻：{dataInfo['lock_gbp']}</Col>
                        <Col span={8}>GBP 非活跃：{dataInfo['death_gbp']}</Col>

                        <Col span={8}>CAD 总余额：{dataInfo['cad']}</Col>
                        <Col span={8}>CAD 暂冻：{dataInfo['lock_cad']}</Col>
                        <Col span={8}>CAD 非活跃：{dataInfo['death_cad']}</Col>

                        <Col span={8}>JPY 总余额：{dataInfo['jpy']}</Col>
                        <Col span={8}>JPY 暂冻：{dataInfo['lock_jpy']}</Col>
                        <Col span={8}>JPY 非活跃：{dataInfo['death_jpy']}</Col>
                    </Row>:''}
                </div>

            </div>
        );
    }
}

export default BaseHoc(UserAccount);