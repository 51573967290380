import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Button,Input} from 'antd';
class SubmitOrderId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amz_id: ''
        };

    }
    componentDidMount(){

    }

    onChange=(e) => {
        this.setState({
            amz_id : e.target.value
        })
    }

    submit=()=>{
        const { amz_id } = this.state;
        const { item } = this.props;
        this.props.$request('/medium/set-dg-order-amz-id',{ id:item['id'],amz_id},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }
    
    render() {
        const { amz_id } = this.state;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="提交订单号"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={8}><div style={style}>亚马逊/沃尔玛订单号:</div></Col>
                    <Col span={16}>
                        <Input style={{width:'100%'}} onChange={this.onChange} value={amz_id} />
                    </Col>
                    <Col span={8}></Col>
                    <Col span={16}><Button type="primary" onClick={this.submit} style={{marginTop:20}} disabled={!(!!amz_id)}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(SubmitOrderId);