import React, { Component } from 'react';
import store from '../../store/index';
import BaseHoc from '../base/baseHoc';
import { Menu } from 'antd';

import { OPEN_TAB, ACTIVE_MENU_KEY } from '../../store/action';
// import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
// import Home from './components/home'


const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const MenuDatas = [
  { key: 'main', name: '首页', isHadChild: false, child: [] },
  {
    key: 'sellerManage', name: '卖家管理', isHadChild: true, child: [
      { key: 'sellerList', name: '卖家列表', isHadChild: false },
      { key: 'sellerWithdrawalList', name: '卖家申请提现列表', isHadChild: false },
      { key: 'sellerCapitalRecord', name: '卖家资金流水', isHadChild: false },
      // { key: 'sellerProducts', name: '卖家商品查询', isHadChild: false },
      // { key: 'sellerMoving', name: '卖家搬家', isHadChild: false },
      // { key: 'sellerNotice', name: '卖家公告', isHadChild: false },
      // { key: 'sellerHelp', name: '卖家帮助文档', isHadChild: false },
      // { key: 'sellerActiveModal', name: '卖家活动弹框', isHadChild: false },
      // { key: 'sellerPromotionSetting', name: '卖家推广设置', isHadChild: false },
    ]
  },
  {
    key: 'buyerManage', name: '买家管理', isHadChild: true, child: [
      { key: 'buyerAddUser', name: '新增买家', isHadChild: false },
      { key: 'buyerList', name: '买家列表', isHadChild: false },
      { key: 'buyerCapitalRecord', name: '买家资金流日志', isHadChild: false },
      // { key: 'buyerProfileChange', name: '修改profile', isHadChild: false },
      // { key: 'buyerNotice', name: '买家公告', isHadChild: false },
      // { key: 'buyerActiveModal', name: '买家活动弹框', isHadChild: false },
      // { key: 'buyerPromotionSetting', name: '买家推广设置', isHadChild: false },
      // { key: 'buyerPoints', name: '买家积分日志', isHadChild: false }
    ]
  },
  {
    key: 'orderManage', name: '订单管理', isHadChild: true, child: [
      { key: 'orderDetail', name: '订单详情', isHadChild: false },
      { key: 'reviewBypass', name: '评价Review提交绕过', isHadChild: false }
    ]
  },
  {
    key: 'rebateManage', name: '返现管理', isHadChild: true, child: [
      { key: 'rebateList', name: '返现列表', isHadChild: false }
    ]
  },
  {
    key: 'specialOrderManage', name: '特殊订单管理', isHadChild: true, child: [
      { key: 'specialOrderList', name: '订单列表', isHadChild: false },
    ]
  },

  {
    key: 'configManage', name: '配置项', isHadChild: true, child: [
      { key: 'addMenu', name: '新增菜单', isHadChild: false },
    ]
  },

  {
    key: 'systemManage', name: '系统管理', isHadChild: true, child: [
      { key: 'adminList', name: '管理员列表', isHadChild: false },
    ]
  },
]

let MenuData = [
  { key: 'main', name: '首页', isHadChild: false, child: [] }
]

class MenuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuNames: {},
      activeMenuKey: [MenuData[0]['key']]
    };
  }

  componentDidMount() {
    this.listenerProps();
    store.subscribe(this.listenerProps);
    this.loadMenus();
  }
  //加载菜单
  loadMenus=()=>{
    this.props.$request('/config/get-user-menu',{},(res)=>{
      if(res['code']===200){
        MenuData = MenuData.concat(res['data']);
        this.generateMenuNames();
      }
    })
  }
  // 生成菜单键值
  generateMenuNames = () => {
    let menuNames = {}
    for (let i = 0; i < MenuData.length; i++) {
      if (!MenuData[i]['isHadChild']) {
        menuNames[MenuData[i]['key']] = MenuData[i]['name'];
      } else {
        for (let j = 0; j < MenuData[i]['child'].length; j++) {
          menuNames[MenuData[i]['child'][j]['key']] = MenuData[i]['child'][j]['name'];
        }

      }
    }
    this.setState({
      menuNames: menuNames
    })
  }

  listenerProps = () => {
    let { menuShow, activeMenuKey,isLogin } = store.getState();
    console.log(isLogin);
    if (activeMenuKey === '') {
      activeMenuKey = MenuData[0]['key']
    }
    this.setState({
      menuShow: menuShow,
      activeMenuKey: [activeMenuKey]
    })
  }

  handlerMenu = ({ key }) => {
    let menuName = this.state.menuNames[key];
    store.dispatch({ type: ACTIVE_MENU_KEY, key: 'activeMenuKey', status: { activeMenuKey: key } })
    store.dispatch({ type: OPEN_TAB, key: 'openTabConfig', status: { key, menuName } })
  }

  render() {
    let MenuDataList = [];
    for (let i = 0; i < MenuData.length; i++) {
      if (!MenuData[i]['isHadChild']) {
        MenuDataList.push(<Menu.Item className="my_menu_item" key={MenuData[i]['key']}><span>{MenuData[i]['name']}</span></Menu.Item>)
      } else {
        let childList = [];
        for (let j = 0; j < MenuData[i]['child'].length; j++) {
          childList.push(<Menu.Item key={MenuData[i]['child'][j]['key']}>{MenuData[i]['child'][j]['name']}</Menu.Item>)
        }
        MenuDataList.push(<SubMenu key={MenuData[i]['key']} title={<span><span>{MenuData[i]['name']}</span></span>}>{childList}</SubMenu>)
      }
    }
    return (
      <div>
        <Menu theme="dark" onClick={this.handlerMenu} defaultSelectedKeys={this.state.activeMenuKey} selectedKeys={this.state.activeMenuKey} mode="inline">
          {MenuDataList}
        </Menu>
      </div>
    );
  }
}

export default BaseHoc(MenuList);