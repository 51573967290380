import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal, message, Row, Col, DatePicker, Select, Button,Input,Switch,InputNumber ,Radio} from 'antd';
const { Option } = Select;

class AddMedium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin_name: '',
            buyer_name: '',
            country_code: '',
            person: '',
            is_auto_request: 0,
            is_can_review: 0,
            is_greater_than: 0,
            is_full_cashback: 0,
            max_process_order_count: 1,
            order_threshold_order_count: 1,
            process_or_threshold_type: 1,
            adminAccountList:[],
            discount:'',
            alipay_name:'',
            alipay_account:'',
            review_type:''
        };
    }

    handlerCountryCodeChange = (e) => {
        this.setState({
            country_code: e
        })
    }

    // handlerAdminNameChange = (e) => {
    //     this.setState({
    //         admin_name: e.target.value
    //     })
    // }

    handlerAdminNameChange = (e) => {
        this.setState({
            admin_name: e
        })
    }

    handlerBuyerNameChange = (e) => {
        this.setState({
            buyer_name: e.target.value
        })
    }

    handlerPersonChange = (e) => {
        this.setState({
            person: e.target.value
        })
    }
    // 是否开启代理
    onAgentChange=(e)=>{
        this.setState({
            is_auto_request:e?1:0
        })
    }
    // 是否可以留评
    onReivewChange=(e)=>{
        this.setState({
            is_can_review:e?1:0
        })
    }
    // 全额返现
    onAllPayChange=(e)=>{
        this.setState({
            is_full_cashback: e?1:0
        })
    }
    // 货值要求
    onPriceChange=(e)=>{
        this.setState({
            is_greater_than:e
        })
    }
    // 处理单量
    onMaxProcessChange=(e)=>{
        this.setState({
            max_process_order_count:e
        })
    }
    //处理单量阈值
    onOrderThresholdChange=(e)=>{
        this.setState({
            order_threshold_order_count:e
        })
    }
    // 限制对象
    onObjectChange=(e)=>{
        this.setState({
            process_or_threshold_type:e.target.value
        })
    }

    onDiscountChange=(e)=>{
        this.setState({
            discount:e
        })
    }

    onAlipayNameChange=(e)=>{
        this.setState({
            alipay_name:e.target.value
        })
    }

    onAlipayAccountChange=(e)=>{
        this.setState({
            alipay_account:e.target.value
        })
    }

    handlerReviewTypeChange=(e)=>{
        this.setState({
            review_type: e
        })
    }

    loadAdminAccount = () => {
        this.props.$request('/medium/get-admin-account', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    adminAccountList: res['data']['data']
                })
            }
        })
    }

    componentDidMount(){
        this.loadAdminAccount();
    }

    doSubmit = () => {
        const { admin_name, buyer_name, country_code, person ,is_auto_request,is_can_review,is_greater_than,max_process_order_count,order_threshold_order_count,process_or_threshold_type,discount,alipay_name,alipay_account,review_type,is_full_cashback} = this.state;
        const { close, reload } = this.props;
        this.props.$request('/medium/add-medium', {
            admin_name, buyer_name, country_code, person ,is_auto_request, is_can_review,is_greater_than,max_process_order_count,order_threshold_order_count,process_or_threshold_type,discount,alipay_name,alipay_account,review_type,is_full_cashback
        }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功');
                reload();
                close();
            }
        })
    }

    render() {
        const { admin_name, buyer_name, country_code, person,is_auto_request,is_can_review,is_greater_than,max_process_order_count,order_threshold_order_count,process_or_threshold_type,adminAccountList,discount,alipay_name,alipay_account,review_type,is_full_cashback } = this.state;
        const style = { width: 300, marginBottom: 10 }
        const adminSelect = [];
        adminAccountList.map(i => {
            adminSelect.push(<Option value={i['name']} key={i['key']}>{i['name']}</Option>)
        })
        return (
            <Modal
                visible={true}
                title="新增中介账号"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>管理员账号:</Col>
                    {/* <Col span={18} style={style}>
                        <Input value={admin_name} onChange={this.handlerAdminNameChange} style={style} />
                    </Col> */}
                    <Col span={18} style={style}>
                        <Select value={admin_name} onChange={this.handlerAdminNameChange} style={style}>
                            {adminSelect}
                        </Select>
                    </Col>

                    <Col span={6}>买家账号:</Col>
                    <Col span={18} style={style}>
                        <Input.TextArea value={buyer_name} onChange={this.handlerBuyerNameChange} style={style} placeholder="可新增多个账号，以','分割；"/>
                    </Col>

                    <Col span={6}>站点:</Col>
                    <Col span={18} style={style}>
                        <Select value={country_code} onChange={this.handlerCountryCodeChange} style={style}>
                            <Option value="US">US</Option>
                            <Option value="DE">DE</Option>
                            <Option value="FR">FR</Option>
                            <Option value="UK">UK</Option>
                            <Option value="CA">CA</Option>
                            <Option value="JP">JP</Option>
                        </Select>
                    </Col>

                    <Col span={6}>负责人:</Col>
                    <Col span={18} style={style}>
                        <Input value={person} onChange={this.handlerPersonChange} style={style}/>
                    </Col>

                    <Col span={6}>是否分配订单:</Col>
                    <Col span={18} style={style}>
                        <Switch checked={is_auto_request?true:false} onChange={this.onAgentChange} />
                    </Col>

                    <Col span={6}>是否可以留评:</Col>
                    <Col span={18} style={style}>
                        <Switch checked={is_can_review?true:false} onChange={this.onReivewChange} />
                    </Col>

                    <Col span={6}>是否接受全额返现:</Col>
                    <Col span={18} style={style}>
                        <Switch checked={is_full_cashback?true:false} onChange={this.onAllPayChange}/>
                    </Col>


                    <Col span={6}>留评类型:</Col>
                    <Col span={18} style={style}>
                        <Select value={review_type} onChange={this.handlerReviewTypeChange} style={style}>
                            <Option value="">不留评</Option>
                            <Option value="1">文字留评</Option>
                        </Select>
                    </Col>

                    <Col span={6}>货值要求:</Col>
                    <Col span={18} style={style}>
                        <InputNumber value={is_greater_than} onChange={this.onPriceChange} min={0}/>
                    </Col>

                    <Col span={6}>处理单量:</Col>
                    <Col span={18} style={style}>
                        <InputNumber value={max_process_order_count} onChange={this.onMaxProcessChange} min={1}/>
                    </Col>

                    <Col span={6}>处理单量阈值:</Col>
                    <Col span={18} style={style}>
                        <InputNumber value={order_threshold_order_count} onChange={this.onOrderThresholdChange} min={1}/>
                    </Col>

                    <Col span={6}>限制对象:</Col>
                    <Col span={18} style={style}>
                    <Radio.Group buttonStyle="solid" onChange={this.onObjectChange} value={process_or_threshold_type}>
                        <Radio.Button value={1}>中介</Radio.Button>
                        <Radio.Button value={0}>买家账号</Radio.Button>
                    </Radio.Group>
                    </Col>

                    <Col span={6}>中介折扣:</Col>
                    <Col span={18} style={style}>
                        <InputNumber value={discount} onChange={this.onDiscountChange} min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} style={style}/>
                    </Col>
                    <Col span={6}>中介支付宝姓名:</Col>
                    <Col span={18} style={style}>
                        <Input value={alipay_name} onChange={this.onAlipayNameChange} style={style}/>
                    </Col>
                    <Col span={6}>中介支付宝账号:</Col>
                    <Col span={18} style={style}>
                        <Input value={alipay_account} onChange={this.onAlipayAccountChange} style={style}/>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} disabled={!(!!admin_name&&!!buyer_name&&!!country_code&&!!person)}>提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(AddMedium);