import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Input,Button} from 'antd';

class ChangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg:''
        };
    }

    handlerTextChange=(e)=>{
        this.setState({
            msg:e.target.value
        })
    }

    componentDidMount(){
        const {item} = this.props;
        this.setState({
            msg:item['cancel_msg']
        })
    }


    doSubmit=()=>{
        const {msg} = this.state;
        const {item} = this.props;
        this.props.$request('/seller/set-cancel-msg',{id:item['id'],msg},(res)=>{
            if(res['code']===200){
                this.cancelSub(item['id'])
            }
        })
    }
    // 取消订阅
    cancelSub=(id)=>{
        const {close,reload} = this.props;
        this.props.$request('/seller/cancel-sub',{id:id},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                reload();
                close();
            }
        })
    }

    render() {
        const { msg } = this.state;
        const { item } = this.props;
        const style = {width:300,marginBottom:10}
        return (
            <Modal 
                visible={true}
                title="取消订阅"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>卖家 ID:</Col>
                    <Col span={18} style={style}>{item['id']}</Col>

                    <Col span={6}>取消原因:</Col>
                    <Col span={18}>
                         <Input.TextArea placeholder="请填写取消订阅原因" onChange={this.handlerTextChange} style={{marginBottom:20}} value={msg}></Input.TextArea>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} disabled={!!msg?false:true}>取消订阅</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(ChangeInfo);