import React, { Component } from 'react';
import BaseHoc from '../../base/baseHoc';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    // componentWillMount() {
    //     console.log('self will mount')
    // }

    // componentDidMount() {
    //     console.log('self did mount')
    // }

    listenerProps = () => {
        // const { isLogin } = store.getState();
        // this.setState({
        //     isLogin: isLogin
        // })
    }

    test=()=>{
        const {$request} = this.props;
        $request('/test',{})
    }

    render() {
        return (
            <div className="content_tabs_box" style={{textAlign:'center'}}>
                <h1 style={{fontSize:'70px',marginTop:'100px'}}>Admin</h1>
                <p>You have been signed</p>
            </div>
        );
    }
}

export default BaseHoc(Home);