import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Select, Button, message ,Modal} from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import '../../../base/index.scss';

const { Option } = Select;

class BuyerNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            editorState: BraftEditor.createEditorState('<p>初始值</p>'), // 设置编辑器初始内容
            outputHTML: '<p></p>' // 编辑器输出内容
        };


    }

    componentDidMount() {
        this.setState({
            editorState: BraftEditor.createEditorState(' ')
        })
    }
    handleChange = (editorState) => {
        this.setState({
            editorState: editorState,
            outputHTML: editorState.toHTML()
        })
    }

    submit = () => {
        const { country } = this.state;
        this.props.$request('/config/add-notic', {
            txt: this.state.outputHTML,
            type: 2,
            country: country,
            end: '2030-12-31'
        }, (res) => {
            if (res['code'] === 200) {
                message.success('操作成功！')
            }
        })
    }

    handleCountryChange = (e) => {
        this.setState({
            country: e
        })
    }

    render() {
        const { editorState, outputHTML, country } = this.state
        return (
            <Modal
                visible={true}
                title="发布公告"
                centered
                onCancel={this.props.close}
                footer={false}
                width={800}
            >
            <div className="content_tabs_box">
                <div className="editor-wrapper">
                    <div>
                        站点：<Select onChange={this.handleCountryChange} style={{width:300}} value={country}>
                            <Option value="US">US</Option>
                            <Option value="DE">DE</Option>
                            <Option value="FR">FR</Option>
                            <Option value="UK">UK</Option>
                            <Option value="CA">CA</Option>
                            <Option value="JP">JP</Option>
                        </Select>
                    </div>
                    <BraftEditor
                        value={editorState}
                        onChange={this.handleChange}
                    />
                </div>
                <Button type="primary" onClick={this.submit}>提交</Button>
            </div>
            </Modal>
        );
    }
}

export default BaseHoc(BuyerNotice);