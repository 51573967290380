import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,DatePicker,Select,Button,Input} from 'antd';
class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text:''
        };

    }
    handleProfileChange=(e)=>{
        this.setState({
            text:e.target.value
        })
    }
    submit=()=>{
        const { item } = this.props;
        this.props.$request('/buyer/edit-profile',{
            id:item['id'],
            profile: this.state.text
        },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }

    
    render() {
        const { text } = this.state;
        const { item } = this.props;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="修改Profile"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>
                        <div style={style}>买家 Id：</div>
                    </Col>
                    <Col span={18}>{item['id']}</Col>
                    <Col span={6}><div style={style}>买家邮箱：</div></Col>
                    <Col span={18}>{item['email']}</Col>
                    <Col span={6}><div style={style}>买家Profile：</div></Col>
                    <Col span={18}><Input.TextArea value={text} onChange={this.handleProfileChange}/></Col>
                    <Col span={6}></Col>
                    <Col span={18}><Button type="primary" onClick={this.submit} style={{marginTop:20}}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(EditProfile);