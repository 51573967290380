import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,InputNumber,Select,Button,Input} from 'antd';
const { Option } = Select;
class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type:'',
            account:'',
            currency:'',
            remark:'',
            msg:''
        };

    }


    handleAccountChange=(e)=>{
        this.setState({
            account:e
        })
    }

    handleTypeChange=(e)=>{
        this.setState({
            type:e
        })
    }

    handleCurrencyChange=(e)=>{
        this.setState({
            currency:e
        })
    }

    handleRemarkChange=(e)=>{
        this.setState({
            remark:e.target.value
        })
    }

    handleMsgChange=(e)=>{
        this.setState({
            msg:e.target.value
        })
    }

    
//     buyer_id=买家ID
// type=0-100%同意订单领取-扣钱 1-代购订单购买-扣钱 3-100%同意订单领取没法购买-加钱 4-代购订单购买失败-加钱
// account=金额
// currency=货币
    submit=()=>{
        const { item } = this.props;
        const {type,account,currency,msg,remark} = this.state;
        this.props.$request('/buyer/add-buyer-capital',{
            buyer_id:item['id'],
            type,
            account,
            currency,
            msg,
            remark
        },(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.props.reload();
                this.props.close();
            }
        })
    }

    
    render() {
        const { type,account,currency,remark,msg } = this.state;
        const { item } = this.props;
        const style ={
            marginBottom: '20px'
        }
        return (
            <Modal
                visible={true}
                title="增加流水"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}><div style={style}>买家 Id：</div></Col>
                    <Col span={18}>{item['id']}</Col>
                    <Col span={6}><div style={style}>买家邮箱：</div></Col>
                    <Col span={18}>{item['email']}</Col>


                    <Col span={6}><div style={style}>流水类型：</div></Col>
                    <Col span={18}>
                        <Select value={type} onChange={this.handleTypeChange} style={{width:'100%'}}>
                            <Option value="0">100%同意订单领取-扣钱</Option>
                            <Option value="1">代购订单购买-扣钱</Option>
                            <Option value="3">100%同意订单领取没法购买-加钱</Option>
                            <Option value="4">代购订单购买失败-加钱</Option>
                            <Option value="6">普通订单退款-扣钱</Option>
                            <Option value="7">额外补偿-加钱</Option>
                        </Select>
                    </Col>

                    <Col span={6}><div style={style}>代购内部订单号：</div></Col>
                    <Col span={18}>
                        <Input value={msg} onChange={this.handleMsgChange} placeholder="DG-12356231712321323" style={{width:'100%'}}/>
                    </Col>

                    <Col span={6}><div style={style}>金额：</div></Col>
                    <Col span={18}><InputNumber min={0} value={account} onChange={this.handleAccountChange} style={{width:'100%'}}/></Col>

                    <Col span={6}><div style={style}>币种：</div></Col>
                    <Col span={18}>
                        <Select value={currency} onChange={this.handleCurrencyChange} style={{width:'100%'}}>
                            <Option value="USD">USD</Option>
                            <Option value="EUR">EUR</Option>
                            <Option value="GBP">GBP</Option>
                            <Option value="CAD">CAD</Option>
                            <Option value="JPY">JPY</Option>
                        </Select>
                    </Col>
                    
                    <Col span={6}><div style={style}>备注：</div></Col>
                    <Col span={18}>
                        <Input.TextArea value={remark} onChange={this.handleRemarkChange} style={{width:'100%'}}/>
                    </Col>
                    <Col span={6}></Col>
                    <Col span={18}><Button type="primary" onClick={this.submit} disabled={!(!!type&&!!account&&!!currency&&!!remark&&!!msg)} style={{marginTop:20}}>提交</Button></Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(EditProfile);