import { SET_LOGIN_STATUS,SET_LOADING_STATUS,SET_MENU_STATUS,OPEN_TAB,ACTIVE_MENU_KEY } from './action'

export default(state,action)=>{
    const { key,status } = action;
    switch (action.type){
        case SET_LOGIN_STATUS:
        return {...state,[key]:status};
        case SET_LOADING_STATUS:
        return {...state,[key]:status};
        case SET_MENU_STATUS:
        return {...state,[key]:!state[key]}
        case OPEN_TAB:
        return {...state,[key]:status}
        case ACTIVE_MENU_KEY:
        return {...state,[key]:status[key]}
        default:
        return state
    }
}