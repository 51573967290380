import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, DatePicker ,Modal,Row,Col} from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
const tconfig = {
    url: '/config/get-seller-event-list',
    param: {},
    callback: null,
    columns: []
}
class EventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {},
            columns: [],
            optionItem:null,
            doEditStatus:false,
            eventName:'',
            code:''
        };

    }
    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.buyerId) - parseFloat(b.buyerId),
            }, {
                title: '事件名称',
                dataIndex: 'event_name',
                key: 'event_name',
            },{
                title:'事件代码',
                dataIndex:'event_id',
                key:'event_id'
            },{
                title:'操作',
                dataIndex:'option',
                key:'option',
                render:(a,b)=>{
                    return <Button size="small" onClick={()=>{
                        this.doOpenEdit(b)
                    }}>编辑</Button>
                }
            }]
        })

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    

    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent();
        })
    }

    doOpenEdit=(item)=>{
        if(!!item){
            console.log(item)
            this.setState({
                optionItem:item,
                doEditStatus:true,
                eventName:item['event_name'],
                code:item['event_id']
            })
        }else{
            this.setState({
                doEditStatus:true
            })
        }
        
    }
    closeModal = ()=>{
        this.setState({
            optionItem:null,
            doEditStatus:false,
            eventName:'',
            code:''
        })
    }

    handlerEventNameChange=(e)=>{
        this.setState({
            eventName:e.target.value
        })
    }

    handlerCodeChange=(e)=>{
        this.setState({
            code:e.target.value
        })
    }

    doSubmit=()=>{
        const {  optionItem,eventName,code } = this.state;
        this.props.$request('/config/add-seller-activity-event',{
            id:optionItem['id'],
            name:eventName,
            eid:code
        },(res)=>{
            if(res['code']===200){
                this.closeModal();
                this.doQuery();
            }
        })
    }

    render() {
        const { param, config, columns,optionItem,doEditStatus,eventName,code } = this.state;
        config['columns'] = columns;
        const style = {width:300,marginBottom:10}
        return (
            <div className="content_tabs_box">
                <Button type="primary" style={{ marginLeft: 20 }} onClick={()=>{this.doOpenEdit(null)}} style={{marginBottom:10}}>新增</Button>
                
                <ProTable {...config} handleQuery={this.handleQuery} />

                {doEditStatus?<Modal 
                    visible={true}
                    title="事件编辑"
                    centered
                    onCancel={this.closeModal}
                    footer={false}
                >
                    <Row>
                        {!!optionItem?<Col span={6}>事件 ID:</Col>:''}
                        {!!optionItem?<Col span={18} ><div style={style}>{!!optionItem?optionItem['id']:''}</div></Col>:''}
                        <Col span={6}>事件名:</Col>
                        <Col span={18}>
                            <Input value={eventName} onChange={this.handlerEventNameChange} style={style}></Input>
                        </Col>

                        <Col span={6}>事件代码</Col>
                        <Col span={18}>
                            <Input.TextArea value={code} onChange={this.handlerCodeChange} style={style}></Input.TextArea>
                        </Col>
                        <Col span={18}>
                            <Button type="primary" onClick={this.doSubmit} disabled={!!eventName&&!!code?false:true}>提交</Button>
                        </Col>
                    </Row>
                </Modal>:''}
            </div>
        );
    }
}

export default BaseHoc(EventList);