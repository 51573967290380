import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Input, Button, message, Row, Col} from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
const monthFormat = 'YYYY-MM';
class PlatformOperation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().getFullYear() + '-'+ ((new Date().getMonth()=== 0 )?(12):(new Date().getMonth()))
        }
    }

    componentDidMount(){
        // this.doQuery();
    }
    handleTimeChange=(e, str)=>{
        this.setState({ 
            date: str
        })
    }

    doQuery=()=>{
        const { date } = this.state;
        this.props.$request('',{date:date+'-01'},(res)=>{
            console.log(res)
        })
    }

    disabledDate =(current)=> {
        // Can not select days before today and today
        return  current.format("YYYY-MM") >= moment().startOf('month').format("YYYY-MM");;
    }
    render() {
        const { date } = this.state;
        console.log(date)
        return (
            <div>
                <div>
                    <span className="label">查询周期</span>
                    <DatePicker defaultValue={moment(date, monthFormat)} className="add_margin" onChange={this.handleTimeChange} format={monthFormat} picker="month" disabledDate={this.disabledDate}/>
                    <Button type="primary" onClick={this.doQuery}>查询</Button>
                </div>
                <div>
                    <Row>
                        <Col></Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default BaseHoc(PlatformOperation);