import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Input, Button,Select } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';

const { RangePicker } = DatePicker;

const { Option } = Select;

const tconfig = {
    url: '/buyer/point-list',
    param: {},
    callback: null,
    columns: []
}
class BuyerPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                buyer_id: '',
                type:''
            },
            columns: [],
            pointTypeList:{},
            tableInfo:'',
        };

    }

    

    componentDidMount() {
        this.loadPointType();
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '买家id',
                dataIndex: 'buyer_id',
                key: 'buyer_id',
                width:100,
            }, {
                title: '创建时间',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
            }, {
                title: '积分',
                dataIndex: 'point',
                key: 'point',
                width: 100,
                sorter: (a, b) => parseFloat(a.point) - parseFloat(b.point),
                render:(a,b)=>{
                    return b['act']+a;
                }
            }, {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                width: 100,
                // render: (a) => {
                //     switch (a) {
                //         case '1':
                //             return '每月赠送'
                //         case '2':
                //             return '注册-激活账户'
                //         case '3':
                //             return '注册-绑定手机号'
                //         case '4':
                //             return '注册-绑定fb'
                //         case '5':
                //             return '注册-绑定tw'
                //         case '6':
                //             return '注册-完善profile'
                //         case '7':
                //             return '注册-完善银行账户信息'
                //         case '8':
                //             return '订单-成功申请3个商品'
                //         case '9':
                //             return '订单-成功提交第一笔订单号'
                //         case '10':
                //             return '订单-留评'
                //         case '11':
                //             return '订单-额外返现成功留评'
                //         case '12':
                //             return '每日登陆'
                //         case '13':
                //             return '每日申请商品'
                //         case '14':
                //             return '申请余额提现'
                //         case '15':
                //             return '系统赠送'
                //     }
                // }
            },{
                title:'备注',
                dataIndex:'msg',
                key:'msg',
                width:200
            }],

        })

        this.doQuery();

    }

    listenerProps = () => {
        // const { isLogin } = store.getState();
        // this.setState({
        //     isLogin: isLogin
        // })
    }

    loadPointType=()=>{
        this.props.$request('/buyer/get-point-type',{},(res)=>{
            if(res['code']===200){
                console.log(res)
                this.setState({
                    pointTypeList:res['data']
                })
            }
        })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleIdChange = (e) => {
        this.setParam({ 'buyer_id': e.target.value })
    }

    handleOptionTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handletypeChange=(e)=>{
        this.setParam({ 'type': e})
    }


    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns, pointTypeList,tableInfo} = this.state;
        config['columns'] = columns;
        let options = [];
        for(let key in pointTypeList){
            options.push(<Option value={key} key={key}>{pointTypeList[key]}</Option>)
        }
        return (
            <div className="content_tabs_box">
                <div>
                    {/* <Button>刷新</Button> */}
                    <div className="tool_bar">
                        <span className="label">买家Id</span>
                        <Input className="inline_el" value={param['id']} onChange={this.handleIdChange} />

                        {/* <span className="label">操作时间</span>
                        <RangePicker className="add_margin" onChange={this.handleOptionTimeChange} /> */}

                        <span className="label">积分类型</span>
                        <Select value={param['type']} bordered={true} style={{width:200}} onChange={this.handletypeChange}>
                            {options}
                        </Select>

                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>


                        {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(BuyerPoints);