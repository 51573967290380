import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import AddNotice from './addNotice';
import '../../../base/index.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/config/notic-list',
    param: {},
    callback: null,
    columns: []
}
class SellerNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                type:1,
                show:1,
                country:'CN'
            },
            columns: [],
            optionItem:null,
            showTplStatus:false,
            openAddNotice:false
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: '发布日期',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
                // render:(a,b)=>{
                //     let date = new Date(a);
                //     console.log(date)
                //     return date.getFullYear()+'-'+date.getMonth()-1+'-'+date.getDay();
                // }
            },{
                title: '推送用户',
                dataIndex: 'show_type',
                key: 'show_type',
                width: 200,
                render:(a,b)=>{
                    if(!!!a){
                        return '全部'
                    }
                    if(a==='1'){
                        return '正式用户'
                    }else if(a==='2'){
                        return '取消用户'
                    }else if(a==='3'){
                        return '试用期用户'
                    } 
                }
            },{
                title: '公告状态',
                dataIndex: 'notice_show',
                key: 'notice_show',
                width: 200,
                render:(a,b)=>{
                    return a==='1'?'生效中':'已关闭'
                }
            }, {
                title:'公告内容',
                dataIndex:'notice_txt',
                key:'notice_txt',
                width:500,
                render:(a)=>{
                    return a.replace(/<[^>]+>|&[^>]+;/g, "")
                }
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 50,
                fixed: 'right',
                render: (a,b) => {
                    return  [
                    <Button size="small" onClick={()=>{this.showTpl(b)}}>查看</Button>
                    ,<Button size="small" onClick={()=>{this.undercarriage(b['id'])}}>下架</Button>
                ]
                },
            }],

        })

        this.doQuery();

    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleShowStateChange = (e) => {
        this.setParam({ 'show': e })
    }


    handleQuery = (cb) => {
        this.childEvent = cb;
    }
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    showTpl =(item)=>{
        this.setState({
            optionItem:item,
            showTplStatus:true
        })
    }

    doAddNotice=()=>{
        this.setState({
            openAddNotice:true
        })
    }

    closeAddNotice=()=>{
        this.setState({
            openAddNotice:false
        })
    }
    // 下架
    undercarriage=(id)=>{
        this.props.$request('/config/close-doc',{id:id},res=>{
            if(res['code']===200){
                message.success('已下架');
                this.doQuery();
            }
        })
    }

    render() {
        const { tableInfo,param, config ,columns,showTplStatus,optionItem,openAddNotice} = this.state;
        config['columns'] =columns;
        return (
            <div className="content_tabs_box">
                <div>
                    <div className="tool_bar">
                        <span className="label">状态</span>
                        <Select value={param['show']} onChange={this.handleShowStateChange}>
                            <Option value={1}>生效中</Option>
                            <Option value={2}>已关闭</Option>
                        </Select>
                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>
                        <Button style={{float:'right'}} onClick={this.doAddNotice}>发布公告</Button>
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery}  autoLoad={false}/>
                {showTplStatus?<Modal
                    visible={showTplStatus}
                    title="查看公告"
                    centered
                    onCancel={()=>{
                        this.setState({
                            optionItem:null,
                            showTplStatus:false
                        })
                    }}
                    footer={false}
                >
                    <div dangerouslySetInnerHTML={{__html:optionItem['notice_txt']}}>
                    </div>
                </Modal>:''}

                {openAddNotice?<AddNotice close={this.closeAddNotice} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(SellerNotice);