import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, message, Modal ,Row,Col,Checkbox} from 'antd';
import ProTable from '../../../widget/proTable';


const tconfig = {
    url: '/user/list',
    param: {},
    callback: null,
    columns: []
}

class AdminList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            password:'',
            optionModal:false,
            optionAdminItem:'',
            config: tconfig,
            columns: [],
            menuList:[],
            checkMenuIds:[],
            jurisdiction:false
        };
    }

    componentDidMount(){
        this.setColumns();
    }
    setColumns = () => {
        this.setState({
            columns: [{
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                width: 150
            }, {
                title: '用户名',
                dataIndex: 'username',
                key: 'username',
                width: 150
            },{
                title:'操作',
                dataIndex:'key',
                key:'key',
                width:150,
                render:(a,b)=>{
                    return <Button onClick={()=>{this.openOptionModal(b)}}>编辑</Button>
                }
            }]
        })
    }
    openOptionModal = (item)=>{
        // 加载菜单
        this.props.$request('/config/get-menu-list',{},(res)=>{
            if(res['code']===200){
                let list = [];
                let data = res['data'];
                for(let i = 0;i<data.length;i++){
                    let arr = [{label:data[i]['name'],value:data[i]['id']}];
                    for(let j = 0;j<data[i]['child'].length;j++){
                        arr.push({
                            label: data[i]['child'][j]['name'], value: data[i]['child'][j]['id'] 
                        })
                    }
                    list.push(arr);
                }
                this.setState({
                    menuList:list,
                    optionModal:true,
                    optionAdminItem:item,
                    checkMenuIds:!!item['menu']?item['menu']:[],
                    jurisdiction:!!item['is_whole']&&item['is_whole']==="1"?true:false,
                })
            }
        })
        
    }
    closeOptionModal = ()=>{
        this.setState({
            optionModal:false,
            optionAdminItem:''
        })
    }

    handlerAdd=()=>{
        const { username,password } = this.state;
        this.props.$request('/user/add-user',{
            username:username,
            password:password
        },(res)=>{
            if(res['code']===200){
                this.doQuery();
                message.success('添加成功')
            }
        })
    }

    handlerChangeUserName=(e)=>{
        this.setState({
            username:e.target.value
        })
    }

    handlerChangePassword=(e)=>{
        this.setState({
            password:e.target.value
        })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    doQuery = () => {
        const { config } = this.state;
        this.setState({
            config: config
        }, () => {
            this.childEvent();
        })
    }

    handlerCheckChange=(checkedValues)=>{
        console.log(checkedValues)
        this.setState({
            checkMenuIds:checkedValues
        })
    }

    setMenu=()=>{
        const { optionAdminItem , checkMenuIds,jurisdiction} = this.state;
        this.props.$request('/user/set-menu',{
            buyer_id:optionAdminItem['id'],
            menu_ids:checkMenuIds,
            is_whole:jurisdiction?1:0
        },(res)=>{
            if(res['code']===200){
                message.success('设置成功。')
                this.closeOptionModal();
                this.doQuery();
            }
        })
    }

    onAllJurisdictionChange=(e)=>{
        this.setState({
            jurisdiction:e.target.checked
        })
    }

    render() {
        const { username,password,optionModal,config,columns,menuList,optionAdminItem,checkMenuIds ,jurisdiction} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <Row style={{marginBottom:10}}>
                    <Col span={1}>
                        用户名:
                    </Col>
                    <Col span={6}>
                        <Input value={username} onChange={this.handlerChangeUserName}></Input>
                    </Col>
                    <Col span={1} offset={1}>
                        密码:
                    </Col>
                    <Col span={6} >
                        <Input value={password} onChange={this.handlerChangePassword}></Input>
                    </Col>
                    <Col span={2} offset={1}>
                        <Button type="primary" disabled={!(!!username&&!!password)} onClick={this.handlerAdd}>新增</Button>
                    </Col>
                </Row>

                <ProTable {...config} handleQuery={this.handleQuery}/>

                {optionModal ? <Modal
                    title="修改菜单权限"
                    centered
                    visible={optionModal}
                    onCancel={this.closeOptionModal}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={false}
                    width={1000}
                >
                    
                    <Row>
                        <Col span={4} style={{paddingBottom:20}}>用户名：</Col>
                        <Col span={20} style={{paddingBottom:20}}>{optionAdminItem['username']}</Col>
                        <Col span={4} style={{paddingBottom:20}}>权限：</Col>
                        <Col span={20} style={{paddingBottom:20}}><Checkbox checked={jurisdiction} onChange={this.onAllJurisdictionChange}>是否完整权限</Checkbox></Col>
                        <Col span={4} style={{paddingBottom:20}}>菜单列表：</Col>
                        <Col span={20} style={{paddingBottom:20}}>
                        <div style={{maxHeight:400,overflow:'auto'}}>
                            <Checkbox.Group style={{ width: '100%' }} value={checkMenuIds} onChange={this.handlerCheckChange}>
                                {menuList.map((i,k)=>
                                    <div>
                                        <div style={{fontSize:18,fontWeight:'bold',marginBottom:'5'}}>{i[0]['label']}</div>
                                        <Row key={k} style={{marginBottom:'20px'}}>
                                            {i.map((j,s)=>
                                            <Col span={6}>
                                                <Checkbox value={j['value']} key={s}>{j['label']}</Checkbox>
                                            </Col>)}
                                        </Row>
                                    </div>
                                )}
                            </Checkbox.Group>
                        </div>
                        </Col>

                        <Col span={4} style={{paddingBottom:20}}></Col>
                        <Col span={20} style={{paddingBottom:20}}>
                            <Button type="primary" onClick={this.setMenu}>设置</Button>
                        </Col>
                    </Row>

                </Modal> : ''}
            </div>
        );
    }
}

export default BaseHoc(AdminList);