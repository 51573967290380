import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Form, Input, Button,message,Modal,Row,Col} from 'antd';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


class SellerActiveAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {
        
    }
    render() {
        const {item} = this.props;
        const style = {
            marginBottom:'15px'
        };
        let info = '';
        if(item['refund_type']==='2'){
            info = JSON.parse(item['account_info']);
        }
        return (
            <Modal 
                visible={true}
                title="返现信息"
                centered
                onCancel={this.props.close}
                footer={false}>
                    <div>
                    {item['refund_type']==='1'?<Row><Col span="24">{item['account_info']}</Col></Row>:''}
                    {item['refund_type']==='2'&&!!info?
                        <Row>
                            <Col span="6"><div style={style}>名：</div></Col><Col span="18">{info['first_name']}</Col>
                            <Col span="6"><div style={style}>姓：</div></Col><Col span="18">{info['last_name']}</Col>
                            <Col span="6"><div style={style}>国家：</div></Col><Col span="18">{info['country']}</Col>
                            <Col span="6"><div style={style}>洲：</div></Col><Col span="18">{info['states']}</Col>
                            <Col span="6"><div style={style}>市：</div></Col><Col span="18">{info['city']}</Col>
                            <Col span="6"><div style={style}>地址：</div></Col><Col span="18">{info['address']}</Col>
                            <Col span="6"><div style={style}>邮编：</div></Col><Col span="18">{info['post_code']}</Col>
                            <Col span="6"><div style={style}>银行国家：</div></Col><Col span="18">{info['bank_country']}</Col>
                            <Col span="6"><div style={style}>routing：</div></Col><Col span="18">{info['routing']}</Col>
                            <Col span="6"><div style={style}>account：</div></Col><Col span="18">{info['account']}</Col>
                            <Col span="6"><div style={style}>账户类型：</div></Col><Col span="18">{info['account_type']}</Col>
                        </Row>:''}
                    </div>
            </Modal>
        );
    }
}

export default BaseHoc(SellerActiveAdd);