import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, DatePicker,Row,Col } from 'antd';
const { Option } = Select;
const { RangePicker } = DatePicker;
class MediumBusinessStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            param:{
                approve_begin:'',
                approve_end:'',
                settled_begin:'',
                settled_end:'',
                currency:'USD'
            },
            data:null
        };
    }

    getStatistics=()=>{
        const { param } = this.state;
        this.props.$request('/finance/medium-stat',param,(res)=>{
            console.log(res);
            if(res['code']===200){
                this.setState({
                    data:res['data']
                })
            }
        })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleApprovedTimeChange=(e,str)=>{
        this.setParam({ 'approve_begin': str[0], 'approve_end': str[1] })
    }

    handleSettledTimeChange=(e,str)=>{
        this.setParam({ 'settled_begin': str[0], 'settled_end': str[1] })
    }

    handleCurrencyChange = (e) => {
        this.setParam({ 'currency': e })
    }

    render() {
        const { param,data } = this.state;
        return (
            <div className="content_tabs_box">
                <span className="label">同意时间</span>
                <RangePicker className="add_margin" onChange={this.handleApprovedTimeChange} />

                <span className="label">结算时间</span>
                <RangePicker className="add_margin" onChange={this.handleSettledTimeChange} />

                <span className="label">币种</span>
                <Select value={param['currency']}  onChange={this.handleCurrencyChange} style={{width:200,marginRight:20}}>
                    <Option value="USD">USD</Option>
                    <Option value="GBP">GBP</Option>
                    <Option value="EUR">EUR</Option>
                    <Option value="CAD">CAD</Option>
                    <Option value="JPY">JPY</Option>
                </Select>

                <Button type="primary" onClick={this.getStatistics} disabled={!(!!param['approve_begin']&&!!param['approve_end']&&!!param['settled_begin']&&!!param['settled_end'])}>查找</Button>
                {!!data?
                <Row>
                    <Col span={4}><div style={{marginBottom:20,marginTop:40}}>未结算订单数：</div> </Col>
                    <Col span={20}><div style={{marginBottom:20,marginTop:40}}>{data['not_settled_count']}</div> </Col>

                    <Col span={4}><div style={{marginBottom:20}}>未结算金额：</div> </Col>
                    <Col span={20}><div style={{marginBottom:20}}>{data['not_settled_sum']}</div> </Col>

                    <Col span={4}><div style={{marginBottom:20}}>已结算订单数：</div> </Col>
                    <Col span={20}><div style={{marginBottom:20}}>{data['settled_count']}</div> </Col>
                    
                    <Col span={4}><div style={{marginBottom:20}}>已结算金额：</div> </Col>
                    <Col span={20}><div style={{marginBottom:20}}>{data['settled_sum']}</div> </Col>
                </Row>
                :''}
            </div>
        );
    }
}

export default BaseHoc(MediumBusinessStatistics);