import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import store from '../../../store/index';
import { SET_LOGIN_STATUS } from '../../../store/action';
import './login.scss';
import { Control } from 'react-keeper';
import BaseHoc from '../../base/baseHoc';

class Login extends Component {

    componentDidMount() {
        this.listenerProps()
        store.subscribe(this.listenerProps);
    }

    listenerProps = () => {
        const { isLogin } = store.getState();
        if (isLogin) {
            Control.go('/')
        }
    }
    render() {
        const onFinish = (values) => {
            this.props.$request('/user/login',values,(res)=>{
                if(res['code']===200){
                    window.sessionStorage.setItem('USERNAME',res['data']['username']);
                    window.sessionStorage.setItem('IS_WHOLE',res['data']['is_whole']);
                    store.dispatch({ type: SET_LOGIN_STATUS, key: 'isLogin', status: true });
                }else{
                    message.error(res['message'])
                }
            })
            
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };
        const tailLayout = {
            wrapperCol: {
                offset: 10,
                span: 16,
            },
        };
        return (
            <div className="login_box">
                <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <div style={{textAlign:'center',fontSize:'30px',fontWeight:'bold',marginBottom:'20px'}}>Admin</div>
                    <Form.Item label="邮箱" name="username" rules={[{ required: true, message: '请输入用户名', }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="密码" name="password" rules={[{ required: true, message: '请输入密码', }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">登录</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default BaseHoc(Login);