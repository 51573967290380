import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ShowRemake from './showRemake';

const { Option } = Select;
const tconfig = {
    url: '/order/refund-list',
    param: {},
    callback: null,
    columns: []
}
class SellerWithdrawalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                refund_type: '',
                request_id: ''
            },
            columns: [],
            tableInfo:'',
            optionIds:[],
            optionItem:null,
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.buyerId) - parseFloat(b.buyerId),
            }, {
                title:'内部订单号',
                dataIndex: 'request_id',
                key: 'request_id',
                width: 120,
            },{
                title: '卖家 Id',
                dataIndex: 'seller_id',
                key: 'seller_id',
                width: 100, sorter: (a, b) => parseFloat(a.seller_id) - parseFloat(b.seller_id),
            }, {
                title:'买家 Id',
                dataIndex:'buyer_id',
                key:'buyer_id',
                width: 100, sorter: (a, b) => parseFloat(a.buyer_id) - parseFloat(b.buyer_id),
            },{
                title:'买家邮箱',
                dataIndex:'buyer_email',
                key:'buyer_email',
                width: 200,
            }, {
                title:'中介名称',
                dataIndex:'medium_name',
                key:'medium_name',
                width: 150,
            },{
                title: '创建时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 200,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '金额',
                dataIndex: 'price_rebate',
                key: 'price_rebate',
                width: 150, sorter: (a, b) => parseFloat(a.price_rebate) - parseFloat(b.price_rebate),
                render: (a, b) => {
                    return a + b['currency']
                }
            }, {
                title: '亚马逊订单号',
                dataIndex: 'amz_order_id',
                key: 'amz_order_id',
                width: 250
            },{
                title:'负责人',
                dataIndex:'person_in_charge',
                key:'person_in_charge',
                width: 120
            }, {
                title: '状态',
                dataIndex: 'refund_type',
                key: 'refund_type',
                width: 150,
                render: (a) => {
                    if (a === '0') {
                        return '等待审核'
                    } else if (a === '1') {
                        return '审核成功'
                    } else if (a === '2') {
                        return '审核失败'
                    }
                }
            },{
                title:'审核时间',
                dataIndex:'updatetime',
                key:'updatetime',
                width: 200,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            },{
                title: '图片',
                dataIndex: 'image_url',
                key: 'image_url',
                width: 200,
                render: (a, b) => {
                    return <a href={a} target="_blank">
                        <img src={a} alt="" style={{ maxWidth: 200 ,maxHeight:100}} />
                    </a>
                }
            }, {
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 100,
                fixed: 'right',
                render: (a,b) => {
                    if(b.refund_type==='0'){
                        return <div>
                        <Button size="small" onClick={()=>{
                            this.passWithdrawl(b)
                        }}>审核成功</Button>
                        <Button size="small" onClick={()=>{
                            this.refuseWithdrawl(b)
                        }}>审核失败</Button>
                        <Button size="small" onClick={()=>{
                            this.openRefundRemake(b)
                        }}>备注</Button>
                        </div>;
                    }
                    
                },
            }]
        })

        this.doQuery();
    }

    // 查看备注
    openRefundRemake=(item)=>{
        this.setState({
            optionItem:item,
            showRemakeStatus:true
        })
    }

    closeShowRemakeModal=()=>{
        this.setState({
            optionItem:null,
            showRemakeStatus:false
        })
    }
    
    // 批量返现成功    
    doPassWithdrawl=()=>{
        this.props.$request('/seller/clean-money',{id:this.state.optionIds,status:1},(res)=>{
            if(res['code']===200){
                message.success('操作成功！');
                this.doQuery();
            }
        })
    }
    passWithdrawl=(item)=>{
        Modal.confirm({
            title: '审核成功-确认退款？',
            icon: <ExclamationCircleOutlined />,
            content: <div>卖家Id：{item['seller_id']}<div>亚马逊订单号为：{item['amz_order_id']}</div><div>退款金额为：{item['price_rebate']} {item['currency']}</div></div>,
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk:()=>{
                this.props.$request( '/order/refund-success',{id:item['id']},(res)=>{
                    if(res['code']===200){
                        message.success('操作成功！');
                        this.doQuery();
                    }
                })
            }
        });
        
    }

    refuseWithdrawl=(item)=>{
        Modal.confirm({
            title: '审核失败-拒绝退款？',
            icon: <ExclamationCircleOutlined />,
            content: <div>卖家Id：{item['seller_id']}<div>亚马逊订单号为：{item['amz_order_id']}</div><div>退款金额为：{item['price_rebate']} {item['currency']}</div></div>,
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk:()=>{
                this.props.$request('/order/refund-fail',{id:item['id']},(res)=>{
                    if(res['code']===200){
                        message.success('操作成功！');
                        this.doQuery();
                    }
                })
            }
        });
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleIdChange = (e) => {
        this.setParam({ 'seller_id': e.target.value })
    }

    handleTypeChange = (e) => {
        this.setParam({ 'refund_type': e})
    }

    handleRequestIdChange= (e)=>{
        this.setParam({ 'request_id': e.target.value })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    setOptionIds=(ids)=>{
        this.setState({
            optionIds:ids
        })
    }

    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,tableInfo,optionIds,optionItem,showRemakeStatus } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">状态</span>
                    <Select value={param['refund_type']} onChange={this.handleTypeChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={''}>全部</Option>
                        <Option value={0}>等待审核</Option>
                        <Option value={1}>审核成功</Option>
                        <Option value={2}>审核失败</Option>
                    </Select>

                    <span className="label" style={{marginRight:10}}>内部订单号</span>
                    <Input value={param['request_id']} onChange={this.handleRequestIdChange} className="inline_el" style={{ width: 128 }}></Input>

                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    {/* <Button onClick={this.doPassWithdrawl} style={{ marginLeft: 20 }} disabled={optionIds.length===0}>批量返现成功</Button> */}

                    {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} setOptionIds={this.setOptionIds}/>

                {/* 查看备注 */}
                {showRemakeStatus?<ShowRemake item={optionItem} close={this.closeShowRemakeModal} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(SellerWithdrawalList);