import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';

const { Option } = Select;
const tconfig = {
    url: '/seller/refund-list',
    param: {},
    callback: null,
    columns: []
}
class SellerWithdrawalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                seller_id: '',
                type: 1,
            },
            columns: [],
            tableInfo:'',
            optionIds:[]
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.buyerId) - parseFloat(b.buyerId),
            }, {
                title: '卖家 Id',
                dataIndex: 'seller_uid',
                key: 'seller_uid',
                width: 100, sorter: (a, b) => parseFloat(a.buyerId) - parseFloat(b.buyerId),
            }, {
                title: '创建时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 150,
                sorter: (a, b) => new Date(a.createtime).getTime() - new Date(b.createtime).getTime(),
            }, {
                title: '金额',
                dataIndex: 'account',
                key: 'account',
                width: 150, sorter: (a, b) => parseFloat(a.account) - parseFloat(b.account),
                render: (a, b) => {
                    return a + b['currency']
                }
            }, {
                title: '操作订单号',
                dataIndex: 'transaction_id',
                key: 'transaction_id',
                width: 250
            }, {
                title: '状态',
                dataIndex: 'account_type',
                key: 'account_type',
                width: 150,
                render: (a) => {
                    if (a === '20') {
                        return '等待审核'
                    } else if (a === '21') {
                        return '已转账'
                    } else if (a === '22') {
                        return '审核失败'
                    }
                }
            },{
                title:'人民币金额',
                dataIndex:'account_cny',
                key:'account_cny',
                width: 120
            },{
                title:'汇率',
                dataIndex:'rate',
                key:'rate',
                width: 100
            },{
                title:'退款账户',
                dataIndex:'msg2',
                key:'msg2',
                width: 200
            },{
                title: '图片',
                dataIndex: 'image',
                key: 'image',
                width: 200,
                render: (a, b) => {
                    return <div>
                    {a.map((item,index)=>
                    <a href={item} target="_blank" style={{textDecoration:'underline',marginRight:10}}>
                       图{index+1}
                    </a>)}
                </div>
                }
            }, {
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 100,
                fixed: 'right',
                render: (a,b) => {
                    if(b.account_type==='20'){
                        return <div>
                            <Button size="small" onClick={()=>{
                                this.passWithdrawl(b)
                            }}>返现成功</Button>
                        <Button size="small" onClick={()=>{
                            this.refuseWithdrawl(b)
                        }}>审核失败</Button></div>;
                    }
                    
                },
            }]
        })

        this.doQuery();
    }

    passWithdrawl=(item)=>{
        this.props.$request('/seller/clean-money',{id:item['id'],status:1},(res)=>{
            if(res['code']===200){
                message.success('操作成功！');
                this.doQuery();
            }
        })
    }

    // 批量返现成功    
    doPassWithdrawl=()=>{
        this.props.$request('/seller/clean-money',{id:this.state.optionIds,status:1},(res)=>{
            if(res['code']===200){
                message.success('操作成功！');
                this.doQuery();
            }
        })
    }


    refuseWithdrawl=(item)=>{
        this.props.$request('/seller/clean-money',{id:item['id'],status:0},(res)=>{
            if(res['code']===200){
                message.success('操作成功！');
                this.doQuery();
            }
        })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    handleIdChange = (e) => {
        this.setParam({ 'seller_id': e.target.value })
    }

    handleTypeChange = (e) => {
        this.setParam({ 'type': e})
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    setOptionIds=(ids)=>{
        this.setState({
            optionIds:ids
        })
    }

    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,tableInfo,optionIds } = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">卖家Id</span>
                    <Input className="inline_el" value={param['seller_id']} onChange={this.handleIdChange} />
                    <span className="label">状态</span>
                    <Select value={param['type']} onChange={this.handleTypeChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={''}>全部</Option>
                        <Option value={1}>待返现</Option>
                        <Option value={2}>已退款</Option>
                        <Option value={3}>审核失败</Option>
                    </Select>

                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    <Button onClick={this.doPassWithdrawl} style={{ marginLeft: 20 }} disabled={optionIds.length===0}>批量返现成功</Button>

                    {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出提现明细</a>:''}
                    {!!tableInfo?<a href={tableInfo['alipay_url']} target="_blank" style={{marginLeft:20}}>导出支付宝提现</a>:''}
                    {!!tableInfo?<a href={tableInfo['wx_url']} target="_blank" style={{marginLeft:20}}>导出微信提现</a>:''}
                    {!!tableInfo?<a href={tableInfo['glocash_url']} target="_blank" style={{marginLeft:20}}>导出GLOCASH提现</a>:''}
                    {!!tableInfo?<a href={tableInfo['stripe_url']} target="_blank" style={{marginLeft:20}}>导出STRIPE提现</a>:''}
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false} rewSelect={true} setOptionIds={this.setOptionIds}/>
            </div>
        );
    }
}

export default BaseHoc(SellerWithdrawalList);