import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Form, Input, Button, Upload, Checkbox, PageHeader, Select, Radio, DatePicker, message, Modal, InputNumber, Statistic } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
class SellerActiveAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
        };
    }

    componentDidMount() {

    }
    onFinish = values => {

    }
    onFinishFailed = () => {

    }
    // 立即推广
    promoteNow = (data) => {
        this.props.$request('/product/set-product', data, (res) => {
            if (res['code'] === 200) {
                message.success('推广成功', 5);
                this.props.refresh();
            } else {
                message.error(res['message'])
            }
        })

    }
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    handleChange = ({ fileList }) => this.setState({ fileList });

    handleCancel = ()=>{
        this.setState({previewVisible:false})
    }

    handleActionChange =(e)=>{
        console.log(e)
    }
    handlePushTimeChange=(e,s)=>{

    }
    render() {
        const { fileList, previewVisible, previewTitle, previewImage } = this.state;
        return (
            <div className="content_tabs_box">
                <Form
                    {...layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    className="form_content"
                >


                    <Form.Item
                        label="活动名"
                        name="title"
                        rules={[{ required: true, message: '请输入活动名' }]}
                    >
                        <Input style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        label="活动图片"
                        name="img"
                        rules={[{ required: true }]}
                    >
                        <Upload
                            action="https://adminapi.pingamz.com/medium/rebate-success"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            headers={{'Authorization':window.sessionStorage.getItem('P_TOKEN')}}
                            accept="image/png, image/jpeg"
                        >
                            {fileList.length >= 1 ? null : (<div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>)}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="活动周期"
                        name="img"
                        rules={[{ required: true }]}
                    >
                       <RangePicker onChange={this.handlePushTimeChange} />
                    </Form.Item>

                    <Form.Item
                        label="触发动作"
                        name="actionTypee"
                        rules={[{ required: true }]}
                    >
                       <Select style={{ width: 300 }}  onChange={this.handleActionChange}>
                            <Option value={1}>订阅</Option>
                            <Option value={2}>跳转至套餐页面</Option>
                        </Select>
                    </Form.Item>




                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" >
                            发布
                        </Button>
                    </Form.Item>
                </Form>

                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                    centered
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

export default BaseHoc(SellerActiveAdd);