import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message,Modal,DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Assign from './assign';
import Examine from './examine';
import Close from './close';
import ExamineSettlement from './examineSettlement';

import SubmitDrewing from '../../specialOrderManage/mediumPurchasingOrderlist/submitDrawing';
import SubmitOrderId from '../../specialOrderManage/mediumPurchasingOrderlist/submitOrderId';

const { RangePicker } = DatePicker;
const { Option } = Select;
const tconfig = {
    url: '/medium/dg-order-list',
    param: {},
    callback: null,
    columns: []
}
class AdvancedPurchasingOrderlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                status: '',
                buyer_email:'',
                order_id:'',
                pay_type:'',
                country:'',
                admin_medium_id:'',
                pay_status:'',
                create_time_begin:'',
                create_time_end:'',
                is_settle:''
            },
            columns: [],
            tableInfo:'',
            optionItem:[],
            assignOrderStatus:false,
            examineOrderStatus:false,
            closeOrderModalStatus:false,
            mediumAccList:[{
                id:{'admin_medium_id':''},
                username:'无'
            }],
            examineSettlementStatus:false,

            submitDrawingStatus:false,
            submitOrderIdStatus:false
        };

    }

    componentDidMount() {
        this.setState({
            columns: [{
                title:'商品图',
                dataIndex: 'product_image',
                key: 'product_image',
                width: 140,

                fixed: 'left',
                render:(a,b)=>{
                    return <img src={a} style={{maxHeight:120,maxWidth:120}} alt="" />
                }
            },{
                title:'商品Title',
                dataIndex: 'product_title',
                key: 'product_title',
                width: 150,
                fixed: 'left',
                render:(a,b)=>{
                    return <div>
                        <a className="product_title" href={b['product_url']} target="_blank">{a}</a>
                        <div>站点：{b['product_source']+' ('+b['product_country']+')'}</div>
                        </div>
                }
            },{
                title:'内部订单号',
                dataIndex: 'order_id',
                key: 'order_id',
                width: 230,
                fixed: 'left',
            },{
                title: '状态',
                dataIndex: 'status_str',
                key: 'status_str',
                width: 150,
                render:(a,b)=>{
                    return <div>
                        {a}
                        {a==='订单关闭'?<div style={{color:'red',fontSize:'12'}}>({b['close_remark']})</div>:''}
                        {a==='买家已确认收货'?<div style={{color:'green',fontSize:'12'}}>({b['is_receive_type']})</div>:''}
                    </div> 
                }
            },{
                title: '结算状态',
                dataIndex: 'is_settle',
                key: 'is_settle',
                width: 150,
                render:(a,b)=>{
                    if(a==='0'){
                        return '未结算'
                    }else{
                        return '已结算'
                    }
                }
            },{
                title:'分配中介',
                dataIndex:'medium_name',
                key:'medium_name',
                width: 150,
                render:(a,b)=>{
                    return (!!a?a:b['status_str']==='未付款'?'':'未分配') + '('+b['allot_count']+')'
                }
            },{
                title:'买家邮箱',
                dataIndex:'buyer_email',
                key:'buyer_email',
                width: 250,
                render:(a,b)=>{
                    return <div>
                        <div>{b.buyer_id}</div>
                        <span style={{textDecoration:'underline',color:'#2196f3',cursor:'pointer'}} onClick={()=>this.doBuyerQuery(a)}>{a}</span>
                        <div style={{color:'red'}}>{b['is_fish']}</div>
                    </div>
                }
            },  {
                title:'收货信息',
                dataIndex:'region',
                key:'region',
                width:400,
                render: (a,b) => {
                    return <div>
                        名: {b['first_name']}<br/>
                        姓: {b['full_name']}<br/>
                        手机号: {b['phone']}<br/>
                        地址: {b['address']}<br/>
                        城市: {b['city']} 洲: {b['state']} 国家: {b['region']} <br/>
                        邮编: {b['zip_code']}
                    </div>
                }
            }, {
                title:'联系方式',
                dataIndex:'app_contact',
                key:'app_contact',
                width:200,
                render:(a,b)=>{
                    return !!a? (a+' - '+b['app_contact_info']):''
                }
            },{
                title:'备注',
                dataIndex:'remark',
                key:'remark',
                width:200,
            }, {
                title:'发货方',
                dataIndex:'fulfilled_by',
                key:'fulfilled_by',
                width:150,
                render:(a,b)=>{
                    return <div>
                        <div>物流方:{a}</div>
                        <div>卖方:{b['sold_by']}</div>
                        <div>品牌:{b['manufacturer']}</div>
                    </div>
                }
            },{
                title: '数量',
                dataIndex: 'count',
                key: 'count',
                width: 150
            },{
                title: <span title="ER总售价+支付手续费">金额明细</span>,
                dataIndex:'need_price',
                key:'need_price',
                width:300,
                render: (a, b) => {
                    return <div>
                        <div title="ER总售价+支付手续费">实收总金额: {a + b['currency']}</div>
                        <div title="亚马逊单个SKU商品原价">商品原价(单价): {b['amazon_price'] + b['currency']}</div>
                        <div title="产品原价*0.15">节省金额: {b['saving_off'] + b['currency']} (中介折扣:{b['discount_ratio']})</div>
                        <div title="商品原价x税率">总税费: {b['tax'] + b['currency']} <span title="取自API对应US各州数据">(税率: {b['address_tax']})</span></div>
                        <div>运费: {b['freight_price'] + b['currency']}</div>
                        <div title="ER总售价（不含手续费）">ER总售价（不含手续费）: {b['er_price'] + b['currency']}</div>
                        <div title="ER总售价*手续费比例">总手续费: {b['fee']||0 + b['currency']}</div>
                    </div>
                }
            },
            // {
            //     title: <span title="亚马逊单个SKU商品原价">商品原价(单价)</span>,
            //     dataIndex: 'amazon_price',
            //     key: 'amazon_price',
            //     width: 150, 
            //     // sorter: (a, b) => parseFloat(a.amazon_price) - parseFloat(b.amazon_price),
            //     render: (a, b) => {
            //         return a + b['currency']
            //     }
            // },
            {
                title: '支付方式',
                dataIndex: 'pay_type',
                key: 'pay_type',
                width: 200,
            },{
                title: '交易号',
                dataIndex: 'transaction_id',
                key: 'transaction_id',
                width: 200,
            },{
                title:'订单类型',
                dataIndex: 'product_source',
                key: 'product_source',
                width: 120,
            },{
                title:'购买方式',
                dataIndex:'order_type',
                key: 'order_type',
                width: 150,
            },
            // {
            //     title:'礼品卡价格',
            //     dataIndex:'card_price',
            //     key: 'card_price',
            //     width: 150,
            // },
            // {
            //     title:'商品单价',
            //     dataIndex:'unit_price',
            //     key:'unit_price',
            //     width: 150,
            //     // sorter: (a, b) => parseFloat(a.unit_price) - parseFloat(b.unit_price),
            //     render: (a, b) => {
            //         return a + b['currency']
            //     }
            // },
            // {
            //     title: <span title="产品原价*0.15">节省金额</span>,
            //     dataIndex: 'saving_off',
            //     key: 'saving_off',
            //     width: 150, 
            //     // sorter: (a, b) => parseFloat(a.saving_off) - parseFloat(b.saving_off),
            //     render: (a, b) => {
            //         return a + b['currency']
            //     }
            // },{
            //     title: <span title="商品原价x税率">总税费</span>,
            //     dataIndex: 'tax',
            //     key: 'tax',
            //     width: 120,
            //     render: (a, b) => {
            //         return a + b['currency']
            //     }
            // },{
            //     title: <span title="取自API对应US各州数据">税率</span>,
            //     dataIndex: 'address_tax',
            //     key: 'address_tax',
            //     width: 100
            // },{
            //     title: <span>运费</span>,
            //     dataIndex: 'freight_price',
            //     key: 'freight_price',
            //     width: 100,
            //     render: (a, b) => {
            //         return a + b['currency']
            //     }
            // },{
            //     title: <span title="ER总售价（不含手续费）">ER总售价（不含手续费）</span>,
            //     dataIndex: 'er_price',
            //     key: 'er_price',
            //     width: 150, 
            //     // sorter: (a, b) => parseFloat(a.er_price) - parseFloat(b.er_price),
            //     render: (a, b) => {
            //         return a + b['currency']
            //     }
            // },{
            //     title: <span title="ER总售价*手续费比例">总手续费</span>,
            //     dataIndex:'fee',
            //     key:'fee',
            //     width:150,
            //     render: (a, b) => {
            //         return !!a?a:0 + b['currency']
            //     }
            // },{
            //     title: '中介折扣',
            //     dataIndex: 'discount_ratio',
            //     key: 'discount_ratio',
            //     width: 150
            // },
            {
                title: '亚马逊/沃尔玛订单号',
                dataIndex: 'amz_order_id',
                key: 'amz_order_id',
                width: 250
            },{
                title:'创建日期',
                dataIndex:'create_time',
                key:'create_time',
                width: 200,
            },{
                title:'付款时间',
                dataIndex:'paid_time',
                key:'paid_time',
                width: 200,
            },{
                title:'分配时间',
                dataIndex:'change_medium_time',
                key:'change_medium_time',
                width: 200,
            },{
                title:'提交亚马逊/沃尔玛订单号时间',
                dataIndex:'submit_orderid_time',
                key:'submit_orderid_time',
                width: 300,
            }, {
                title:'提交发货图时间',
                dataIndex:'delivery_time',
                key:'delivery_time',
                width: 200,
            },{
                title:'审核时间',
                dataIndex:'audit_time',
                key:'audit_time',
                width: 150,
            },{
                title:'审核完成已结算时间',
                dataIndex:'audit_time',
                key:'audit_time',
                width: 300,
            },{
                title: '中介-运单号/预计到货时间',
                dataIndex: 'arrival_time_medium',
                key: 'arrival_time_medium',
                width: 200
            },{
                title: '运单号/预计到货时间',
                dataIndex: 'arrival_time',
                key: 'arrival_time',
                width: 200
            },{
                title: '发货图',
                dataIndex: 'amz_order_image',
                key: 'amz_order_image',
                width: 200,
                render: (a, b) => {
                    return <a href={a} target="_blank">
                        <img src={a} alt="" style={{ maxWidth: 200 ,maxHeight:100}} />
                    </a>
                }
            },{
                title: '发送到货通知时间',
                dataIndex: 'arrival_order_mail_time',
                key: 'arrival_order_mail_time',
                width: 200
                
            },{
                title: '确认时间',
                dataIndex: 'receive_time',
                key: 'receive_time',
                width: 200
            },{
                title: '用户 IP',
                dataIndex: 'ip',
                key: 'ip',
                width: 200,
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 120,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [];

                    // if(b['status_str']!=='未付款'&&!!b['medium_name']){
                        // 已关闭
                        if(b.order_status!=='5'){
                            btns.push(<Button size="small" style={{marginBottom:20}} onClick={()=>{
                                this.openCloseOrderModal(b)
                            }}>关闭订单</Button>)
                        }
                        if(b.order_status!=='0'&&b.order_status!=='4'&&b.order_status!=='5'){
                            btns.push(<Button size="small" onClick={()=>{
                                this.openAssignOrderModal(b)
                            }}>分配</Button>)
                        }
                        // if(b.order_status==='1'){
                        //     btns.push(<Button size="small" onClick={()=>{
                        //         this.closeOrder(b)
                        //     }}>关闭订单</Button>)
                        // }
                        if(b.order_status==='3'){
                            btns.push(<Button size="small" onClick={()=>{
                                this.openExamineOrderModal(b)
                            }}>审核通过</Button>)
                            btns.push(<Button size="small" onClick={()=>{
                                this.examineFail(b)
                            }}>审核失败</Button>)
                        }


                        if(b.order_status==='9'||b.order_status==='4'){
                            if(b['is_settle']==='0'){
                                btns.push(<Button size="small" onClick={()=>{
                                    this.openExamineSettlementModal(b)
                                }}>结算</Button>)
                            }
                        }

                        if(b.order_status==='4'){
                            btns.push(<Button size="small" onClick={()=>{
                                this.sendArrivalNoticeEmail(b)
                            }}>到货通知</Button>)
                        }

                        if(b.order_status==='1'){
                            btns.push(<Button size="small" onClick={()=>{
                                this.openSubmitOrderIdModal(b)
                            }}>提交订单号</Button>)
                        }else if(b.order_status==='2'){
                            btns.push(<Button size="small" onClick={()=>{
                                this.openSubmitDrawingModal(b)
                            }}>上传发货信息</Button>)
                        }  
                    // }else{
                    //     if(b.order_status!=='5'){
                    //         btns.push(<Button size="small" style={{marginBottom:20}} onClick={()=>{
                    //             this.openCloseOrderModal(b)
                    //         }}>关闭订单</Button>)
                    //     }
                    //     if(b.order_status!=='0'&&b.order_status!=='4'&&b.order_status!=='5'){
                    //         btns.push(<Button size="small" onClick={()=>{
                    //             this.openAssignOrderModal(b)
                    //         }}>分配</Button>)
                    //     }
                    // }
                    

                    return btns;
                },
            }]
        })
        this.loadMediumAccList();
        this.doQuery();
    }

    handleCreateTimeChange = (e, str) => {
        this.setParam({ 'create_time_begin': str[0], 'create_time_end': str[1] })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleStatusChange = (e) => {
        this.setParam({ 'status': e})
    }
    handlePayStatusChange=(e)=>{
        this.setParam({ 'pay_status': e})
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    closeAssignOrderModal=()=>{
        this.setState({
            assignOrderStatus: false,
            optionItem:null
        })
    }
    // 分配
    openAssignOrderModal=(item)=>{
        this.setState({
            assignOrderStatus: true,
            optionItem: item
        })
    }
    
    closeExamineOrderModal=()=>{
        this.setState({
            examineOrderStatus: false,
            optionItem:null
        })
    }

    // 审核
    openExamineOrderModal=(item)=>{
        this.setState({
            examineOrderStatus: true,
            optionItem: item
        })
    }

    // 审核失败
    examineFail=(item)=>{
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '拒绝此发货图?',
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk() {
                me.props.$request('/medium/audit-fail-dg-order',{id:item['id']},(res)=>{
                  if(res['code']===200){
                      me.doQuery();
                  }
                })
            }
        });
    }
    // 打开审核结算结算
    openExamineSettlementModal=(item)=>{
        this.setState({
            examineSettlementStatus: true,
            optionItem: item
        })
        // let me = this;
        // Modal.confirm({
        //     title: '提示',
        //     icon: <ExclamationCircleOutlined />,
        //     content: '',
        //     okText: '确认',
        //     cancelText: '取消',
        //     centered:true,
        //     onOk() {
        //         me.props.$request('',{id:item['id']},(res)=>{
        //           if(res['code']===200){
        //               me.doQuery();
        //           }
        //         })
        //     }
        // });
    }
    // 关闭审核结算
    closeExamineSettlementModal=()=>{
        this.setState({
            examineSettlementStatus: false,
            optionItem: null
        })
    }

    // 关闭订单
    openCloseOrderModal=(item)=>{
        // let me = this;
        // Modal.confirm({
        //     title: '提示',
        //     icon: <ExclamationCircleOutlined />,
        //     content: '确认关闭此订单?',
        //     okText: '确认',
        //     cancelText: '取消',
        //     centered:true,
        //     onOk() {
        //         me.props.$request('/medium/close-dg-order',{id:item['id']},(res)=>{
        //           if(res['code']===200){
        //               me.doQuery();
        //           }
        //         })
        //     }
        // });
        this.setState({
            closeOrderModalStatus: true,
            optionItem: item
        })
    }

    closeCloseOrderModal=()=>{
        this.setState({
            closeOrderModalStatus: false,
            optionItem: null
        })
    }

    // 购买方式
    handleOrdertypeChange=(e)=>{
        this.setParam({ 'order_type': e})
    }
    // 支付方式
    handlePayTypeChange=(e)=>{
        this.setParam({ 'pay_type': e})
    }

    //结算状态
    handleSettleChange=(e)=>{
        this.setParam({ 'is_settle': e})
    }
    // 买家账号
    handleBuyerEmailChange=(e)=>{
        this.setParam({ 'buyer_email': e.target.value})
    }
    // 代购内部订单号
    handleOrderIdChange=(e)=>{
        this.setParam({ 'order_id': e.target.value})
    }
    // 站点切换
    handleCountryChange=(e)=>{
        this.setParam({ 'country': e})
    }

    // 加载中介账号
    loadMediumAccList=()=>{
        this.props.$request('/medium/get-dg-medium-list',{},res=>{
            if(res['code']===200){
                const { mediumAccList } = this.state;
                this.setState({
                    mediumAccList: mediumAccList.concat(res['data']['list'])
                })
            }
        })
    }
    // 中介账号
    handleMediumAccChange=(e)=>{
        this.setParam({ 'admin_medium_id': e})
    }


    closeSubmitDrawingModal=()=>{
        this.setState({
            submitDrawingStatus: false,
            optionItem:null
        })
    }
    // 提交发货图
    openSubmitDrawingModal=(item)=>{
        this.setState({
            submitDrawingStatus: true,
            optionItem: item
        })
    }
    
    closeSubmitOrderIdModal=()=>{
        this.setState({
            submitOrderIdStatus: false,
            optionItem:null
        })
    }

    // 提交亚马逊订单号
    openSubmitOrderIdModal=(item)=>{
        this.setState({
            submitOrderIdStatus: true,
            optionItem: item
        })
    }
    // 点击买家邮箱快速查询
    doBuyerQuery=(value)=>{
        this.setState({
            param: {
                status: '',
                buyer_email: value,
                order_id: '',
                pay_type: '',
                country: '',
                admin_medium_id: ''
            },
        },()=>{
            this.doQuery();
        })
    }

    // 到货通知
    sendArrivalNoticeEmail=(item)=>{
        let me = this;
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定发送到货通知?',
            okText: '确认',
            cancelText: '取消',
            centered:true,
            onOk() {
                me.props.$request('/medium/arrival-order',{id:item['id']},(res)=>{
                  if(res['code']===200){
                      message.success('发送成功！')
                      me.doQuery();
                  }
                })
            }
        });
    }


    // 0-提交下单信息 1-已完成付款 ，等待提交亚马逊订单号 2-已提交亚马逊订单号 3-已提交发货图 4-发货图审核成功 5-订单退款
    render() {
        const { param, config, columns,optionItem, assignOrderStatus, examineOrderStatus,closeOrderModalStatus, tableInfo ,mediumAccList ,examineSettlementStatus,submitDrawingStatus,submitOrderIdStatus} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">

                    <span className="label">中介账号</span>
                    <Select value={param['admin_medium_id']} onChange={this.handleMediumAccChange} className="inline_el" style={{ width: 128 }}>
                        {mediumAccList.map((item,index)=>
                            <Option value={item['id']['admin_medium_id']} key={index}>{item['username']}</Option>
                        )}
                    </Select>

                    <span className="label">订单状态</span>
                    <Select value={param['status']} onChange={this.handleStatusChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={''}>全部</Option>
                        {/* <Option value={0}>未付款</Option> */}
                        <Option value={'1-1'}>已付款未分配</Option>
                        <Option value={1}>待提交订单号</Option>
                        <Option value={2}>待提交发货图</Option>
                        <Option value={3}>待审核</Option>
                        <Option value={4}>审核完成</Option>
                        {/* <Option value={'4-1'}>审核完成-已结算</Option>  */}
                        <Option value={9}>买家已确认收货</Option>
                        <Option value={5}>已关闭</Option>
                        <Option value={8}>支付渠道退款</Option>
                    </Select>

                    <span className="label">支付状态</span>
                    <Select value={param['pay_status']} onChange={this.handlePayStatusChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={''}>全部</Option>
                        <Option value={'0'}>未付款</Option>
                        <Option value={'1'}>已付款</Option>
                    </Select>

                    <span className="label">结算状态</span>
                    <Select value={param['is_settle']} onChange={this.handleSettleChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={''}>全部</Option>
                        <Option value={'0'}>未结算</Option>
                        <Option value={'1'}>已结算</Option>
                    </Select>


                    <span className="label">下单日期</span>
                    <RangePicker className="add_margin" onChange={this.handleCreateTimeChange} />

                    <span className="label">站点</span>
                    <Select value={param['country']} onChange={this.handleCountryChange} className="inline_el" style={{ width: 80 }}>
                        <Option value={''}>全部</Option>
                        <Option value={'US'}>US</Option>
                        <Option value={'UK'}>UK</Option>
                        <Option value={'DE'}>DE</Option>
                    </Select>

                    <span className="label">支付方式</span>
                    <Select value={param['pay_type']} onChange={this.handlePayTypeChange} className="inline_el" style={{ width: 128 }}>
                        <Option value={''}>全部</Option>
                        <Option value={1}>余额</Option>
                        <Option value={2}>信用卡</Option>
                        <Option value={3}>Paypal</Option>
                    </Select>

                    <span className="label">买家邮箱</span>
                    <Input value={param['buyer_email']} onChange={this.handleBuyerEmailChange} className="inline_el" style={{ width: 128 }}></Input>

                    <span className="label">代购内部订单号</span>
                    <Input value={param['order_id']} onChange={this.handleOrderIdChange} className="inline_el" style={{ width: 128 }}></Input>

                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                    {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>

                {assignOrderStatus?<Assign item={optionItem} close={this.closeAssignOrderModal} reload={this.doQuery}/>:''}
                {examineOrderStatus?<Examine item={optionItem} close={this.closeExamineOrderModal} reload={this.doQuery}/>:''}
                {closeOrderModalStatus?<Close item={optionItem} close={this.closeCloseOrderModal} reload={this.doQuery}/>:''}
                {examineSettlementStatus?<ExamineSettlement item={optionItem} close={this.closeExamineSettlementModal} reload={this.doQuery}/>:''}
                {submitDrawingStatus?<SubmitDrewing item={optionItem} close={this.closeSubmitDrawingModal} reload={this.doQuery}/>:''}
                {submitOrderIdStatus?<SubmitOrderId item={optionItem} close={this.closeSubmitOrderIdModal} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(AdvancedPurchasingOrderlist);