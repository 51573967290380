import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Input, Button, Select, message,Modal,DatePicker } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const { Option } = Select;
const tconfig = {
    url: '/medium/capital-list',
    param: {},
    callback: null,
    columns: []
}
class MediumCapitalRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                admin_id: '',
                submitimage_time_begin:'',
                submitimage_time_end:'',
                settle_time_begin:'',
                settle_time_end:'',
                refund_time_begin:'',
                refund_time_end:''
            },
            columns: [],
            tableInfo:'',
            optionItem:[],
            adminAccountList:[]
        };

    }
   
    componentDidMount() {
        this.loadAdminAccount();
        this.setState({
            columns: [{
                title:'内部订单号',
                dataIndex: 'request_id',
                key: 'request_id',
                width: 140
            },{
                title:'亚马逊订单号',
                dataIndex: 'amz_order_id',
                key: 'amz_order_id',
                width: 150
            },{
                title:'卖家ID',
                dataIndex: 'seller_id',
                key: 'seller_id',
                width: 100,
            },{
                title:'支付宝姓名',
                dataIndex:'alipay_name',
                key:'alipay_name',
                width: 200
            },{
                title:'当前汇率',
                dataIndex:'exchange_rate',
                key:'exchange_rate',
                width: 100
            }, {
                title: '站点',
                dataIndex: 'country_code',
                key: 'country_code',
                width: 100
            },{
                title: '返现金额',
                dataIndex: 'price_rebate',
                key: 'price_rebate',
                width: 150,
                sorter: (a, b) => parseFloat(a.price_rebate) - parseFloat(b.price_rebate),
                render: (a, b) => {
                    return a+b['currency']
                }
            },{
                title: '返现金额CNY',
                dataIndex: 'price_rebate_cny',
                key: 'price_rebate_cny',
                width: 150,
                
            },{
                title: '结算金额',
                dataIndex: 'settle_accounts',
                key: 'settle_accounts',
                width: 150,
                render: (a, b) => {
                    return a+b['currency']
                }
            }, {
                title: '结算金额CNY',
                dataIndex: 'settle_accounts_cny',
                key: 'settle_accounts_cny',
                width: 250
            },{
                title:'利润',
                dataIndex:'profit',
                key:'profit',
                width: 200,
                render: (a, b) => {
                    return a+b['currency']
                }
            },{
                title:'利润CNY',
                dataIndex:'profit_cny',
                key:'profit_cny',
                width: 200,
            },{
                title:'折扣率',
                dataIndex:'discount_rate',
                key:'discount_rate',
                width: 200,
            },{
                title:'提交图片时间',
                dataIndex:'submit_order_image_time',
                key:'submit_order_image_time',
                width: 200,
            },{
                title:'订单结算时间',
                dataIndex:'settle_order_time',
                key:'settle_order_time',
                width: 200,
            }, {
                title:'退款时间',
                dataIndex:'refund_order_time',
                key:'refund_order_time',
                width: 200,
            }]
        })

        this.doQuery();
    }


    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    // 以上基础


    // 加载中介
    loadAdminAccount = () => {
        this.props.$request('/medium/get-admin-account', {}, (res) => {
            if (res['code'] === 200) {
                this.setState({
                    adminAccountList: res['data']['data']
                })
            }
        },()=>{},false)
    }
    // submitimage_time_begin,submitimage_time_end,settle_time_begin,settle_time_end,refund_time_begin,refund_time_end
    handleAdminIdChange = (e) => {
        this.setParam({ 'admin_id': e })
    }
    handleSubmitPicTimeChange = (e, str) => {
        this.setParam({ 'submitimage_time_begin': str[0], 'submitimage_time_end': str[1] })
    }
    handleSettlementTimeChange = (e, str) => {
        this.setParam({ 'settle_time_begin': str[0], 'settle_time_end': str[1] })
    }
    handleRefundTimeChange = (e, str) => {
        this.setParam({ 'refund_time_begin': str[0], 'refund_time_end': str[1] })
    }
    

    render() {
        const { param, config, columns,tableInfo,adminAccountList} = this.state;
        config['columns'] = columns;
        
        return (
            <div className="content_tabs_box">
                <div className="tool_bar">
                    <span className="label">中介账号</span>
                    <Select value={param['admin_id']} onChange={this.handleAdminIdChange} className="inline_el" style={{ width: 120 }}>
                    {adminAccountList.map((item,index) => 
                        <Option key={index} value={item['id']} key={item['key']}>{item['name']}</Option>
                    )}
                    </Select>
                    <span className="label">提交图片时间</span>
                    <RangePicker className="add_margin" onChange={this.handleSubmitPicTimeChange} />


                    <span className="label">结算时间</span>
                    <RangePicker className="add_margin" onChange={this.handleSettlementTimeChange} />


                    <span className="label">退款时间</span>
                    <RangePicker className="add_margin" onChange={this.handleRefundTimeChange} />

                    <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>


                    {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
            </div>
        );
    }
}

export default BaseHoc(MediumCapitalRecord);