import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { DatePicker, Select, Input, Button, message,Modal } from 'antd';
import ProTable from '../../../widget/proTable';
import '../../../base/index.scss';
import EditProfile from './editProfile';
import BonusPoints from './bonusPoints';
import DisabledAcc from './disabledAcc';
import AddStatement from './addStatement';

const { RangePicker } = DatePicker;
const { Option } = Select;

const tconfig = {
    url: '/buyer/list',
    param: {},
    callback: null,
    columns: []
}
class BuyerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: tconfig,
            param: {
                buyer_id: '',
                reg_status: '',
                ga: '',
                begin: '',
                end: ''
            },
            columns: [],
            editProfile:false,
            addPoint:false,
            disabledAcc:false,
            optionItem:'',
            tableInfo:'',
            addStatement:false
            
        };

    }

    // componentWillMount() {
    //     console.log('self will mount')
    // }

    componentDidMount() {
        console.log('self did mount');
        this.setState({
            columns: [{
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                fixed: 'left',
                sorter: (a, b) => parseFloat(a.id) - parseFloat(b.id),
            }, {
                title: '邮箱',
                dataIndex: 'email',
                key: 'email',
                width: 200,
                fixed: 'left'
            }, {
                title: '用户名',
                dataIndex: 'username',
                key: 'username',
                width: 200,
                render:(a,b)=>{
                    return <div>
                        <div>{a}</div>
                        <div>whatApp: {b['whatApp']}</div>
                    </div>
                }
            }, {
                title: '注册时间',
                dataIndex: 'createtime',
                width: 200,
                key: 'createtime'
            }, {
                title: '注册 IP',
                dataIndex: 'reg_ip',
                key: 'reg_ip',
                width: 200,
            }, {
                title: '最后登录IP',
                dataIndex: 'last_login_ip',
                key: 'last_login_ip',
                width: 200,
            },{
                title:'来源',
                dataIndex:'sid',
                key:'sid',
                width:150
            }, {
                title: '订单数',
                dataIndex: 'rebate_count',
                key: 'rebate_count',
                width: 150,
                render: (a, b) => {
                    return <div>
                        <div> 申请数：{b['request_count']}</div>
                        <div> 返现数：{b['rebate_count']}</div>
                        <div> 完成订单数：{b['confirm_count']}</div>
                        <div> 拒绝订单数：{b['decline_count']}</div>
                        <div> 留评数：{b['review_count']}</div>
                        <div> 退款订单数：{b['refund_count']}</div>
                        <div> 关闭订单数：{b['close_count']}</div>
                    </div>
                }
            },{
                title:'余额',
                dataIndex:'account_usd',
                key:'account_usd',
                width:150,
                render: (a, b) => {
                    return <div>
                        <div> {b['account_usd']} USD</div>
                        <div> {b['account_gbp']} GBP</div>
                        <div> {b['account_eur']} EUR</div>
                        <div> {b['account_cad']} CAD</div>
                        <div> {b['account_jpy']} JPY</div>
                    </div>
                }
            }, {
                title: '注册时间',
                dataIndex: 'createtime',
                key: 'createtime',
                width: 200,
            }, {
                title: '注册国家',
                dataIndex: 'reg_country_code',
                key: 'reg_country_code',
                width: 150,
            }, {
                title: '到期时间',
                dataIndex: 'subscribe_period_end',
                key: 'subscribe_period_end',
                width: 200,
            }, {
                title: '等级',
                dataIndex: 'level',
                key: 'level',
                width: 80,
                sorter: (a, b) => parseFloat(a.level) - parseFloat(b.level),
            }, {
                title: '亚马逊 Profile',
                dataIndex: 'profile_url',
                key: 'profile_url',
                width: 200,
            }, {
                title: '最后登录国家',
                dataIndex: 'last_login_country_code',
                key: 'last_login_country_code',
                width: 150,
            }, {
                title: '收款方式',
                dataIndex: 'pay_account_type',
                key: 'pay_account_type',
                width: 200,
                render: (a, b) => {
                    return <div>{a}<br />{b['paypal_account']}</div>
                }
            }, {
                title: '积分',
                dataIndex: 'point',
                key: 'point',
                width: 100,
                sorter: (a, b) => parseFloat(a.point) - parseFloat(b.point),
            }, {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (a) => {
                    if (a === '1') {
                        return '已激活'
                    } else if (a === '0') {
                        return '未激活'
                    }
                }
            }, {
                title: '邀请人 Id',
                dataIndex: 'invitation_uid',
                key: 'invitation_uid',
                width: 100
            }, {
                title: '是否为中介',
                dataIndex: 'is_medium',
                key: 'is_medium',
                width: 120,
                render:(a)=>{
                    if(a==='0'){
                        return '否'
                    }else if(a==='1'){
                        return '是'
                    }
                }
            }, {
                title:'账号状态',
                dataIndex:'credit',
                key:'credit',
                width:150
            },{
                title:'备注',
                dataIndex:'remark',
                key:'remark',
                width:150
            },{
                title: '操作',
                dataIndex: 'option',
                key: 'buyer',
                width: 150,
                fixed: 'right',
                render: (a,b) => {
                    let btns = [
                        <Button size="small" onClick={()=>{
                            this.openEditProfileModal(b)
                        }}>修改 profile</Button>,
                        // <Button size="small" onClick={()=>{
                        //     this.openBonusPointsModal(b)
                        // }}>赠送积分</Button>,
                        <Button size="small" onClick={()=>{
                            this.openDisabledAccModal(b)
                        }}>拉黑</Button>,

                        b['credit']==='限制提现'?<Button size="small" onClick={()=>{
                            this.recoveryRefund(b)
                        }}>恢复提现</Button>:'',

                        <Button size="small" onClick={()=>{
                            this.openHandlerRebateBank(b)
                        }}>{b['is_open_bank']==='0'?'开启银行卡返现':'关闭银行卡返现'}</Button>,
                        b['is_open_buyer_capital']?<Button size="small" onClick={()=>{
                            this.openHandlerAddStatement(b)
                        }}>增加流水</Button>:''
                    ]
                    return btns;
                },
            }],

        })
        this.doQuery();
    }

    listenerProps = () => {
        // const { isLogin } = store.getState();
        // this.setState({
        //     isLogin: isLogin
        // })
    }

    setParam = (obj) => {
        const { param } = this.state;
        for (let key in obj) {
            param[key] = obj[key];
        }
        this.setState({
            param: param
        })
    }
    // 恢复提现
    recoveryRefund=(item)=>{
        let me = this;
        Modal.confirm({
            title: '提示',
            content: '恢复提现？',
            centered:true,
            onOk() {
                me.props.$request('/buyer/recover-return-cash',{buyer_id:item['id']},(res)=>{
                  if(res['code']===200){
                      me.doQuery();
                  }
              })
            }
        });
    }
    openHandlerRebateBank= item =>{
        this.props.$request('/buyer/open-close-bank',{buyer_id:item['id']},(res)=>{
            if(res['code']===200){
                message.success('操作成功');
                this.doQuery();
            }
        })
    }
    handleIdChange = (e) => {
        this.setParam({ 'buyer_id': e.target.value })
    }

    handleEmailChange = e=>{
        this.setParam({ 'email': e.target.value })
    }

    handleProfileChange=e=>{
        this.setParam({ 'profile': e.target.value })
    }

    handleProfileCountryChange=e=>{
        this.setParam({ 'profile_country': e })
    }

    handleGaChange = (e) => {
        this.setParam({ 'ga': e.target.value })
    }

    handleRegTimeChange = (e, str) => {
        this.setParam({ 'begin': str[0], 'end': str[1] })
    }

    handleRegStatusChange = (e) => {
        this.setParam({ 'reg_status': e })
    }

    handleQuery = (cb) => {
        this.childEvent = cb;
    }

    //打开修改 profile
    openEditProfileModal=(item)=>{
        this.setState({
            optionItem:item,
            editProfile:true
        })
    }
    // 关闭修改 profile
    closeEditProfileModal=()=>{
        this.setState({
            optionItem:null,
            editProfile:false
        })  
    }
    //打开修改 profile
    openBonusPointsModal=(item)=>{
        this.setState({
            optionItem:item,
            addPoint:true
        })
    }
    // 关闭修改 profile
    closeBonusPointsModal=()=>{
        this.setState({
            optionItem:null,
            addPoint:false
        })  
    }
    // 开启禁用账户
    openDisabledAccModal=(item)=>{
        this.setState({
            optionItem:item,
            disabledAcc:true
        })  
    }
    // 关闭禁用账户
    closeDisabledAccModal=()=>{
        this.setState({
            optionItem:null,
            disabledAcc:false
        })
    }
    // 增加流水
    openHandlerAddStatement=(item)=>{
        this.setState({
            optionItem:item,
            addStatement:true
        })
    }

    // 关闭禁用账户
    closeHandlerAddStatement=()=>{
        this.setState({
            optionItem:null,
            addStatement:false
        })
    }
    
    
    doQuery = () => {
        const { config, param } = this.state;
        config['param'] = param;
        this.setState({
            config: config
        }, () => {
            this.childEvent(1,20,(data)=>{
                this.setState({
                    tableInfo:data
                })
            });
        })
    }

    render() {
        const { param, config, columns,editProfile,optionItem,tableInfo,addPoint,disabledAcc ,addStatement} = this.state;
        config['columns'] = columns;
        return (
            <div className="content_tabs_box">
                <div>
                    {/* <Button>刷新</Button> */}
                    <div className="tool_bar">
                        <span className="label">买家Id</span>
                        <Input className="inline_el" value={param['id']} onChange={this.handleIdChange} />

                        <span className="label">买家邮箱</span>
                        <Input className="inline_el" value={param['email']} onChange={this.handleEmailChange} />

                        <span className="label">profile</span>
                        <Input className="inline_el" value={param['profile']} onChange={this.handleProfileChange} />

                        <span className="label">profile站点</span>
                        <Select value={param['profile_country']} onChange={this.handleProfileCountryChange} className="inline_el">
                            <Option value=''>全部</Option>
                            <Option value={'US'}>US</Option>
                            <Option value={'DE'}>DE</Option>
                            <Option value={'FR'}>FR</Option>
                            <Option value={'UK'}>UK</Option>
                            <Option value={'CA'}>CA</Option>
                            <Option value={'JP'}>JP</Option>
                        </Select>
                    </div>
                    <div className="tool_bar">

                        <span className="label">注册时间</span>
                        <RangePicker className="add_margin" onChange={this.handleRegTimeChange} />

                        <span className="label">推广码</span>
                        <Input className="inline_el" value={param['ga']} onChange={this.handleGaChange} />

                        <span className="label">激活状态</span>
                        <Select value={param['reg_status']} onChange={this.handleRegStatusChange} className="inline_el">
                            <Option value=''>全部</Option>
                            <Option value={0}>未激活</Option>
                            <Option value={1}>已激活</Option>
                        </Select>

                        <Button type="primary" style={{ marginLeft: 20 }} onClick={this.doQuery}>查询</Button>

                        {!!tableInfo?<a href={tableInfo['url']} target="_blank" style={{marginLeft:20}}>导出Excel</a>:''}
                    </div>
                </div>
                <ProTable {...config} handleQuery={this.handleQuery} autoLoad={false}/>
                {editProfile?<EditProfile item={optionItem} close={this.closeEditProfileModal} reload={this.doQuery}/>:''}
                {addPoint?<BonusPoints item={optionItem} close={this.closeBonusPointsModal} reload={this.doQuery}/>:''}
                {disabledAcc?<DisabledAcc item={optionItem} close={this.closeDisabledAccModal} reload={this.doQuery}/>:''}
                {addStatement?<AddStatement item={optionItem} close={this.closeHandlerAddStatement} reload={this.doQuery}/>:''}
            </div>
        );
    }
}

export default BaseHoc(BuyerList);