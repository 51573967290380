import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Select, Button, message ,Modal ,Row,Col,Input,DatePicker,Upload} from 'antd';
import { SketchPicker } from 'react-color';
import { UploadOutlined } from '@ant-design/icons';
import 'braft-editor/dist/index.css';
import '../../../base/index.scss';
import LogoRed from '../../../../images/buyer/logoNew.svg';
import LogoWhite from '../../../../images/buyer/logoNew_w.svg';
import moment from 'moment';
import qs from 'qs';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

class BuyerNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            stations: [],
            dev:'',
            color:'#fff',
            showColorPicker:false,
            fileList:[],
            image:'', //预览图片地址
            theme: '1',  //主题 1深色，2亮色,
            url:'',
            isStat: '0',
            isOnly: '0',
            needLogin: '0',
            clickAction: '0',
            begin: '',
            end: '',
            stat:{
                cate:'',
                type:'',
                action:'',
                remark:''
            }
        };


    }

    componentDidMount() {
        const { item } = this.props;
        if(!!item){
            if(!!item['stat']){
                let arrs = item['stat'].split('&');
                item['stat'] = {};
                for(let i =0;i<arrs.length;i++){
                    item['stat'][(arrs[i].split('='))[0]] = (arrs[i].split('='))[1];
                }
            }
            this.setState(item);
        }
    }

    handleChange = (editorState) => {
        
    }

    // 站点
    handleStationsChange = (e) => {
        console.log(e)
        this.setState({
            stations: e
        })
    }

    // 设备
    handleDevChange= (e)=>{
        this.setState({
            dev: e,
            fileList: [],
            image:''
        })
    }
    handleColorChange=(color)=>{
        this.setState({
            color: color['hex']
        })
    }

    // 打开或关闭调色板
    showColorPicker=()=>{
        this.setState({
            showColorPicker: true
        })
    }
    hideColorPicker=()=>{
        this.setState({
            showColorPicker: false
        })
    }

    // 主题
    handleLogoThemeChange=(value)=>{
        this.setState({
            theme: value
        })
    }

    // 跳转地址
    handleUrlChange=(e)=>{
        this.setState({
            url: e.target.value
        })
    }
    // 是否需要统计
    handleIsStatChange=(v)=>{
        this.setState({
            isStat: v
        })
    }
    // 统计的
    handleStatCateChange=(e)=>{
        let { stat } = this.state;
        stat['cate'] = e.target.value;
        this.setState({
            stat: stat
        })
    }

    handleStatTypeChange=(e)=>{
        let { stat } = this.state;
        stat['type'] = e.target.value;
        this.setState({
            stat: stat
        })
    }

    handleStatActionChange=(e)=>{
        let { stat } = this.state;
        stat['action'] = e.target.value;
        this.setState({
            stat: stat
        })
    }

    handleStatRmkChange=(e)=>{
        let { stat } = this.state;
        stat['remark'] = e.target.value;
        this.setState({
            stat: stat
        })
    }

    // banner 唯一
    handleOnlyChange=(e)=>{
        this.setState({
            isOnly: e
        })
    }

    // 点击动作
    handleClickActionChange=(e)=>{
        this.setState({
            clickAction: e
        })
    }

    // 上架周期
    handleWeekChange=(e, str) => {
        this.setState({
            begin:str[0],
            end: str[1] 
        })
    }

    // 是否需要登录展示
    handleNeedLoginChange=(e)=>{
        this.setState({
            needLogin: e
        })
    }




    // 完成提交
    submit = () => {
        const {id,fileList,stations, dev ,color,theme,url,isStat,isOnly,needLogin,clickAction,begin,end,stat } = this.state;
        const formData = new FormData();
        let newStat = qs.stringify(stat);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        formData.append('id',id);            //有 id 则为修改，数据需要回显，无 id 则新增
        formData.append('stations',stations);      //站点 us、uk、fr、ca、de、jp
        formData.append('dev',dev);           //终端 pc、phone
        formData.append('color',color);         //phone banner 背景颜色  pc 无
        formData.append('theme',theme);         //phone banner 顶部 logo 颜色
        formData.append('isOnly',isOnly);        //是否唯一展示，否 那么会按上架最新时间放最前轮播
        formData.append('needLogin',needLogin);     //是否需要登录才展示   
        formData.append('clickAction',clickAction);   //banner 点击的动作
        formData.append('url',url);           //点击跳转地址，clickAction值为2、3的时候才有
        formData.append('begin',begin);         //banner 上架时间
        formData.append('end',end);           //banner 下架时间
        formData.append('isStat',isStat);        //是否需要统计
        formData.append('stat',newStat);          //统计内容
        // 将图片插入
        this.props.$upload('/config/set-buyer-banner',formData,(res) => {
            if (res['code'] === 200) {
                message.success('操作成功！');
                this.props.close();
            }else{
                message.error(res['message']);
            }
        })
    }

    render() {
        const { dev, stations ,color ,showColorPicker ,theme,url,isStat,stat,isOnly,needLogin,clickAction,begin,end,fileList,image} = this.state;
        const labelSpan = 8;
        const inputSpan = 16;
        const baseStyle = {marginBottom:10};

        // 上传 banner插件的 props
        const upLoadProps = {
            onRemove: file => {
              this.setState(state => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                  fileList: newFileList,
                };
              });
            },
            beforeUpload: file => {
              const image = window.URL ? window.URL.createObjectURL(file): window.webkitURL.createObjectURL(file);
              this.setState(state => ({
                fileList: [...state.fileList, file],
                image: image
              }));
              return false;
            },
            fileList,
        };
        
        return (
            <Modal
                visible={true}
                title="上传 banner"
                centered
                onCancel={this.props.close}
                footer={false}
                width={500}
                bodyStyle={{maxHeight:500,overflow:'auto'}}
            >
                <Row>
                    <Col span={labelSpan}>站点：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleStationsChange} style={{width:300}} value={stations}>
                            <Option value="US">US</Option>
                            <Option value="DE">DE</Option>
                            <Option value="FR">FR</Option>
                            <Option value="UK">UK</Option>
                            <Option value="CA">CA</Option>
                            <Option value="JP">JP</Option>
                        </Select>
                    </Col>

                    <Col span={labelSpan}>推广周期：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <RangePicker style={{width:'100%'}} onChange={this.handleWeekChange} value={[moment(begin||new Date(), dateFormat), moment(end||new Date(), dateFormat)]} format={dateFormat}/>
                    </Col>

                    <Col span={labelSpan}>终端：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleDevChange} style={{width:300}} value={dev}>
                            <Option value="1">PC</Option>
                            <Option value="2">Phone</Option>
                        </Select>
                    </Col>

                    {!!dev?<><Col span={labelSpan}>上传</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Upload {...upLoadProps} maxCount={1} showUploadList={false}>
                            <Button icon={<UploadOutlined />}>选择文件</Button>
                            <p style={{fontSize:12,marginTop:5}}>{dev==='1'?'请上传950x360像素的图片，图片小于100kb':'请上传750x375像素的图片，图片小于100kb'}</p>
                        </Upload>
                    </Col></>:''}

                    {dev==='1'?<>
                    <Col span={labelSpan}>Pc预览：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <div style={{width:300,minHeight:100,border:'1px solid #ddd',textAlign:'center'}}>
                            <img src={image} style={{width:'100%'}} alt="" />
                        </div>
                    </Col></>:''}
                    

                    {dev==='2'?<>
                    <Col span={labelSpan}>Phone预览：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <div style={{width:300,height:160,border:'1px solid #ddd',background:color,textAlign:'center',marginBottom:20}}>
                            <img src={theme===1?LogoRed:LogoWhite} style={{marginTop:10,marginBottom:10,width:60}} alt="" />
                            <img src={image} style={{width:'85%'}} alt="" />
                        </div>
                    </Col>

                    <Col span={labelSpan}>选择背景色：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <div style={{position:'relative'}}>
                            <div style={{width:20,height:20,background:color,display:'inline-block',border:'1px solid #ddd',verticalAlign:'middle',marginRight:5}}></div>
                            {color}
                            {!showColorPicker?<Button style={{float:'right'}} size="small" type="primary" onClick={this.showColorPicker}>选取颜色</Button>:''}
                            {showColorPicker?<Button style={{float:'right'}} size="small" type="primary" onClick={this.hideColorPicker}>确认</Button>:''}
                            {showColorPicker?<div style={{position:'absolute',top:30,right:0,zIndex:12}}>
                                <SketchPicker  color={color} onChange={this.handleColorChange}/>
                            </div>:''}
                        </div>
                    </Col>

                    <Col span={labelSpan}>Logo主题：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleLogoThemeChange} style={{width:300}} value={theme}>
                            <Option value="1">深色</Option>
                            <Option value="2">浅色</Option>
                        </Select>
                    </Col>
                    </>:''}

                    <Col span={labelSpan}>只显示当前Banner</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleOnlyChange} style={{width:300}} value={isOnly}>
                            <Option value="1">是</Option>
                            <Option value="0">否</Option>
                        </Select>
                    </Col>

                    <Col span={labelSpan}>是否需要登录显示</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleNeedLoginChange} style={{width:300}} value={needLogin}>
                            <Option value="1">是</Option>
                            <Option value="0">否</Option>
                        </Select>
                    </Col>
                    
                    <Col span={labelSpan}>点击Banner操作：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleClickActionChange} style={{width:300}} value={clickAction}>
                            <Option value="0">无</Option>
                            <Option value="1">滑动至楼层</Option>
                            <Option value="2">站内跳转</Option>
                            <Option value="3">打开新页面</Option>
                        </Select>
                    </Col>
                    {clickAction==="2"||clickAction==="3"? <>
                    <Col span={labelSpan}>跳转地址：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Input.TextArea value={url} onChange={this.handleUrlChange}></Input.TextArea>
                    </Col>

                    <Col span={labelSpan}>是否需要统计：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Select onChange={this.handleIsStatChange} style={{width:300}} value={isStat}>
                            <Option value="1">是</Option>
                            <Option value="0">否</Option>
                        </Select>
                    </Col>
                    {isStat==='1'?<>
                    <Col span={labelSpan}>统计-分类名(cate)：</Col>
                    <Col span={inputSpan} style={baseStyle} >
                        <Input onChange={this.handleStatCateChange} value={stat.cate}></Input>
                    </Col>

                    <Col span={labelSpan}>统计-类型名(type)：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Input onChange={this.handleStatTypeChange} value={stat.type}></Input>
                    </Col>

                    <Col span={labelSpan}>统计-动作名(action)：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Input onChange={this.handleStatActionChange} value={stat.action}></Input>
                    </Col>

                    <Col span={labelSpan}>统计-备注：</Col>
                    <Col span={inputSpan} style={baseStyle}>
                        <Input onChange={this.handleStatRmkChange} value={stat.remark}></Input>
                    </Col>
                    </>:''}
                    </>:''}

                </Row>
                    <div style={{textAlign:'right'}}><Button type="primary" onClick={this.submit}>提交</Button></div>
            </Modal>
        );
    }
}

export default BaseHoc(BuyerNotice);