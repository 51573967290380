import React, { Component } from 'react';
import BaseHoc from '../../../base/baseHoc';
import { Modal ,message,Row, Col,Input,Button} from 'antd';

class ShowRemake extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin_remark:''
        };
    }

    handlerTextChange=(e)=>{
        this.setState({
            admin_remark:e.target.value
        })
    }

    componentDidMount(){
        const {item} = this.props;
        this.setState({
            admin_remark:item['admin_remark']
        })
    }


    doSubmit=()=>{
        const {admin_remark} = this.state;
        const {item,close,reload} = this.props;
        this.props.$request('/order/order-refund-remark',{id:item['id'],admin_remark},(res)=>{
            if(res['code']===200){
                message.success('操作成功！');
                reload();
                close();
            }
        })
    }

    render() {
        const { admin_remark } = this.state;
        const { item } = this.props;
        const style = {width:300,marginBottom:10}
        return (
            <Modal 
                visible={true}
                title="备注"
                centered
                onCancel={this.props.close}
                footer={false}
            >
                <Row>
                    <Col span={6}>卖家 ID:</Col>
                    <Col span={18} style={style}>{item['id']}</Col>

                    <Col span={6}>备注:</Col>
                    <Col span={18}>
                         <Input.TextArea placeholder="填写备注" onChange={this.handlerTextChange} style={{marginBottom:20}} value={admin_remark}></Input.TextArea>
                    </Col>

                    <Col span={6}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={this.doSubmit} disabled={!!admin_remark?false:true}>提交</Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default BaseHoc(ShowRemake);